@use "../utils/mixins";
@use "../utils/variables";

//  PRE TABLE
.pre-table {

  @include mixins.breakpoint() {
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0 25px;
    height: 45px;

  }
  @include mixins.breakpoint(variables.$small_tablet) {
    padding: 0;
  }

  .table-name {

    @include mixins.breakpoint() {

      color: variables.$black;

      font-size: 18px;
      line-height: 40px;

    }

  }

}

//  TABLE CONTROLS
.table-controls {
    
  @include mixins.breakpoint() {

    display: flex;
    flex-direction: row;
    align-items: center;

  }

  &.mobile-only {
    @include mixins.breakpoint(variables.$small_tablet) {
      display: none;
    }
  }
  &.tablet-and-above {
    @include mixins.breakpoint() {
      display: none;
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      display: flex;
    }
  }

  li {

    @include mixins.breakpoint() {

      padding: 0 10px;

      border-right: solid 1px variables.$black;

    }

    &:first-child {
      @include mixins.breakpoint() {
        padding: 0 10px 0 0;
      }
    }

    &:last-child {
      @include mixins.breakpoint() {
        padding: 0 0 0 10px;
        border: none;
      }
    }

  }

}

//  TABLE LOCATION
.table-location {

  @include mixins.breakpoint() {
    display: flex;
    flex-direction: column;
  }

  strong {
    
    @include mixins.breakpoint() {

      color: variables.$dark_midnight_blue;

      font-size: 18px;
      line-height: 1;
      font-weight: variables.$bold;

      margin: 0 0 5px 0;

    }
    @include mixins.breakpoint(variables.$small_tablet) {
      margin: 0;
    }
  
  }

  .table-location__bar {

    @include mixins.breakpoint() {

      background-color: rgba(variables.$dark_midnight_blue,0.25);
      border-radius: 5px;
      height: 5px;

    }
    @include mixins.breakpoint(variables.$small_tablet) {
      display: none;
    }

    .table-location__indicator {

      @include mixins.breakpoint() {

        background-color: variables.$dark_midnight_blue;
        height: 5px;
        border-radius: 5px;

      }

    }

  }

}

//  DATA TABLE WRAPPER
.data-table__wrapper {

  @include mixins.breakpoint() {

    position: relative;

    margin: 0 0 20px 0;

    border-top: solid 1px variables.$border_color;
    border-bottom: solid 1px variables.$border_color;

  }
  @include mixins.breakpoint(variables.$small_tablet) {
    border: none;
  }

  .fixed-shadow {
    @include mixins.breakpoint() {

      width: 25vw;
      height: 100%;

      display: block;

      position: absolute;
      z-index: 1;

      top: 0;
      bottom: 0;

      left: 0;

      @include mixins.box-shadow(5px 0px 5px 0px rgba(variables.$black, 0.15));

    }
    @include mixins.breakpoint(variables.$small_tablet) {
      display: none;
    }
  }

}

//  DATA TABLE SCROLL
.data-table__scroll {
  @include mixins.breakpoint() {

    overflow-x: scroll;
    overflow-y: hidden;

    width: calc(60vw);
    margin: 0 15vw 0 25vw;

    box-sizing: content-box;

  }
  @include mixins.breakpoint(variables.$small_tablet) {

    overflow-x: visible;

    width: auto;
    margin: 0;

  }

  //  LAST SCORE MOD
  &.LastScoreTable {
    @include mixins.breakpoint() {
      width: calc(45vw);
      margin: 0 30vw 0 25vw;
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      overflow-x: visible;

      width: auto;
      margin: 0;
    }
  }

}

//  DATA TABLES
.data-table {

  @include mixins.breakpoint() {

    table-layout: fixed;

    border: none;
    border-collapse: separate;
    border-spacing: 0;

  }
  @include mixins.breakpoint(variables.$small_tablet) {
    border-collapse: collapse;
  }

  &.scores {

    @include mixins.breakpoint() {
      width: 100vw;
    }
    @include mixins.breakpoint(variables.$small_tablet) {

      width: auto;

    }

    &.advanced-stats {
      @include mixins.breakpoint(variables.$small_tablet) {
        margin: 0 0 40px 0;
      }
    }

    //  COLGROUP FOR TABLET AND ABOVE ONLY
    colgroup {

      col {

        &.start {
          @include mixins.breakpoint(variables.$small_tablet) {
          }
        }

        &.end {
          @include mixins.breakpoint(variables.$small_tablet) {
            width: 80px;
          }
        }

        &.col {
          @include mixins.breakpoint(variables.$small_tablet) {
            width: 49px;
          }
        }

      }

    }

    //  FIXED COLS & NOT FIXED
    thead,
    tbody {

      @include mixins.breakpoint() {
      }

      tr {

        @include mixins.breakpoint() {
        }

        th,
        td {

          @include mixins.breakpoint() {

            white-space: nowrap;

            flex: none;
            width: 15vw;

            height: 40px;

            font-size: 16px;
            line-height: 19px;

            vertical-align: middle;
            text-align: center;

          }
          @include mixins.breakpoint(variables.$small_tablet) {
            line-height: 1;
          }

          &.fixed {

            @include mixins.breakpoint() {
              position: absolute;
            }
            @include mixins.breakpoint(variables.$small_tablet) {
              position: static;
            }

            &.start {

              @include mixins.breakpoint() {

                color: variables.$dark_midnight_blue;

                width: 25vw;

                left: 0;

                text-align: left;
                padding-left: 10px;

                display: flex;
                flex-direction: row;
                align-items: center;

              }
              @include mixins.breakpoint(variables.$small_tablet) {

                width: 120px;
                display: table-cell;

              }

            }

            &.end {

              @include mixins.breakpoint() {

                width: 15vw;
                right: 0;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

              }
              @include mixins.breakpoint(variables.$small_tablet) {

                width: 80px;
                display: table-cell;

              }


            }

            &.pre-end {

              @include mixins.breakpoint() {

                width: 15vw;
                right: 15vw;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

              }
              @include mixins.breakpoint(variables.$small_tablet) {
                
                display: table-cell;

              }

            }

            &.pre-end-total {

              @include mixins.breakpoint() {

                right: 15vw;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

              }

              @include mixins.breakpoint(variables.$small_tablet) {

                display: table-cell;

              }

            }

          }

        }

      }

    }

    //  THEAD
    thead {

      tr {

        th {

          @include mixins.breakpoint() {

            color: variables.$white;
            background-color: variables.$dark_midnight_blue;

            font-size: 16px;
            font-weight: variables.$bold;

            border-right: solid 1px variables.$dark_midnight_blue;

          }

          &.fixed {
            &.start {
              @include mixins.breakpoint() {

                color: variables.$white;
                text-transform: uppercase;

              }
            }
          }

        }

      }

    }

    //  TBODY
    tbody {

      tr {

        td {

          //  INPUT
          input[type="text"] {

            @include mixins.breakpoint() {

              width: 100%;
              height: 39px;

              border: none;

              margin: 0;

              display: block;

              text-align: center;

            }

          }

        }

        &:nth-child(odd) {
          td {
            @include mixins.breakpoint() {
              background-color: variables.$table_odd;
              border-bottom: solid 1px variables.$so_many_grays;
            }
          }
        }

        &:nth-child(even) {
          td {
            @include mixins.breakpoint() {
              background-color: variables.$table_even;
              border-bottom: solid 1px variables.$so_many_grays;
            }
          }
        }

        &:nth-child(odd),
        &:nth-child(even) {
          td {
            @include mixins.breakpoint() {
              border-right: solid 1px variables.$so_many_grays;
            }
            &.start {
              @include mixins.breakpoint() {
                border-bottom: solid 1px variables.$so_many_grays;
              }
            }
            &.end {
              @include mixins.breakpoint() {
                background-color: variables.$light_blue_2;
                border-bottom: solid 1px variables.$white;
              }
            }
            &:last-child {
              @include mixins.breakpoint() {
                border-right: solid 1px variables.$so_many_grays;
              }
            }
          }
        }

        &:last-child {
          td {
            @include mixins.breakpoint() {
              border-bottom: none;
            }
          }
        }

      }

    }

  }

}

.data-table {
  &.scores {
    &.advanced-stats {
      tbody {
        tr {
          &.first-child {
            @include mixins.breakpoint() {
              border-top: solid 1px variables.$border_color;
            }
          }
        }
        @include mixins.breakpoint() {
          border-top: solid 1px variables.$border_color;
          border-bottom: solid 1px variables.$border_color;
        }
      }
    }
  }
}

.hidden-advanced-stats {
  height: 0px;
  overflow: hidden;
}

//  COLUMN NORMALIZATION

.data-table {

  //  FRONT 9
  &.Front {

    &.advanced-stats,
    &.scores {

      @include mixins.breakpoint(variables.$small_tablet) {

        width: 100%;
        table-layout: fixed;

      }

      thead,
      tbody {

        tr {

          th,
          td {

            @include mixins.breakpoint(variables.$small_tablet) {
              width: auto;
            }

            &.start {
              @include mixins.breakpoint(variables.$small_tablet) {
                width: 120px;
              }
            }

            &.end {
              @include mixins.breakpoint(variables.$small_tablet) {
                width: 80px;
              }
            }

          }

        }

      }

    }

  }

  //  BACK 9
  &.Back {

    &.advanced-stats,
    &.scores {

      @include mixins.breakpoint(variables.$small_tablet) {

        width: 100%;
        table-layout: fixed;

      }

      thead,
      tbody {

        tr {

          th,
          td {

            @include mixins.breakpoint(variables.$small_tablet) {
              width: auto;
            }

            &.pre-end {
              @include mixins.breakpoint(variables.$small_tablet) {
                width: auto;
              }
            }

            &.start {
              @include mixins.breakpoint(variables.$small_tablet) {
                width: 120px;
              }
            }

            &.end {
              @include mixins.breakpoint(variables.$small_tablet) {
                width: 60px;
              }
            }

          }

        }

      }

    }

  }

}


.score-markup {

  @include mixins.breakpoint() {

    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: 30px 31px;

  }

  
  &.score-circle {
    @include mixins.breakpoint() {
      background-image: url('../img/icons/score_state_circle.png');
      //  color: #ff0000;
    }
  }
  
  &.score-double-circle {
    @include mixins.breakpoint() {
      background-image: url('../img/icons/score_state_double_circle.png');
      //  color: #00ff00;
    }
  }
  
  &.score-square {
    @include mixins.breakpoint() {
      background-image: url('../img/icons/score_state_square.png');
      //  color: #0000ff;
    }
  }
  
  &.score-double-square {
    @include mixins.breakpoint() {
      background-image: url('../img/icons/score_state_double_square.png');
      //  color: #ea00ff;
    }
  }

}

//   INPUT & SELECT / RESETS
.data-table__wrapper {
  .data-table__scroll {
    .data-table {
      &.scores {
        .score-markup,
        .react-icon-select .react-icon-select__control {
          @include mixins.breakpoint() {
            background-color: transparent;
          }
        }
      }
    }
  }
}
