@use "mixins";

@include mixins.keyframes(fade_in) {

  0% {
      opacity: 0;
      visibility: hidden;
  }
  100% {
      opacity: 1;
      visibility: visible;
  }

}