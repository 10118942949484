@use "../utils/mixins";
@use "../utils/variables";

.tabs-container {

  @include mixins.breakpoint() {
    display: none;
  }
  @include mixins.breakpoint(variables.$small_tablet) {

    display: block;
    height: 40px;
    border-bottom: solid 1px #e5e5e5;

  }


  .nav__links {

    @include mixins.breakpoint() {

      display: flex;
      flex-direction: row;
      align-items: center;

      padding: 0;

    }
    @include mixins.breakpoint(variables.$small_desktop) {
    }

    li {

      .nav__link {

        @include mixins.breakpoint() {
          font-size: 19px;
          line-height: 40px;
          font-weight: 500;
          letter-spacing: 0.5px;
          white-space: nowrap;
          padding: 0 10px;
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
          transition: all .25s ease;
          color: #00365f;
        }
        @include mixins.breakpoint(variables.$small_desktop) {
          letter-spacing: 1.8px;
        }
        &:hover {
          background: rgba(0,136,206,.2);
        }
        &.is-active {
          color: #fff;
          background-color: #00365f;
        }

      }

    }

  }

}