@use "../utils/mixins";
@use "../utils/variables";

.login_form {
  input[type="text"],
  input[type="password"] {
    font-family: National, sans-serif;
    border-radius: 4.5px;
    margin-bottom: 5px;
    font-size: 18.5px;
    height: 43px;
  }
  input[type="text"]:focus-visible,
  input[type="password"]:focus-visible {
    border: 3px solid red;
  }
  label {
    font-family: National, sans-serif;
    font-size: 18px;
    font-weight: variables.$regular;
  }
  span.error {
    color: variables.$dark_cardinal;
    font-size: 13px;
    line-height: 15px;
    font-weight: 600;

    width: fit-content;
    background: rgba($color: white, $alpha: 0.8);
    padding: 3px;
    border-radius: 5px;

    &:empty {
      padding: 0;
    }
  }
  div.hidden-text {
    line-height: 0;
    font-size: 0;
    color: transparent;
  }
  .show_password_wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .show_password {
    position: absolute;
    align-self: flex-end;
  }
  .show_button {
    padding: 0.3em 1.4em;
    text-decoration: none;
  }
  .infoLabel {
    color: white;
    font-family: National, sans-serif;
    font-size: 1.3em;
    line-height: 1.1em;
    font-weight: variables.$regular;
    margin-bottom: 15px;
    white-space: pre-wrap;
    a:link {
      color: white;
      text-decoration: underline;
    }
    a:visited {
      color: white;
    }
    a:focus-visible {
      color: red;
    }
  }
}

.recaptcha_policy {
  font-size: 14px;
  text-align: center;
  color: white;
  word-spacing: 0.06rem;
  margin-bottom: 3px;
  a {
    color: white;
    display: inline-block;
    text-decoration: underline;
    margin-inline: 0.3rem;
    text-decoration-color: rgba(255, 255, 255, 0.7);
  }
}

.login_container {
  display: flex; /* Use flexbox layout */
  flex: 1;
  justify-content: space-between; /* Align items with space between them */
  align-items: center; /* Align items at the start of the cross axis */
  background: #fff; /* White background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding :0px 0px; /* 30px top and bottom, 20px left and right */
  max-width: 1000px; /* Maximum width of the form */
  margin: auto; /* Centering the form */
  margin-top: 20px;
  width: 90%;
  flex-direction: column;
  padding-bottom: 20px;

  @include mixins.breakpoint(variables.$large_mobile) {
    padding: 0px 20px;
    padding-bottom: 20px;
  }

  @include mixins.breakpoint(variables.$small_tablet) {
    padding: 0px 20px;
    margin: 50px 50px;
    width: 70%;
    flex-direction: row;
  }
}


.login__title {
  @include mixins.breakpoint() {
    color: variables.$dark_midnight_blue;
    font-size: 23px;
    text-align: center;
    margin: 0 0 30px 0;
  }
  @include mixins.breakpoint(variables.$tablet) {
    font-size: 23px;
    line-height: 1.2;
  }
  @include mixins.breakpoint(variables.$small_desktop) {
    font-size: 23px;
  }
  
  &.sub-title {
    color: variables.$dark_midnight_blue;
    font-weight: 500;
    font-size: 23px;
  }
}

.welcome__header {
  text-align: center;
  color: white;
  padding-top: 70px;
  font-size: 45px;
}

.welcome__header_small {
  text-align: center;
  color: white;
  font-size: 17px;
  padding-top: 10px;
}

.separator {
  border-left: 2px solid rgba(149, 148, 148, 0.443);
  height: 350px;
  top: 00px;
  position: relative;
}


.create-trial {
  @include mixins.breakpoint() {
    height: 100%;
    padding: 0px 25px;
    background-color: rgba(variables.$white, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    margin: 0;
  }

  .create-trial__title {
    @include mixins.breakpoint() {
      color: variables.$dark_cardinal;
      font-size: 23px;
      line-height: 1;
      text-align: center;
      margin: 0 0 30px 0;
      font-weight: bold;
    }
    @include mixins.breakpoint(variables.$tablet) {
      font-size: 23px;
      line-height: 1.2;
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      font-size: 23px;
    }

    &.sub-title {
      color: variables.$dark_midnight_blue;
      font-weight: 500;
    }
  }
}

.create-trial-list {
  list-style-type: disc;
  margin-left: 20px;
}

.create-trial-list li {
  margin-bottom: 10px;
}