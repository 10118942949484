@use "../utils/mixins";
@use "../utils/variables";

.member_slides_pos{
  height: 0;
  overflow: visible;
  position: relative;
  top: -100px;
  .memberships_slideshow{
    height: 100px;
    padding: 10px 0;
    background: rgba(#f2f2f2, 0.8);
    h4{
      font-size: 14px;
      font-weight: variables.$bold;
      color: variables.$dark_midnight_blue;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding: 0 40px;
    }

    .slideshow-container {
      position: relative;
      margin: 13px 0 15px;
      .slide{
        padding: 0 0 0 40px;
        .assoc{
          font-size: 15.5px;
          line-height: 1.2em;
        }
        .club{
          font-size: 14px;
          color: rgba(variables.$black, 0.7);
          line-height: 1.2em;
        }
      }
    }

    .prev, .next {
      cursor: pointer;
      position: absolute;
      top: 50%;
      width: auto;
      margin-top: -22px;
      padding: 16px;
      color:rgba(0,0,0,0.4);
      font-size: 16px;
      font-weight: 100;
      transition: 0.6s ease;
      user-select: none;
      background: none;
      border: none;
      box-shadow: none;
    }
    .prev{
      left: 0;
    }
    .next {
      right: 0;
    }
    
    .prev:hover, .next:hover {
      color: rgba(0,0,0,0.8);
      background: none;
      border: none;
      box-shadow: none;
    }

  }
  //  OVERRIDES & FIXES

  .memberships_slideshow {
  
    @include mixins.breakpoint() {

      display: flex;
      flex-direction: column;

    }

    .slideshow-container {
  
      @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;

        height: 34px;

        position: relative;

      }

      .slide {
  
        @include mixins.breakpoint() {

          width: 100%;

          margin: 0;
          padding: 0 40px;

        }

        li {
  
          @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;

          }

          .club-logo__container {

            .club-logo {

              @include mixins.breakpoint() {

                height: 34px;
                width: auto;

                margin: 0 10px 0 0;

                display: block;
                object-fit: contain;

              }

            }

          }

          .club__details {

            .assoc {

            }
    
            .club {
  
            }

          }
  
        }
  
      }

      .prev,
      .next {

        @include mixins.breakpoint() {

          width: 34px;
          height: 34px;

          margin-top: 0;
          padding: 0;

          position: absolute;

          top: 0;
          
          z-index: 9;

        }

      }

      .hidden {
        @include mixins.breakpoint() {
          display: none;
        }
      }
  
    }
  
  }  
}
@include mixins.breakpoint(variables.$small_tablet) {
  .member_slides_pos{
    display: none;
  }
}




