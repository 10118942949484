@use "../utils/mixins";
@use "../utils/variables";

.p-row {
  .l-col {
    &.blank {
      display: none;
      @include mixins.breakpoint(variables.$tablet) {
        display: block;
      }
    }
  }
  .r-col {
  }
}

.profile-page__head {
  background-color: variables.$white;
  margin: 0 0 20px 0;

  .p-row {
    @include mixins.breakpoint(variables.$small_tablet) {
      display: flex;
      flex-direction: row;
    }

    .l-col {
      padding: 20px;
      border-bottom: solid 1px rgba(variables.$separator_color, 0.1);
      @include mixins.breakpoint(variables.$small_tablet) {
        flex: none;
        width: 40%;

        border: none;

        padding: 35px 0 35px 35px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }
    .r-col {
      padding: 20px;
      @include mixins.breakpoint(variables.$small_tablet) {
        flex: none;
        width: 60%;

        padding: 35px 35px 35px 0;
      }
    }
  }

  .profile-name {
    font-family: "National", sans-serif;

    font-size: 24px;
    line-height: 32px;

    margin: 0;

    @include mixins.breakpoint(variables.$small_tablet) {
      font-size: 32px;
    }
  }
  .profile-details {
    font-size: 16px;
    line-height: 24px;

    margin: 0;
    @include mixins.breakpoint(variables.$small_tablet) {
      font-size: 18px;
    }
  }
  .membership-container {
    h4 {
      color: variables.$dark_midnight_blue;

      text-transform: uppercase;

      margin: 0 0 10px 0;

      @include mixins.breakpoint(variables.$small_tablet) {
        font-size: 20px;
        font-weight: 300;
      }
    }
    .membership-list {
      display: flex;
      flex-direction: column;

      @include mixins.breakpoint(variables.$small_tablet) {
        height: 110px;

        overflow-y: auto;
      }

      .membership-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin: 0 0 12px 0;

        &:last-of-type {
          margin: 0;
        }
        .membership-item__badge {
          margin: 0 16px 0 0;
          img {
            width: 48px;
            height: 48px;
            display: block;
          }
        }
        .membership-item__details {
          display: flex;
          flex-direction: column;

          font-size: 18px;
          line-height: 24px;

          strong {
            @include mixins.breakpoint(variables.$small_tablet) {
              font-size: 16px;
              line-height: 18px;
            }
          }
          span {
            opacity: 0.8;
            @include mixins.breakpoint(variables.$small_tablet) {
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

.profile-page__body {
  background-color: variables.$white;
}

.accordion-container {
  .accordion-item {
    border-bottom: solid 1px rgba(variables.$separator_color, 0.1);
    &:last-of-type {
      border: none;
    }
    .accordion-item__head {
      padding: 20px 0;
      margin: 0 20px;

      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center right;

      background-size: 12px 6px;

      background-image: url("../img/icons/chevron_blue_bottom.svg");

      cursor: pointer;

      @include mixins.breakpoint(variables.$small_tablet) {
        margin: 0 35px;
      }

      .p-row {
        @include mixins.breakpoint(variables.$small_tablet) {
          display: flex;
          flex-direction: row;
        }
        .l-col {
          @include mixins.breakpoint(variables.$small_tablet) {
            flex: none;
            width: calc(40% - 5px);
          }
        }
        .r-col {
          display: block;
          @include mixins.breakpoint(variables.$small_tablet) {
            flex: none;
            width: 60%;
          }
        }
      }

      &.is-open {
        background-image: url("../img/icons/chevron_blue_top.svg");

        .p-row {
          .r-col {
            display: none;
          }
        }
      }

      .accordion-item__label {
        color: variables.$text_muted;

        font-size: 16px;
        line-height: 20px;
      }
      .accordion-item__preview {
        color: variables.$black;

        font-size: 18px;
        line-height: 24px;

        .psw {
          color: variables.$text_muted;

          font-size: 32px;
          line-height: 24px;

          max-height: 24px;
        }
        .cat-title {
          color: variables.$text_muted;

          font-size: 14px;
          line-height: 18px;
        }
        .default {
          color: variables.$black;

          font-size: 18px;
          line-height: 24px;
        }
      }
    }
    .accordion-item__body {
      background-color: variables.$light_gray;

      display: none;

      padding: 0 20px;

      box-sizing: border-box;

      .p-row {
        @include mixins.breakpoint(variables.$small_tablet) {
          display: flex;
          flex-direction: row;
        }
        .l-col {
          @include mixins.breakpoint(variables.$small_tablet) {
            display: block;
            flex: none;
            width: calc(40% - 5px);
          }
        }
        .r-col {
          @include mixins.breakpoint(variables.$small_tablet) {
            flex: none;
            width: 60%;

            max-width: 380px;
          }
        }
      }

      &.is-open {
        display: block;
        padding: 40px 20px;

        @include mixins.breakpoint(variables.$small_tablet) {
          padding: 40px 35px;
        }
      }
      input[type="text"].large2,
      input[type="password"].large2,
      input[type="email"].large2 {
        font-size: 16px;
        border-color: variables.$border_color;
        color: variables.$black;
        max-height: 41px;
        height: 41px;
        &:focus {
          border-color: variables.$border_color;
        }
        &.error {
          border-color: variables.$legend_light_red;
        }
      }
    }
  }
}

.panel-heading {
  color: variables.$black;

  font-size: 16px;
  line-height: 20px;

  margin: 0 0 20px 0;

  text-transform: uppercase;

  &.no-wrap {
    white-space: nowrap;
  }
  &.no-margin {
    margin: 0;
  }
}

.color-muted {
  color: variables.$text_muted;
}

.switch-heading {
  color: variables.$black;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
}
.switch-description {
  line-height: 1 !important;
  color: variables.$black;
  opacity: 0.7;
  margin: 0 !important;
}

span.error {
  color: red;
  font-size: 15px;
  line-height: 15px;
  font-family: National, sans-serif;
  margin-top: 8px;
}
.default_select {
  max-height: 41px;
  height: 41px;

  .ds__control {
    max-height: 41px;
    height: 41px;
    .ds__value-container {
      .ds__single-value {
        font-size: 16px;
        color: variables.$black;
      }
    }
  }
}
.date_picker_wrapper {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
  align-items: center;
  .picker_title {
    font-family: National, sans-serif;
    font-size: 18px;
    color: variables.$black;
  }
  .change_month {
    @include mixins.breakpoint() {
      background-color: transparent;
      border: none;

      display: flex;
      flex-direction: row;
      align-items: center;
    }
    
    & {
      font-family: National, sans-serif;
      font-size: 28px;
      color: variables.$black;
    }
  }
}

.svg-color {
  svg {
    color: #949494;
  }
}
