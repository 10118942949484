@use "../utils/mixins";
@use "../utils/variables";

//  ICON SELECT

//  TRIGGER
.react-icon-select {
  @include mixins.breakpoint() {
    height: 39px;
    max-height: 39px;

    min-width: 38px;
  }

  .react-icon-select__control {
    @include mixins.breakpoint() {
      height: 39px;
      max-height: 39px;

      border: none;

      display: flex;
      flex-direction: row;

      border-radius: 0;

      background-color: variables.$white;

      position: relative;

      cursor: pointer;
    }

    &.react-icon-select__control--menu-is-open,
    &.react-icon-select__control--is-focused {
      @include mixins.breakpoint() {
        border: none;

        box-sizing: content-box;

        @include mixins.box-shadow(inset 0 0 0 1px variables.$dark_midnight_blue);
      }
    }

    .react-icon-select__value-container {
      @include mixins.breakpoint() {
        width: 100%;
        height: 40px;

        padding: 0;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        flex: none;

        position: relative;
      }

      .react-icon-select__value {
        @include mixins.breakpoint() {
          position: absolute;

          left: 50%;
          top: 50%;

          @include mixins.transform(translate3d(-50%, -50%, 0));
        }

        .material-icons-outlined {
          & + span {
            @include mixins.breakpoint() {
              display: none;
            }
          }
        }
      }
    }

    .react-icon-select__indicators {
      .react-icon-select__indicator {
        &.react-icon-select__clear-indicator {
          @include mixins.breakpoint() {
            display: none;
          }
        }

        &.react-icon-select__dropdown-indicator {
          &::before {
            @include mixins.breakpoint() {
              content: "";
              display: block;

              width: 0;
              height: 0;

              border-style: solid;
              border-width: 0 0 6px 6px;
              border-color: transparent transparent variables.$bright_blue transparent;

              position: absolute;

              right: 4px;
              bottom: 4px;
            }
          }

          svg {
            @include mixins.breakpoint() {
              display: none;
            }
          }
        }
      }

      .react-icon-select__indicator-separator {
        @include mixins.breakpoint() {
          display: none;
        }
      }
    }
  }
}

//  MENU
.react-icon-select__menu {
  @include mixins.breakpoint() {
    border-radius: 0 !important;

    margin-top: -1px !important;
    margin-bottom: 0 !important;

    width: 160px !important;

    box-shadow: none !important;

    border: solid 1px variables.$dark_midnight_blue;
  }

  .react-icon-select__menu-list {
    @include mixins.breakpoint() {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }

    .react-icon-select__option {
      @include mixins.breakpoint() {
        height: 40px;

        padding: 0 8px;

        display: flex;
        flex-direction: row;
        align-items: center;

        background-color: variables.$white;
        border-bottom: solid 1px variables.$medium_blue;
      }

      .material-icons-outlined {
        @include mixins.breakpoint() {
          margin: 0 8px 0 0;
        }
      }

      span {
        @include mixins.breakpoint() {
          color: variables.$black;
          opacity: 0.6;
        }
      }

      //  SELECTED
      &.react-icon-select__option--is-selected {
        @include mixins.breakpoint() {
          background-color: variables.$light_blue_3;
        }

        span {
          @include mixins.breakpoint() {
            opacity: 1;
          }
        }
      }
    }

    .react-icon-select__clear {
      @include mixins.breakpoint() {
        display: block;

        background-color: transparent;
        border: none;

        height: 40px;

        color: variables.$dark_midnight_blue;

        font-size: 14px;
        line-height: 40px;
        font-weight: variables.$bold;

        margin: 0;
        padding: 0 8px;

        text-align: left;
      }
    }
  }
}

//  ICONS
.react-icon-select,
.react-icon-select__menu {
  .material-icons-outlined {
    @include mixins.breakpoint() {
      width: 30px;
      height: 30px;

      font-size: 18px;
      line-height: 30px;

      text-align: center;

      border-radius: 50%;
    }

    //  GREEN
    &.green {
      @include mixins.breakpoint() {
        color: variables.$green;
        @include mixins.box-shadow(inset 0 0 0 1px variables.$green);
      }
    }

    //  RED
    &.red {
      @include mixins.breakpoint() {
        color: variables.$cardinal;
        @include mixins.box-shadow(inset 0 0 0 1px variables.$cardinal);
      }
    }
  }
}

.default_select {
  max-height: 41px;
  height: 41px;
  flex-grow: 0;
  .ds__control {
    border-radius: 0;
    border: 1px solid variables.$border_color;
    min-height: 35px;
    max-height: 41px;
    .ds__single-value {
      .right {
        margin-left: 10px;
      }
    }
    .ds__value-container {
      div[class$="-Input"] {
        margin: 0;
        padding-bottom: 0;
        padding-top: 0;
        color: transparent;
      }
    }
    .ds__indicators {
      .ds__indicator-separator {
        display: none;
      }
    }
    &:hover,
    &.ds__control--is-focused {
      border-color: variables.$border_color;
      box-shadow: 0 0 1px variables.$border_color;
    }
  }
  .ds__menu {
    border-radius: 0;
    margin-top: -1px;
    margin-bottom: 0;
    box-shadow: none;
    border: 1px solid variables.$border_color;
    .ds__menu-list {
      padding-top: 0;
      padding-bottom: 0;
      .ds__option {
        white-space: pre-line;
        padding: 14px 12px;
        color: #444;
        border-bottom: 1px solid variables.$border_color;
        small {
          display: block;
          color: variables.$text-muted;
          font-size: 0.9em;
        }
      }
      .ds__option--is-selected,
      .ds__option--is-focused {
        background-color: variables.$light_gray;
        color: variables.$black;
      }
    }
  }
  &.full_on_phone {
    .ds__menu {
      position: fixed;
      height: 100vh;
      z-index: 1000;
      //  select has built in scroll if total height of items is > than containing menu also it makes the menu slighly larger
      //  overflow-y: scroll;
      left: 0;
      top: 0;
      -webkit-overflow-scrolling: touch;
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      .ds__menu {
        position: absolute;
        height: auto;
      }
    }

    //  MOD
    &.full_on_phone {
      .ds__menu {
        @include mixins.breakpoint() {
          border: none;
        }
        @include mixins.breakpoint(variables.$small_tablet) {
          border: solid 1px variables.$border_color;
          border-top: none;

          position: absolute;
          min-width: 0;
          width: 280px;
        }

        .ds__menu-list {
          @include mixins.breakpoint() {
            //  LIB OVERRIDE FOR MOBILE
            //  IT WAS CUTTING OUT SOME OPTIONS, LOL
            max-height: none !important;
          }
          @include mixins.breakpoint(variables.$small_tablet) {
            max-height: 360px !important;
          }
        }

        //  HEADER
        .ds__header {
          @include mixins.breakpoint() {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            padding: 0 25px;
            height: 70px;

            position: relative;
          }
          @include mixins.breakpoint(variables.$small_tablet) {
            display: none;
          }

          .ds__button {
            @include mixins.breakpoint() {
              background-color: transparent;
              border: none;

              padding: 0;
              margin: 0;

              color: variables.$bright_blue;

              font-size: 16px;
              line-height: 32px;

              display: block;
            }

            &.left {
              @include mixins.breakpoint() {
                position: absolute;
                left: 25px;
              }
            }
          }

          .ds__title {
            @include mixins.breakpoint() {
              font-size: 16px;
              line-height: 32px;

              flex: none;
              max-width: 200px;
            }
          }
        }

        //  DS BODY
        .ds__body {
          @include mixins.breakpoint() {
            overflow-y: scroll;
            height: calc(100vh - 70px);
          }
          @include mixins.breakpoint(variables.$small_tablet) {
            overflow-y: auto;
            height: auto;
          }
        }

        .ds__option {
          @include mixins.breakpoint() {
            font-size: 18px;
            line-height: 20px;

            padding: 20px 25px;
          }
          @include mixins.breakpoint(variables.$small_tablet) {
            font-size: 14px;
            line-height: 20px;

            padding: 15px;
          }
          @include mixins.breakpoint(variables.$small_desktop) {
            font-size: 16px;
          }

          &:first-of-type {
            @include mixins.breakpoint() {
              border-top: solid 1px variables.$border_color;
            }
          }
          &:last-of-type {
            @include mixins.breakpoint() {
              border-bottom: solid 1px variables.$border_color;
            }
          }
        }
      }
    }
  }
  &.minwidth {
    min-width: 280px;
  }
  &.smallwidth {
    min-width: 220px;
  }
}

.calculator-tee-dropdown {
  width: 100%;

  .ds__control {
    &:hover,
    &.ds__control--is-focused {
      border-color: transparent;
      box-shadow: none;
    }
  }
  .padding-left {
    padding-left: 10px;
  }
}

.calculator-selected-tee .calculator-tee-option-ratings {
  display: none;
}

.calculator-tee-dropdown
  > .ds__menu
  > .ds__menu-list
  > .ds__option--is-disabled {
  background-color: #eff0f2 !important;
  min-width: 280px;
}

.calculator-tee-dropdown
  > .ds__menu
  > .ds__menu-list
  > .ds__option--is-selected {
  background-color: transparent !important;
}

.calculator-tee-dropdown
  > .ds__menu
  > .ds__menu-list
  > .ds__option--is-focused {
  background-color: #eff0f2 !important;
}

.calculator-tee-dropdown > .ds__menu {
  @media (min-width: 800px) {
    width: max-content !important;
  }
}
