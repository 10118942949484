@use "../utils/mixins";
@use "../utils/variables";

.pre_authorization {
  display: flex;
  flex-direction: column;

  .pre_authorization_body {
    font-size: 20px;
    margin-bottom: 20px;
    .title {
      font-size: 22px;
      color: variables.$dark_midnight_blue;
      margin-bottom: 30px;
      .str {
        font-weight: 700;
      }
    }
    .items {
      list-style: inside;
      .item {
        font-size: 18px;
        margin-left: 40px;
        margin-top: 5px;
        font-weight: 700;
        .em {
          font-weight: 300;
        }
      }
    }

    .footer_buttons {
      flex: 1 0 120px;
      margin: 0 10px;
      @include mixins.breakpoint(variables.$small_tablet) {
        flex: 1 0 200px;
      }
    }
  }

  .pre_authorization_footer {
    margin: 16px 0 0 0;
    padding: 16px 0 0 0;
    display: flex;
  }
}
