@use "../utils/mixins";
@use "../utils/variables";

.tabs {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        padding: 0 20px;
        margin: 0 0 20px 0;

    }

    &.rounded {

        li {
    
            @include mixins.breakpoint() {

                flex: 1 1 0;

                border-right: solid 1px variables.$bg_gray;
                
                overflow: hidden;

            }
            @include mixins.breakpoint(variables.$small_tablet) {
                flex: 0 0 160px;
            }
    
            &:first-child {
                @include mixins.breakpoint() {
                    border-top-left-radius: 16px;
                    border-bottom-left-radius: 16px;
                }
            }

            &:last-child {
                @include mixins.breakpoint() {
                    border-top-right-radius: 16px;
                    border-bottom-right-radius: 16px;
                    border-right: none;
                }
            }

            a {
    
                @include mixins.breakpoint() {
    
                    width: 100%;

                    color: variables.$dark_midnight_blue;
                    background-color: variables.$white;

                    font-size: 14px;
                    line-height: 32px;

                    display: block;

                    text-align: center;
                    text-transform: uppercase;

                    cursor: pointer;

                }
    
                &.is-active {

                    @include mixins.breakpoint() {

                        color: variables.$white;
                        background-color: variables.$dark_midnight_blue;

                    }

                }

            }
    
        }

    }

    &.light {
        
        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            margin: 20px 0 25px 0;

        }

        li {

            @include mixins.breakpoint() {
                margin: 0 30px;
            }

            &:first-child {
                @include mixins.breakpoint() {
                    margin: 0 30px 0 0;
                }
            }

            &:first-child {
                @include mixins.breakpoint() {
                    margin: 0 0 0 30px;
                }
            }

            a {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    font-size: 15px;
                    line-height: 25px;
                    font-weight: variables.$semi-bold;

                    display: block;

                    letter-spacing: 1px;
                    text-transform: uppercase;
                    white-space: nowrap;

                }

                &.is-active {

                    @include mixins.breakpoint() {

                        color: variables.$cardinal;
                        border-bottom: solid 2px variables.$cardinal;

                    }

                }

            }

        }

    }

    &.auto{
        margin: 0;
        padding: 0;
        li{
            flex: 0 0 auto;
            border: 1px solid variables.$border_color;
            a{
                padding: 0.2em 2em;
                text-transform: none;
            }
            & + li{
                margin-left: -1px;
            }
            &:last-child {
                border-right: 1px solid variables.$border_color;
            }
        }
    }

}