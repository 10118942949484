@use "../utils/mixins";
@use "../utils/variables";

input[type="text"],
input[type="number"],
input[type="tel"],
input[type="password"],
input[type="email"] {
  @include mixins.breakpoint() {
    height: 35px;

    font-size: 16px;
    line-height: 25px;

    padding: 5px 10px;
    margin: 0;

    border: solid 1px variables.$bg_gray;
    border-radius: 1px;
  }

  &:disabled {
    @include mixins.breakpoint() {
      background-color: variables.$light_gray;
    }
  }

  &:focus {
    @include mixins.breakpoint() {
      border-color: variables.$lol_gray;
    }
  }

  &.large {
    @include mixins.breakpoint() {
      color: variables.$dark_midnight_blue;

      height: 42px;

      font-size: 24px;
      line-height: 42px;

      border-color: variables.$lol_gray;
    }

    &:focus {
      @include mixins.breakpoint() {
        border-color: variables.$dark_midnight_blue;
      }
    }
  }

  &.centered-text {
    @include mixins.breakpoint() {
      text-align: center;
    }
  }

  &.full-width {
    width: 100%;
  }

  &.full-height {
    height: 41px;
  }
}

//  PASSWORD BULLETS SHOULD BE BIGGER
input[type="password"] {
  @include mixins.breakpoint() {
    font-size: 24px;
  }
}

//  SEARCH
input[type="text"] {
  &.search {
    @include mixins.breakpoint() {
      height: 40px;
      width: 100%;
      min-width: 240px;

      font-size: 16px;
      line-height: 40px;

      padding: 0 60px 0 20px;

      border-radius: 20px;
    }
  }
}

.search-container {
  @include mixins.breakpoint() {
    margin: 0 0 15px 0;

    position: relative;
  }

  &:after {
    @include mixins.breakpoint() {
      content: "search";
      display: block;

      font-family: "Material Icons Outlined";

      font-size: 24px;
      line-height: 40px;

      height: 40px;
      width: 40px;

      position: absolute;

      right: 0;
      top: 0;
    }
  }
}
