@use "../utils/mixins";
@use "../utils/variables";

.my-card-section {
  @include mixins.breakpoint() {
    padding-top: 40px;
    padding-bottom: 0;
  }
}

.my-card-scrollable {
  max-width: 100%;
  overflow-x: auto;

  margin: 0 20px 40px 20px;
}

.my-card {
  @include mixins.breakpoint() {
    width: 930px;
    height: 528px;

    border-radius: 20px;
    background-color: variables.$white;

    border: solid 1px variables.$text_gray;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    width: 100%;
    height: auto;

    overflow: hidden;
  }

  .my-card__head {
    display: flex;
    flex-direction: column;

    .my-card-head__row {
      display: flex;
      flex-direction: row;

      margin: 0 5px;

      &.border-bot {
        border-bottom: solid 1px variables.$text_gray;
      }

      &.padding-top-bot {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .my-card-head__col {
        flex: 1 1 0;

        display: flex;
        flex-direction: column;

        &.border-right {
          border-right: solid 1px variables.$text_gray;
        }

        .my-card-head__cell {
          font-size: 18px;
          line-height: 24px;

          padding: 5px 15px;

          .my-card-head__label {
            color: variables.$text_gray;

            font-size: 16px;

            text-transform: uppercase;

            margin: 0 10px 0 0;
          }
        }

        .my-card__logo {
          margin: auto 15px auto auto;

          width: 260px;
          height: 70px;

          background-image: url("../img/logos/usga_ghin_logo.png");

          background-repeat: no-repeat;
          background-attachment: scroll;
          background-position: center left;
          background-size: contain;

          display: flex;
          flex-direction: row;

          justify-content: flex-end;

          .usga,
          .ghin {
            // width: 100px;
            // height: 35px;

            // display: block;

            // background-repeat: no-repeat;
            // background-attachment: scroll;
            // background-position: center;

            // background-size: contain;
          }

          // .usga {

          //   background-image: url('../img/logos/logo_usga.svg');

          // }

          // .ghin {

          //   background-image: url('../img/logos/logo_ghin.svg');

          // }
        }

        .my-card__sentry-logo {
          height: 70px;
          width: auto;
        }
      }
    }
  }

  .my-card__mid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-size: 18px;
    line-height: 32px;

    padding: 10px 0;

    color: variables.$white;
    background-color: variables.$text_gray;
  }

  .my-card__body {
    .my-card-body__cell {
      font-size: 18px;
      line-height: 32px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      padding: 10px 20px;

      height: 52px;

      border-right: solid 1px variables.$text_gray;

      &:nth-child(4n) {
        border-right: none;
      }

      .date {
      }

      .score {
      }

      .letter {
      }
    }

    .my-card-body__row {
      display: flex;
      flex-direction: row;

      flex-wrap: wrap;

      &.two-cols {
        .my-card-body__cell {
          flex: none;
          width: 50%;

          &:nth-child(4n + 2),
          &:nth-child(4n + 1) {
            background-color: variables.$light_gray;
          }
        }
      }

      &.three-cols {
        .my-card-body__cell {
          flex: none;
          width: 33.333333%;

          &:nth-child(6n + 3),
          &:nth-child(6n + 2),
          &:nth-child(6n + 1) {
            background-color: variables.$light_gray;
          }
        }
      }

      &.four-cols {
        .my-card-body__cell {
          flex: none;
          width: 25%;

          &:nth-child(8n + 4),
          &:nth-child(8n + 3),
          &:nth-child(8n + 2),
          &:nth-child(8n + 1) {
            background-color: variables.$light_gray;
          }
        }
      }
    }
  }
}
