@use "../utils/mixins";
@use "../utils/variables";

.hero__section {
  @include mixins.breakpoint() {
    height: 624px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 50%;
    background-size: cover;
    background-image: url("../img/ghin_background.jpg");
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    height: 456px;
  }

  @include mixins.breakpoint(variables.$small_desktop) {
    height: 600px;
  }

  .hero__container {
    @include mixins.breakpoint() {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 70%;
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      display: flex;
      flex-direction: row;
      padding: 0px 0;
      height: calc(100% - 40px);
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      width: 1230px;
      padding: 0px 0px;
      padding-bottom: 120px;
      margin: 0 auto;
    }

    .hero__column {
      @include mixins.breakpoint() {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
      }
      @include mixins.breakpoint(variables.$small_tablet) {
        flex: none;
        width: 50%;
        padding: 0 0px;
      }
      @include mixins.breakpoint(variables.$tablet) {
        padding: 0 0px;
      }
      @include mixins.breakpoint(variables.$large_mobile) {
        flex: content;
        width: 80%;
      }
      @include mixins.breakpoint(variables.$small_desktop) {
        padding: 0 30px;
      }

      &.grow-0 {
        @include mixins.breakpoint() {
          flex-grow: 0;
        }
        @include mixins.breakpoint(variables.$small_tablet) {
          flex-grow: 1;
        }
      }
      
      &.hide-phone {
        @include mixins.breakpoint() {
          display: none;
        }
        @include mixins.breakpoint(variables.$small_tablet) {
          display: flex;
        }
      }
    }
  }

  &.login {
    height: auto;
    @include mixins.breakpoint() {
      @include mixins.box-shadow(inset 0 0 0 4000px rgba(variables.$black, 0.25));
      padding: 0px;
      padding-bottom: 20px;
    }
    @include mixins.breakpoint(variables.$large_mobile) {
      padding: 20px;
    }

    @include mixins.breakpoint(variables.$small_tablet) {
      padding: 0;
    }

    .hero__column {
      margin-top: 50px;
      @include mixins.breakpoint(variables.$small_tablet) {
        margin-top: 50px;
        max-width: 100%;
      }
    }
  }

  &.profile {
    @include mixins.breakpoint() {
      height: 420px;
    }

    &.trial {
      @include mixins.breakpoint() {
        height: 520px;
      }
      
      @include mixins.breakpoint(variables.$small_tablet) {
        height: 490px;
      }
    }
  }

  &.golfer {
    height: 250px;
    background-image: url("../img/ghin_background.jpg");
    background-position: left center;

    @include mixins.breakpoint(variables.$small_tablet) {
      height: 300px;
    }

    @include mixins.breakpoint(variables.$small_desktop) {
      height: 375px;
    }
  }
}

.trial__background {
  @include mixins.breakpoint() {
    height: 624px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center 30%; /* Modified */
    background-size: cover;
    background-image: url("../img/ghin_background3.jpg");
    background-position-y: 60%;
  }
}

.thanks__section {
  @include mixins.breakpoint() {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: top center;
    background-size: cover;
    background-image: url("../img/ghin_background2.jpg");
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  @include mixins.breakpoint(variables.$small_tablet) {
    height: 624px;
  }

  @include mixins.breakpoint(variables.$small_desktop) {
    height: 700px;
  }
  .thanks__container {
    @include mixins.breakpoint() {
      display: flex;
      flex-direction: column;
      background-color: white;
      color: white;
      align-items: center;
      height: 100%;
      padding: 50px;
    }
  }
}