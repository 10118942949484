@use "../utils/mixins";
@use "../utils/variables";
@use "sass:color";

.btn {
  @include mixins.breakpoint() {
    flex: none;
    width: auto;
    height: auto;

    font-size: 15px;
    font-weight: variables.$bold;
    line-height: 1em;
    letter-spacing: 2px;
    font-family: inherit;

    padding: 0.75em 2.4em;
    border-radius: 20px;
    border: none;

    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;

    cursor: pointer;
    will-change: background-color;
    @include mixins.transition(background-color 300ms ease-in-out 0ms);
    -webkit-tap-highlight-color: transparent;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    font-size: 16px;
  }
  @include mixins.breakpoint(variables.$small_desktop) {
    font-size: 19px;
  }

  &.empty {
    @include mixins.breakpoint() {
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
    }
  }

  &.blank {
    @include mixins.breakpoint() {
      background-color: transparent;
      border: none;

      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &.lh24 {
      @include mixins.breakpoint() {
        line-height: 24px;
        font-size: 16px;
        text-transform: none;
        font-weight: normal;
        letter-spacing: normal;
        padding-right: 0;
      }
    }

    &.medium {
      @include mixins.breakpoint() {
        font: 400 11px system-ui;
        text-transform: none;
        line-height: 15px;
        height: 15px;
      }
    }

    &.no-pad {
      @include mixins.breakpoint() {
        padding: 0;
      }
    }

    &.blue {
      @include mixins.breakpoint() {
        color: variables.$dark_midnight_blue;
        text-transform: none;
      }
    }
  }

  &.fill {
    &.cardinal {
      @include mixins.breakpoint() {
        color: variables.$white;
        background-color: variables.$cardinal;
      }
      &:hover {
        @include mixins.breakpoint() {
          background-color: color.adjust(variables.$cardinal, $lightness: -5%);
        }
      }
    }
    &.blue {
      @include mixins.breakpoint() {
        color: variables.$white;
        background-color: variables.$dark_midnight_blue;
      }
      &:hover {
        @include mixins.breakpoint() {
          background-color: color.adjust(variables.$dark_midnight_blue, $lightness: -5%);
        }
      }
      &.sixty {
        min-width: 60%;
      }
    }
  }

  &:disabled {
    @include mixins.breakpoint() {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &.outline {
    @include mixins.breakpoint() {
      display: block;
      border: none;
      will-change: box-shadow;
    }

    &.full {
      width: 100%;
    }

    &.blue {
      @include mixins.breakpoint() {
        color: variables.$dark_midnight_blue;
        @include mixins.box-shadow(inset 0 0 0 1px variables.$dark_midnight_blue);
        background-color: transparent;
      }
    }
  }

  &.lnk {
    @include mixins.breakpoint() {
      font-size: 16px;
      line-height: 35px;
      font-weight: variables.$medium;
      background-color: transparent;
      text-decoration: underline;
      text-transform: none;
      letter-spacing: normal;
    }

    &.white {
      @include mixins.breakpoint() {
        color: variables.$white;
      }
    }

    &.blue {
      @include mixins.breakpoint() {
        color: variables.$bright_blue;
      }
    }

    &.no-pad {
      @include mixins.breakpoint() {
        padding: 0;
      }
    }

    &.small {
      @include mixins.breakpoint() {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &.zero-pad {
    padding-left: 0;
    padding-right: 0;
  }

  &.no-underline {
    text-decoration: none;
  }

  &.too_large_on_phone {
    padding: 0.75em 1em;
    @include mixins.breakpoint(variables.$small_tablet) {
      padding: 0.75em 2.4em;
    }
  }

  &.has_badge {
    padding: 0.5em 2.4em;
  }
  .badge {
    display: inline-block;
    font-size: 1.2em;
    padding: 0.25em 0.4em;
    letter-spacing: normal;
    line-height: 1em;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 50%;
    background-color: variables.$white;
  }
  &.fill.cardinal {
    .badge {
      color: variables.$cardinal;
    }
  }

  //  COMPOSITION OF TWO ICONS WITH DIFF VISUALS
  //  USE IN CARD TABLE
  &.comp {
    @include mixins.breakpoint() {
      padding: 0;

      background-color: transparent;
      border: none;
    }

    .btn-in {
      @include mixins.breakpoint() {
        padding: 0 10px;
        margin: 0 5px 0 0;

        height: 30px;
        line-height: 30px;

        color: variables.$legend_light_blue;
        background-color: variables.$light_gray;
      }
    }

    .btn-out {
      @include mixins.breakpoint() {
        color: variables.$dark_midnight_blue;
      }
    }

    .open {
      display: none;
    }
    .close {
      display: inline-block;
    }
    &.active {
      .open {
        display: inline-block;
      }
      .close {
        display: none;
      }
    }
  }

  &.add,
  &.delete,
  &.check {
    font-weight: 400;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0;

    &::before {
      content: "";
      display: block;

      margin: 0 5px 0 0;

      width: 30px;
      height: 30px;

      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center;

      background-size: contain;
    }
  }

  //  ADD
  &.add {
    color: variables.$dark_midnight_blue;
    background-color: transparent;
    border: none;

    &::before {
      background-image: url("../img/icons/ic_circle_plus.svg");
    }
  }

  //  DELETE
  &.delete {
    color: variables.$cardinal;
    background-color: transparent;
    border: none;

    padding: 0;

    &::before {
      background-image: url("../img/icons/ic_circle_minus.svg");
    }
  }

  //  DELETE
  &.check {
    color: variables.$dark_midnight_blue;
    background-color: transparent;
    border: none;

    &::before {
      background-image: url("../img/icons/check_icon.svg");
    }
  }
}

//  SPECIAL CASE
.item-list {
  &.courses {
    position: relative;
    .item-list__action {
      @include mixins.breakpoint(variables.$small_tablet) {
        position: absolute;
        top: 0;
        right: 20px;
        margin: 0;
      }

      .btn {
        &.lnk {
          &.type-z-button {
            @include mixins.breakpoint(variables.$small_tablet) {
              display: flex;
              flex-direction: row;
              align-items: center;

              max-width: 200px;
              height: 55px;

              white-space: normal;

              text-align: right;
            }
            @include mixins.breakpoint(variables.$small_desktop) {
              max-width: none;
            }

            &.filter {
              height: 70px;
            }
          }
        }
      }
    }
  }
}
