@use "../utils/mixins";
@use "../utils/variables";

.handicap__index {
  &.single {
    @include mixins.breakpoint() {
      display: flex;
      flex-direction: row;

      margin: 0 0 10px 0;
    }

    &.trial {
      @include mixins.breakpoint() {
        justify-content: center;
        @include mixins.breakpoint(variables.$small_tablet) {
          justify-content: left;
        }
      }
    }

    @include mixins.breakpoint(variables.$small_tablet) {
      justify-content: left;
    }

    @include mixins.breakpoint(variables.$small_desktop) {
      margin: 0 0 20px 0;
    }

    .data {
      display: flex;

      .handicap__value {
        @include mixins.breakpoint() {
          color: variables.$white;

          font-size: 42px;
          line-height: 1;
          font-weight: variables.$regular;
        }
        @include mixins.breakpoint(variables.$small_desktop) {
          font-size: 60px;
        }
      }

      .cap-container {
        display: flex;
        flex-direction: row;
      }
      .cap {
        display: flex;
        margin-top: 10px;
        text-align: center;
      }
      .handicap__trend {
        @include mixins.breakpoint() {
          color: variables.$white;

          font-size: 14px;
          line-height: 1;
          font-weight: variables.$light;

          margin-top: -10px;
        }
        @include mixins.breakpoint(variables.$small_desktop) {
          font-size: 18px;
          margin-top: -20px;
        }
      }
    }

    .reg {
      @include mixins.breakpoint() {
        height: 42px;

        display: flex;
        flex-direction: row;
        align-items: center;

        margin: 0 0 0 10px;
      }
      @include mixins.breakpoint(variables.$small_desktop) {
        height: 60px;
      }

      .handicap__reg {
        @include mixins.breakpoint() {
          color: variables.$white;

          font-size: 16px;
          line-height: 16px;
          font-weight: variables.$bold;
        }
      }
    }
  }

  &.double {
    @include mixins.breakpoint() {
      margin: 0 0 10px 0;
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      margin: 0 0 20px 0;
    }

    .reg {
      @include mixins.breakpoint() {
        margin: 0 0 10px 0;
      }

      .handicap__reg {
        @include mixins.breakpoint() {
          color: variables.$white;

          font-size: 16px;
          line-height: 16px;
          font-weight: variables.$bold;
        }
      }
    }

    .data {
      @include mixins.breakpoint() {
        display: flex;
        flex-direction: row;
      }

      .col {
        @include mixins.breakpoint() {
          flex: 1 1 0;

          display: flex;
          flex-direction: row;
        }
        @include mixins.breakpoint(variables.$small_tablet) {
          flex: 0 0 auto;
        }

        &:first-child {
          @include mixins.breakpoint() {
            padding: 0 10px 0 0;
            border-right: solid 2px variables.$white;
          }
          @include mixins.breakpoint(variables.$small_tablet) {
            padding: 0 20px 0 0;
          }
        }
        &:last-child {
          @include mixins.breakpoint() {
            padding: 0 0 0 10px;
          }
          @include mixins.breakpoint(variables.$small_tablet) {
            padding: 0 0 0 20px;
          }
        }

        .handicap__value {
          @include mixins.breakpoint() {
            color: variables.$white;

            font-size: 40px;
            line-height: 1;

            letter-spacing: -2px;

            margin: 0 5px 0 0;
          }
          @include mixins.breakpoint(variables.$small_tablet) {
            font-size: 48px;
          }
        }

        .group {
          @include mixins.breakpoint() {
            display: flex;
            flex-direction: column;

            padding: 2px 0;
          }
          @include mixins.breakpoint(variables.$small_tablet) {
            padding: 4px 0;
          }

          .handicap__hole {
            @include mixins.breakpoint() {
              color: variables.$white;

              font-size: 16px;
              line-height: 20px;

              white-space: nowrap;

              text-transform: uppercase;
            }
            @include mixins.breakpoint(variables.$small_tablet) {
              font-size: 21px;
              line-height: 22px;
              font-weight: variables.$medium;
            }
          }

          .handicap__trend {
            @include mixins.breakpoint() {
              color: variables.$white;

              font-size: 14px;
              line-height: 18px;

              white-space: nowrap;
            }
            @include mixins.breakpoint(variables.$small_tablet) {
              font-size: 13px;
              line-height: 24px;
            }
            @include mixins.breakpoint(variables.$small_desktop) {
              line-height: 1;
            }
          }
        }
      }
    }
  }
}
