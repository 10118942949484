@use "../utils/mixins";
@use "../utils/variables";

.lookup__list {

  li {
    border-bottom: solid 1px variables.$border_color;
    background-color: variables.$white;

    &:hover{
      background-color: variables.$light_gray;
    }

    .item {
        @include mixins.breakpoint() {
            padding: 7px 10px;

            display: flex;
            flex-direction: row;
            align-items: center;
            color: variables.$black;

            font-size: 18px;
          
          &.my-courses-item {
            padding: 20px 20px 20px 10px;
            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: center right;
            background-size: 12px 6px;
            margin-right: 20px;
            &.no-search {
              background-image: url("../img/icons/chevron_blue_bottom.svg");
              cursor: pointer;
            }
          }
          
          &.my-courses {
            cursor: pointer;
          }
        }
        @include mixins.breakpoint(variables.$small_tablet) {
            padding: 7px 20px;
        }

        .star {
          display: inline-block;
          width: 30px;
          height: 30px;
          margin: 8px 20px 8px 10px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 18px 18px;
          background-image: url('../img/icons/ic_star_43x43.svg');
          &.active{
            background-image: url('../img/icons/ic_star_43x43_active.svg');
          }
        }

        .ic_c_minus {
          display: inline-block;
          width: 70px;
          height: 40px;
          min-width: 40px;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 10px;
          background-size: 29px;
          background-image: url("../img/icons/ic_circle_minus.svg");
          cursor: pointer;
        }
      
        .drag {
          display: inline-block;
          width: 30px;
          height: 30px;
          margin: 8px 8px 8px 10px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 18px 18px;
          background-image: url('../img/icons/drag_icon.svg');
        }
      
        .radio-tee {
          display: inline-block;
          width: 30px;
          height: 30px;
          margin: 8px 20px 8px 10px;
          cursor: pointer;
        }

        .star-blank {
          display: inline-block;
          width: 30px;
          height: 30px;
          margin: 8px 20px 8px 10px;
          background-repeat: no-repeat;
          background-position: center;
        }

        .ic_c_plus {
          display: inline-block;
          width: 70px;
          height: 40px;
          min-width: 40px;
          margin-right: 10px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 40px 40px;
          background-image: url('../img/icons/ic_circle_plus.svg');
          cursor: pointer;
          &.active{
            background-image: url('../img/icons/ic_circle_plus_filled.svg');
          }
        }

        .ic_check {
          display: inline-block;
          width: 70px;
          height: 40px;
          min-width: 40px;
          margin-right: 10px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 40px 40px;
          background-image: url('../img/icons/check_icon.svg');
          cursor: pointer;
        }

        .btn-ic {
          border: none;
          background-color: transparent;
          margin: 0px 15px 5px 5px;
        }
        @include mixins.breakpoint(variables.$small_tablet) {
          .ic_c_plus {
            width: 60px;
            background-size: 35px 35px;
          }
          
          .ic_check {
            width: 60px;
            background-size: 35px 35px;
          }
          
        }
        @include mixins.breakpoint(variables.$desktop) {
          .ic_c_plus {
            width: 60px;
            background-size: 25px 25px;
          }

          .ic_check {
            width: 60px;
            background-size: 25px 25px;
          }
        }
        .details {

          @include mixins.breakpoint() {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0;
              width: 100%;
          }
          @include mixins.breakpoint(variables.$small_tablet) {
              display: flex;
              flex-direction: row;
          }
          
          &.my-courses {
            padding: 15px 0 15px 0;
          }
          
          &.center {
            align-items: center;
            
            &.my-courses-details {
              color: #00365f;
              font-size: 20px;
            }
          }

          .name {
            @include mixins.breakpoint() {
              width: 100%;
            }
            @include mixins.breakpoint(variables.$small_tablet) {
              width: 40%;
            }
          }

          .tee {
            @include mixins.breakpoint(variables.$small_tablet) {
              width: 50%;
            }
          }
          
          .club:not(.abbr){
            @include mixins.breakpoint() {
              font-weight: variables.$medium;
              font-size: 16px;
            }
            @include mixins.breakpoint(variables.$small_tablet) {
              font-size: 18px;
            }
          }

        }

        .index {
          @include mixins.breakpoint() {
            flex: none;
            margin: 0 0 0 auto;
            width: 120px;
            text-align: center;

            justify-content: center;

          }
        }

      &.header{
        color: variables.$text_muted;
        font-size: 14px;
        text-transform: uppercase;
        background-color: variables.$white;
        cursor: default;
        .star{
          background: none;
        }
        .ic_c_plus{
          background: none;
        }
        
        &.my-courses {
          padding: 25px 20px;
        }

        @include mixins.breakpoint() {
          [data-abbr]{
            &:after{
              content: attr(data-abbr);
            }
          }
        }
        @include mixins.breakpoint(variables.$small_tablet) {
          [data-abbr]{
            &:after{
              content: attr(data-full);
            }
          }
        }

      }

    }

    .not_found{
      background-color: variables.$white;
      text-align: center;
      font-size: 18px;
      padding: 50px 0;
    }  

  }
  
  .accordion-body {
    background-color: #f6f6f6;
    display: none;
    padding: 0 20px;
    box-sizing: border-box;
    
    &.is-open {
      display: block;
      padding: 40px 20px;
    }
  }

  .row {
    max-width: 380px;
    margin-left: calc(30% - 5px);
  }

}