@use "../utils/mixins";
@use "../utils/variables";

section:not(.hero__section) {

    .section__head {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            padding: 0 20px;
            margin: 20px 0 20px;

        }
        @include mixins.breakpoint(variables.$tablet) {
            padding: 0;
            margin: 20px 0 40px 0;
        }

        .section__title {

            @include mixins.breakpoint() {

                color: variables.$dark_midnight_blue;

                font-size: 18px;
                line-height: 32px;
                font-weight: variables.$regular;

            }
            @include mixins.breakpoint(variables.$small_tablet) {

                font-size: 28px;
                line-height: 32px;

            }

        }

        .section__controls {

            li {

                .section__link {

                    @include mixins.breakpoint() {

                        color: variables.$dark_midnight_blue;

                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;

                        font-size: 14px;
                        line-height: 32px;

                    }
                    @include mixins.breakpoint(variables.$tablet) {
                        font-size: 18px;
                    }

                    .material-icons-outlined {

                        @include mixins.breakpoint() {
                            font-size: 18px;
                        }

                    }

                    &.default-section-link {
                        color: variables.$dark_midnight_blue;
                        &:hover{
                            color: variables.$cardinal;
                        }
                    }

                }

            }

        }

    }

    .section__body {

    }

}

section {

  &.post-score,
  &.stats_section,
  &.my-card-section {

    @include mixins.breakpoint(variables.$small_tablet) {

      width: 730px;
      margin: 0 auto 40px;

    }
    @include mixins.breakpoint(variables.$small_desktop) {
        width: 970px;
    }

  }

}