@use "../utils/variables";

.smartbanner-show {
  &.smartbanner-margin-top {
    margin-top: 80px;
  }
  &.smartbanner-margin-bottom {
    margin-bottom: 80px;
  }
  .smartbanner {
    display: block;
  }
}

.smartbanner {
  left: 0;
  display: none;
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-family: Helvetica Neue,sans-serif;
  background: #f4f4f4;
  z-index: 9998;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;
  border-bottom: 1px solid variables.$border_color;
}

.smartbanner-top {
  position: absolute;
  top: 0;
}

.smartbanner-bottom {
  position: fixed;
  bottom: 0;
}

.smartbanner-container {
  margin: 0 auto;
  padding: 0 5px;
}

.smartbanner-close {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 10px;
  font-size: 22px;
  font-weight: 100;
  text-align: center;
  color: #888;
  text-decoration: none;
  background: transparent;
  border: 0;
  padding: 0 0 1px;
  -webkit-font-smoothing: subpixel-antialiased;
  &:active, &:hover {
    color: #444;
  }
}

.smartbanner-icon {
  width: 57px;
  height: 57px;
  margin-right: 12px;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

.smartbanner-info {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  width: calc(99% - 170px);
  font-size: 12px;
  line-height: 1.2em;
  font-weight: normal;
}

.smartbanner-wrapper {
  max-width: 60px;
  display: inline-block;
  text-align: right;
  width: 100%;
}

.smartbanner-title {
  font-size: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 5px;
}

.smartbanner-description {
  max-height: 40px;
  overflow: hidden;
}

.smartbanner-author {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 3px;
  &:empty + .smartbanner-description {
    max-height: 50px;
  }
}

.smartbanner-button {
  margin: auto 0;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: variables.$regular;
  color: variables.$bright_blue;
  text-decoration: none;
  display: inline-block;
  &:active, &:hover {
    color: #aaa;
  }
}

.smartbanner-ios {
  background: #f2f2f2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  line-height: 80px;
  .smartbanner-close {
    border: 0;
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-family: Arial;
    color: #888;
    -webkit-font-smoothing: none;
    &:active, &:hover {
      color: #888;
    }
  }
  .smartbanner-icon {
    background-size: cover;
  }
  .smartbanner-info {
    color: #6a6a6a;
    text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.8);
    font-weight: 300;
  }
  .smartbanner-title {
    color: #4d4d4d;
    font-weight: 500;
  }
  .smartbanner-button {
    padding: 0 10px;
    font-size: 15px;
    min-width: 10%;
    font-weight: 400;
    color: #0c71fd;
    &:active, &:hover {
      background: #f2f2f2;
    }
  }
}



.smartbanner-kindle, .smartbanner-windows {
  background: #f4f4f4;
  background: linear-gradient(180deg, #f4f4f4, #cdcdcd);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  line-height: 80px;
}

.smartbanner-kindle .smartbanner-close, .smartbanner-windows .smartbanner-close {
  border: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: #888;
  text-shadow: 0 1px 0 #fff;
}

.smartbanner-kindle .smartbanner-close {
  &:active, &:hover {
    color: #aaa;
  }
}

.smartbanner-windows .smartbanner-close {
  &:active, &:hover {
    color: #aaa;
  }
}

.smartbanner-kindle .smartbanner-icon, .smartbanner-windows .smartbanner-icon {
  background: rgba(0, 0, 0, 0.6);
  background-size: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.smartbanner-kindle .smartbanner-info, .smartbanner-windows .smartbanner-info {
  color: #6a6a6a;
  text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.8);
}

.smartbanner-kindle .smartbanner-title, .smartbanner-windows .smartbanner-title {
  color: #4d4d4d;
  font-weight: 700;
}

.smartbanner-kindle .smartbanner-button, .smartbanner-windows .smartbanner-button {
  padding: 0 10px;
  min-width: 10%;
  color: #6a6a6a;
  background: #efefef;
  background: linear-gradient(180deg, #efefef, #dcdcdc);
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px #bfbfbf, 0 1px 0 hsla(0, 0%, 100%, 0.6), inset 0 2px 0 hsla(0, 0%, 100%, 0.7);
}

.smartbanner-kindle .smartbanner-button {
  &:active, &:hover {
    background: #dcdcdc;
    background: linear-gradient(180deg, #dcdcdc, #efefef);
  }
}

.smartbanner-windows .smartbanner-button {
  &:active, &:hover {
    background: #dcdcdc;
    background: linear-gradient(180deg, #dcdcdc, #efefef);
  }
}