@use "../utils/mixins";
@use "../utils/variables";

.events__list {

    @include mixins.breakpoint() {
        background-color: variables.$white;
    }

    li {

        @include mixins.breakpoint() {
            border-bottom: solid 1px variables.$border_color;
        }

        .event {

            @include mixins.breakpoint() {

                padding: 20px;

                display: flex;
                flex-direction: row;
                align-items: center;

            }
            @include mixins.breakpoint(variables.$small_tablet) {

                padding: 30px;

            }

            .event__date {

                @include mixins.breakpoint() {

                    flex: none;

                    width: 60px;
                    height: 100%;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    padding: 0 20px 0 0;
                    margin: 0 20px 0 0;

                    border-right: solid 1px variables.$border_color;

                    box-sizing: content-box;

                }

                .date-month {

                    @include mixins.breakpoint() {

                        color: variables.$faded_blue;

                        font-size: 14px;
                        line-height: 1;

                        text-transform: uppercase;

                    }

                }

                .date-day {

                    @include mixins.breakpoint() {

                        color: variables.$black;

                        font-size: 22px;
                        line-height: 24px;

                    }

                }

            }

            .event__details {

                @include mixins.breakpoint() {

                    display: flex;
                    flex-direction: column;

                    padding: 0 20px 0 0;

                }

                .event__details__name {

                    @include mixins.breakpoint() {

                        color: variables.$faded_blue;

                        font-size: 16px;
                        line-height: 18px;

                        max-height: 36px;
                        overflow: hidden;

                    }
                    @include mixins.breakpoint(variables.$small_tablet) {

                        font-size: 20px;
                        line-height: 20px;

                        max-width: 100%;
                        max-height: 20px;

                        overflow: hidden;
                        text-overflow: ellipsis;

                    }

                }

                .event__details__location {

                    @include mixins.breakpoint() {

                        color: variables.$black;

                        font-size: 14px;
                        line-height: 20px;

                        max-height: 40px;
                        overflow: hidden;

                    }
                    @include mixins.breakpoint(variables.$small_tablet) {
                        font-size: 14px;
                    }

                }

            }

            .material-icons-outlined {

                @include mixins.breakpoint() {

                    flex: none;

                    color: variables.$dark_midnight_blue;

                    margin: 0 0 0 auto;

                }

            }

        }

    }

}