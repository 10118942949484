@use "../utils/mixins";
@use "../utils/variables";

.tooltip {
  height: 20px;
  width: 20px;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  display: inline-block;
  background-color: variables.$legend_gray;
}

.score-panel {
  @include mixins.breakpoint() {
    padding: 30px 20px 20px;
    background-color: variables.$light_gray;
  }

  .score-panel__head {
    @include mixins.breakpoint() {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      margin: 0 0 16px 0;
    }

    .score-panel__title {
      @include mixins.breakpoint() {
        color: variables.$black;

        font-size: 16px;
        line-height: 32px;

        text-align: center;
        text-transform: uppercase;

        position: relative;
      }

      sup {
        @include mixins.breakpoint() {
          position: absolute;

          top: -12px;
          right: -36px;
        }

        .score-panel__info {
          @include mixins.breakpoint() {
            border: none;
            background-color: transparent;
          }

          .material-icons {
            @include mixins.breakpoint() {
              color: variables.$dark_midnight_blue;
            }
          }
        }
      }
    }
  }

  .score-panel__body {
  }
}

.score-legend {
  color: #757575;
  background: white;
  padding: 15px 10px;

  b {
    font-weight: bold;
  }
}

.score-info {
  @include mixins.breakpoint(variables.$small_tablet) {
    margin-left: 0px;
  }
  
  & {
    color: #999999;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.25px;
    margin-left: 25px;
  }

  .title {
    font-weight: variables.$bold;
  }
}
