@use "../utils/mixins";
@use "../utils/variables";

.panel {
  @include mixins.breakpoint() {
    background-color: variables.$white;
  }
  @include mixins.breakpoint(variables.$tablet) {
    @include mixins.box-shadow(0 0 8px 8px rgba(variables.$black, 0.05));
  }
}

.box {
  @include mixins.breakpoint() {
    display: flex;
    flex-direction: column;
  }

  &:not(:only-of-type) {
    &:not(:last-child) {
      @include mixins.breakpoint() {
        margin: 0 0 25px 0;
      }
    }
  }

  &.height100 {
    @include mixins.breakpoint() {
      height: 100%;
    }
  }

  .box-head {
    @include mixins.breakpoint() {
      display: flex;
      flex-direction: column;

      padding: 0 25px;
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      flex-direction: row;
      justify-content: space-between;
    }
    @include mixins.breakpoint(variables.$tablet) {
      padding: 0;
    }

    .box-title {
      @include mixins.breakpoint() {
        color: variables.$dark_midnight_blue;

        font-size: 24px;
        line-height: 1;
        font-weight: variables.$light;

        margin: 0 0 15px 0;
      }
      @include mixins.breakpoint(variables.$small_tablet) {
        font-size: 28px;
        line-height: 40px;
      }
      @include mixins.breakpoint(variables.$small_desktop) {
        font-size: 35px;
      }

      span {
        font-size: 16px;
        line-height: 18px;

        display: block;
      }

      strong {
        font-weight: variables.$bold;
      }
    }

    &.box-head--row {
      @include mixins.breakpoint() {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .box-head-base {
    padding: 20px 0;
    background: variables.$white;
    text-align: center;
    font-size: 18px;
    border-bottom: 1px solid #eee;
    @include mixins.breakpoint(variables.$small_tablet) {
      padding: 25px 0;
      font-size: 20px;
    }
  }

  .box-head-sample {
    padding: 10px 0;
    text-align: center;
    font-size: 15px;
  }
  .box-pre-body {
    @include mixins.breakpoint() {
      padding: 10px 25px;
      border-top: solid 1px variables.$mid_gray_2;
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      padding: 10px 0;
    }
  }

  .box-body {
    @include mixins.breakpoint() {
      background-color: variables.$white;
    }

    &.tablet-mod {
      @include mixins.breakpoint(variables.$small_tablet) {
        padding: 25px 50px;
      }
    }

    &:not(:only-of-type) {
      &:not(:last-child) {
        @include mixins.breakpoint() {
          margin: 0 0 10px 0;
        }
        @include mixins.breakpoint(variables.$small_tablet) {
          margin: 0 0 20px 0;
        }
      }
    }

    &.std {
      @include mixins.breakpoint() {
        padding: 20px 25px;
      }
    }
    &.grow {
      @include mixins.breakpoint() {
        flex-grow: 1;
      }
    }
    &.center {
      @include mixins.breakpoint() {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .box-footer {
    @include mixins.breakpoint() {
      height: 40px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      padding: 0 25px;
      margin: 0 0 10px 0;
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      justify-content: flex-start;

      height: 20px;

      padding: 0;
      margin: 0 0 20px 0;
    }
  }
}
