@use "../utils/mixins";
@use "../utils/variables";

.advanced-stats-panel {
  @include mixins.breakpoint() {
    width: 100%;
  }

  .head-stats__container {
    @include mixins.breakpoint() {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position: relative;
      margin: 0 20px;
      border-bottom: solid 1px variables.$border_color;
    }
    @include mixins.breakpoint(375px) {
      flex-direction: row;
    }

    .head-stat__item {
      @include mixins.breakpoint() {
        flex: none;

        // height: 110px;

        box-sizing: content-box;

        display: flex;
        flex-direction: column;

        &.putts-box {
          flex-grow: 0;
          width: 25%;
        }
        &.gir-box {
          flex-grow: 1;

          @include mixins.breakpoint() {
            width: calc(100% - 40px);
            padding: 10px 0;
            margin: 0 20px;

            border-top: solid 1px variables.$border_color;
          }
          @include mixins.breakpoint(variables.$mobile) {
            width: 75%;
            margin: 0;
            padding: 0 10px;
            position: relative;
            border-top: none;

            &:before {
              content: "";
              position: absolute;
              left: 0;
              // Line height is 90%, so we push it by 5%
              // from top and that makes it look centered.
              top: 5%;
              height: 90%;
              border-left: solid 1px variables.$border_color;
            }
          }
        }
      }

      .head-stat__label {
        @include mixins.breakpoint() {
          display: block;

          text-align: center;

          color: variables.$black;

          font-size: 16px;
          line-height: 1;

          margin: 0 0 10px 0;
        }

        @include mixins.breakpoint(variables.$small_desktop) {
          font-size: 20px;
        }
      }

      .head-stat__value {
        @include mixins.breakpoint() {
          color: variables.$dark_midnight_blue;

          font-size: 36px;
          line-height: 1;
          font-weight: variables.$light;

          text-align: center;
        }
      }
    }
  }

  .centered-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
