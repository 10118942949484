@use "../utils/mixins";
@use "../utils/variables";

.react-datepicker-wrapper {

    .react-datepicker__input-container {

        @include mixins.breakpoint() {
            width: 100%;
        }

        input {

            @include mixins.breakpoint() {

                width: 100%;

                background-repeat: no-repeat;
                background-attachment: scroll;
                background-position: calc(100% - 10px) center;

                background-size: 24px 24px;
                background-image: url('../img/icons/ic_today_24px.svg');

            }

            &:focus {
              border: 3px solid red;
            }

            &.using-mouse:focus {
              border: solid 1px #eff0f2!important;
            }
          }

    }

}

.react-datepicker-popper {

    @include mixins.breakpoint() {

        width: 100vw;
        left: -25px !important;

    }
    @include mixins.breakpoint(variables.$small_tablet) {

        width: 360px;
        left: 0 !important;

    }

    .react-datepicker {

        @include mixins.breakpoint() {

            width: 100%;

            border: solid 1px variables.$border_color;
            border-radius: 0;

        }

        .react-datepicker__navigation {

            @include mixins.breakpoint() {

                width: 24px;
                height: 24px;

                background-repeat: no-repeat;
                background-attachment: scroll;
                background-position: center center;

                background-size: cover;

                border: none;

                top: 10px;

            }

            &.react-datepicker__navigation--previous {

                @include mixins.breakpoint() {
                    left: 10px;
                    background-image: url('../img/icons/ic_chevron_left_24px.svg');
                }

                &:focus-visible {
                  outline: 3px solid red;
                }

            }

            &.react-datepicker__navigation--next {

                @include mixins.breakpoint() {
                    right: 10px;
                    background-image: url('../img/icons/ic_chevron_right_24px.svg');
                }

                &:focus-visible {
                  outline: 3px solid red;
                }

            }

        }

        .react-datepicker__month-container {

            @include mixins.breakpoint() {

                width: 100%;

            }
            &:focus-visible {
              outline: 3px solid red;
            }

            .react-datepicker__header {

                @include mixins.breakpoint() {

                    background-color: variables.$white;
                    border: none;

                    padding: 0;
                    margin: 0;

                }

                .react-datepicker__current-month {

                    @include mixins.breakpoint() {

                        height: 45px;

                        font-size: 18px;
                        line-height: 45px;
                        font-weight: variables.$regular;

                        background-color: variables.$light_blue;

                    }

                }

                .react-datepicker__header__dropdown {

                }

                .react-datepicker__day-names {

                    @include mixins.breakpoint() {

                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        padding: 10px 0;
                        margin: 0 10px;

                        border-bottom: solid 1px variables.$border_color;

                        text-transform: uppercase;

                    }

                    .react-datepicker__day-name {

                        @include mixins.breakpoint() {

                            flex: none;

                            width: 40px;
                            height: 40px;

                            font-size: 14px;
                            line-height: 40px;
                            font-weight: variables.$medium;

                            margin: 0;

                        }

                    }

                }

            }

            .react-datepicker__month {

                @include mixins.breakpoint() {

                    margin: 0;
                    padding: 10px;

                    display: flex;
                    flex-direction: column;

                }

                .react-datepicker__week {

                    @include mixins.breakpoint() {

                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                    }

                    .react-datepicker__day {

                        @include mixins.breakpoint() {

                            flex: none;

                            width: 40px;
                            height: 40px;

                            font-size: 14px;
                            line-height: 40px;
                            font-weight: variables.$medium;

                            text-align: center;

                            margin: 0;

                        }

                        &:hover {

                            @include mixins.breakpoint() {

                                border-radius: 50%;

                                background-color: rgba(variables.$dark_midnight_blue,0.0625);

                            }

                        }

                        &.react-datepicker__day--selected {

                            @include mixins.breakpoint() {

                                border-radius: 50%;

                                background-color: variables.$dark_midnight_blue;

                            }

                        }

                        &.react-datepicker__day--weekend {
                            @include mixins.breakpoint() {
                            }
                        }

                        &.react-datepicker__day--outside-month {
                        }

                    }

                }

            }

        }

    }

    //  RENDERED ABOVE INPUT
    &[data-placement="top-start"] {


        .react-datepicker__triangle {

            &:before {

                @include mixins.breakpoint() {
                    border-bottom-color: variables.$white !important;
                    border-top-color: variables.$border_color !important;
                }

            }

        }

    }

    //  RENDERED BELLOW INPUT
    &[data-placement="bottom-start"] {

        .react-datepicker__triangle {

            @include mixins.breakpoint() {
                border-bottom-color: transparent;
            }

            &:before {

                @include mixins.breakpoint() {
                    border-bottom-color: variables.$light_blue !important;
                    border-top-color: variables.$border_color !important;
                }

            }

        }

    }

}
