@use "../utils/mixins";
@use "../utils/variables";

.donut {
  font-size: 15px;
  @include mixins.breakpoint() {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }

  //  DONUT
  .donut__container {
    @include mixins.breakpoint() {
      position: relative;
    }

    //  RECHARTS
    .recharts-wrapper {
      svg {
        .recharts-pie {
          .recharts-pie-labels {
            .donut__label {
              @include mixins.breakpoint() {
                vertical-align: top;
              }

              .label-value {
                @include mixins.breakpoint() {
                  font-size: 2.3em;
                  line-height: 32px;
                }
              }

              .label-percent {
                @include mixins.breakpoint() {
                  font-size: 1.6em;
                  line-height: 32px;
                  font-weight: variables.$light;
                }
              }
            }
          }
        }
      }
    }

    //  DONUT MID
    .donut-mid {
      @include mixins.breakpoint() {
        width: 180px;
        height: 180px;
        display: block;
        border-radius: 50%;
        background-color: variables.$white;
        position: absolute;
        // Formula: (width donut - width donut-mid) / 2
        top: 85px;
        left: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .donut-mid__percentage {
        @include mixins.breakpoint() {
          color: variables.$black;
          font-family: 'National Book', sans-serif;
          font-size: 4.4em;
          line-height: 64px;
          letter-spacing: -6px;
          text-align: center;
          position: relative;
          margin: 0;
        }
        sup {
          @include mixins.breakpoint() {
            color: variables.$black;
            font-size: 26px;
            line-height: 20px;
            font-weight: variables.$bold;
            position: absolute;
            top: 0;
            right: -10px;
          }
        }
      }

      .donut-mid__label {
        @include mixins.breakpoint() {
          text-transform: uppercase;
          font-size: 27px;
        }
      }
    }
  }

  //  DONUT LEGEND
  .donut-legend {
    @include mixins.breakpoint() {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      flex-wrap: wrap;

      padding: 20px 0;
    }
  
    @include mixins.breakpoint(variables.$small_desktop) {
      padding: 20px;
    }

    li {
      @include mixins.breakpoint() {
        flex: none;

        color: variables.$black;

        font-size: 18px;
        line-height: 24px;

        padding: 0 10px;

        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .donut-legend__color {
        @include mixins.breakpoint() {
          display: block;

          width: 8px;
          height: 8px;

          margin: 0 8px 0 0;

          border-radius: 2px;
        }

        &.light-blue {
          @include mixins.breakpoint() {
            background-color: variables.$legend_light_blue;
          }
        }

        &.dark-blue {
          @include mixins.breakpoint() {
            background-color: variables.$legend_dark_blue;
          }
        }

        &.gray {
          @include mixins.breakpoint() {
            background-color: variables.$legend_gray;
          }
        }

        &.light-red {
          @include mixins.breakpoint() {
            background-color: variables.$legend_light_red;
          }
        }

        &.dark-red {
          @include mixins.breakpoint() {
            background-color: variables.$legend_dark_red;
          }
        }
      }
    }
  }

  //  DONUT STATS
  .donut-stats {

    margin: 50px 0 0 0;
    @include mixins.breakpoint() {
      display: flex;
      flex-direction: row;
    }

    .donut-stat__item {
      @include mixins.breakpoint() {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 0 20px;

        border-right: solid 1px variables.$border_color;
      }

      &:last-child {
        @include mixins.breakpoint() {
          border-right: none;
        }
      }

      .donut-stat__label {
        @include mixins.breakpoint() {
          color: variables.$black;

          font-size: 20px;
          line-height: 20px;
        }
      }

      .donut-stat {
        @include mixins.breakpoint() {
          color: variables.$legend_dark_blue;

          font-size: 36px;
          line-height: 1;
          font-weight: variables.$light;
        }
      }
    }
  }

  &.small {
    .recharts-wrapper {
      svg {
        .recharts-pie {
          .recharts-pie-labels {
            .donut__label {
              .label-value {
                @include mixins.breakpoint() {
                  font-size: 1.7em;
                }
              }
            }
          }
        }
      }
    }
    .donut-mid {
      @include mixins.breakpoint() {
        width: 100px;
        height: 100px;
        top: 60px;
        left: 60px;
      }
      .donut-mid__percentage {
        @include mixins.breakpoint() {
          font-size: 2.9em;
          line-height: 40px;
        }
        sup {
          @include mixins.breakpoint() {
            font-size: 15px;
            line-height: 10px;
            top: 0;
            right: -10px;
          }
        }
      }
      .donut-mid__label {
        @include mixins.breakpoint() {
          font-size: 15px;
        }
      }
    }
  }
}
