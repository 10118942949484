@use "../utils/mixins";

.recent-scores__slider {

    @include mixins.breakpoint() {
        padding: 20px;
    }

    &.typeB {
        @include mixins.breakpoint() {
            padding: 20px 25px;
        }
    }

}