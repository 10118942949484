@use "../utils/mixins";
@use "../utils/variables";

.container {
    width: 100%;
    max-width: 1230px;
    margin: 0 auto;
    padding: 0;
    &.has-adds{
        @include mixins.breakpoint(variables.$small_tablet) {
            width: 730px;
        }
        @include mixins.breakpoint(variables.$small_desktop) {
            width: 970px;
        }
    }
}

.promo_google_banner{
    display: none;
    @include mixins.breakpoint(variables.$small_tablet) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0;
    }
}
.promo_homepage_banner{
    display: flex;
    flex-direction: column;
    align-items: center;
}
