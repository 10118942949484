@use "../utils/mixins";
@use "../utils/variables";

//  FORM INFO
.info {

  @include mixins.breakpoint() {

    font-size: 16px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 20px 0;

  }

  &.relativ {
    @include mixins.breakpoint() {
      position: relative;
    }
  }

  p {
    @include mixins.breakpoint() {
      font-size: 16px;
    }
  }

  strong {
    @include mixins.breakpoint() {
      font-weight: variables.$bold;
    }
    &.large {
      @include mixins.breakpoint() {
        font-size: 32px;
        display: inline-block;
        margin: 0 0 0 10px;
      }
    }
  }

  //  TRIGGER CONTAINER
  sup {
    @include mixins.breakpoint() {
      button{
        padding: 0;
      }
      @include mixins.transform(translate3d(0,-12px,0));
    }
  }

}