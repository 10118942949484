@use "../utils/mixins";
@use "../utils/variables";

.radio_container {
  &.inline {
    @include mixins.breakpoint() {
      display: flex;
      flex-direction: row;
    }
  }

  &.is-full {
    @include mixins.breakpoint() {
      width: 100%;
    }
  }

  //  PILLS
  input[type="radio"] {
    &.pill {
      @include mixins.breakpoint() {
        position: absolute;
        transform: scale(0);
      }

      & + label {
        @include mixins.breakpoint() {
          flex: 1 1 0;

          height: 35px;

          color: variables.$dark_midnight_blue;

          font-size: 14px;
          line-height: 35px;

          display: block;
          text-align: center;

          border: solid 1px variables.$border_color;
        }

        &:first-of-type {
          @include mixins.breakpoint() {
            border-top-left-radius: 18px;
            border-bottom-left-radius: 18px;
          }
        }

        &:last-of-type {
          @include mixins.breakpoint() {
            border-top-right-radius: 18px;
            border-bottom-right-radius: 18px;

            border-left: none;
          }
        }
      }

      &:focus-visible + label {
        @include mixins.breakpoint() {
        outline: 2px solid red!important;
        }
      }

      &:checked {
        & + label {
          @include mixins.breakpoint() {
            color: variables.$white;
            background-color: variables.$dark_midnight_blue;

            border-color: variables.$dark_midnight_blue;
          }
        }
      }
    }
  }
}
