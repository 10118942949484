@use "../utils/mixins";
@use "../utils/variables";

.navigation {

    @include mixins.breakpoint() {
        display: none;
    }
    @include mixins.breakpoint(variables.$small_tablet) {

        display: block;

        height: 40px;

        background-color: rgba(variables.$dark_midnight_blue, 0.9);

    }


    .nav__links {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            padding: 0;

        }
        @include mixins.breakpoint(variables.$small_desktop) {
        }

        li {

            .nav__link {

                @include mixins.breakpoint() {

                    color: variables.$white;
                    font-size: 16px;
                    line-height: 40px;
                    font-weight: variables.$bold;
                    letter-spacing: 0.5px;

                    white-space: nowrap;

                    padding: 0 10px;
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    transition: all .25s ease;

                }
                @include mixins.breakpoint(variables.$small_desktop) {
                    letter-spacing: 1.8px;
                }
                &:hover {
                    background: rgba(0,136,206,.2);
                }
                &.is-active {
                    background: #007AB8;
                }

            }

        }

    }

}