@use "../utils/mixins";
@use "../utils/variables";

.user-menu__container {
  @include mixins.breakpoint() {
    display: none;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    position: relative;

    margin: 0 0 0 auto;

    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
  }
  @include mixins.breakpoint(variables.$small_desktop) {
    margin: 0 15px 0 auto;
  }
  .user-menu__trigger {
    @include mixins.breakpoint() {
      background-color: transparent;
      border: none;

      display: flex;
      flex-direction: row;
      align-items: center;

      color: variables.$dark_midnight_blue;

      font-family: "National Book", sans-serif;

      font-size: 16px;
      line-height: 40px;
      font-weight: variables.$medium;

      @include mixins.user-select(none);

      position: relative;

      padding: 0 32px 0 0;
    }

    &.border-focus {
      &:focus-visible {
        outline: 3px solid red;
      }
    }

    @include mixins.breakpoint(variables.$small_desktop) {
      font-size: 20px;
    }

    .material-icons-outlined {
      @include mixins.breakpoint() {
        color: variables.$dark_midnight_blue;

        width: 24px;

        flex: none;

        position: absolute;
        right: 0;
      }

      &.more {
        @include mixins.breakpoint() {
          display: block;
        }
      }

      &.less {
        @include mixins.breakpoint() {
          display: none;
        }
      }
    }

    & + .user-menu__content {
      @include mixins.breakpoint() {
        display: none;

        position: absolute;

        top: 72px;
        right: -20px;

        width: 240px;

        background-color: variables.$white;
      }
      @include mixins.breakpoint(variables.$tablet) {
        right: -25px;
      }
    }

    &.is-active {
      .material-icons-outlined {
        &.more {
          @include mixins.breakpoint() {
            display: none;
          }
        }

        &.less {
          @include mixins.breakpoint() {
            display: block;
          }
        }
      }

      & + .user-menu__content {
        @include mixins.breakpoint() {
          display: block;
          text-transform: uppercase;
        }
      }
    }
  }
}
