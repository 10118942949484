@use "../utils/mixins";
@use "../utils/variables";

.ReactModal__Overlay {
  z-index: 1000;
  background-color: rgba(127, 127, 127, 0.5);
}
.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  background: variables.$white;
  overflow: auto;
  outline: none;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 100%;

  .modal-content {
    position: relative;
    z-index: 100;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: variables.$white;
    padding: 20px;

    .modal_close {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
      font-size: 20px;
      background: none;
      border: none;
      &:hover {
        background: none;
        border: none;
      }
    }

    .modal-body {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5% 5% 0 5%;
      h3 {
        font-size: 22px;
        line-height: 1em;
        margin-bottom: 20px;
        color: variables.$dark_midnight_blue;
        text-align: center;
      }
      p {
        text-align: center;
        font-size: 18px;
        line-height: 1.4em;
        font-weight: variables.$light;
      }
      .content {
        align-items: center;
        justify-content: center;
      }
      .error {
        text-align: center;
        font-size: 16px;
        font-weight: variables.$light;
        color: variables.$cardinal;
        padding-top: 1em;
      }
      .actions {
        display: flex;
        flex-wrap: wrap;
        padding-top: 35px;
        .btn,
        .btn.fill,
        .btn.outline {
          flex: 1 0 200px;
          margin: 10px;
        }
        &.centered {
          justify-content: center;
          .btn,
          .btn.fill,
          .btn.outline {
            flex-grow: 0;
            margin: 0 10px;
          }
        }
      }
      .socials {
        display: flex;
        flex-direction: column;
        align-items: center;
        a,
        a:hover,
        a:visited {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          width: 140px;
          color: variables.$dark_midnight_blue;
          text-transform: uppercase;
          font-weight: variables.$medium;
        }
        span {
          display: inline-block;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-repeat: no-repeat;
          background-size: auto 22px;
          background-position: center center;
          margin-right: 15px;
          &.twitter {
            background-image: url("../img/icons/ic_social_twitter.svg");
            background-color: rgba(42, 170, 224, 1);
          }
          &.facebook {
            background-image: url("../img/icons/ic_social_fb.svg");
            background-color: rgba(41, 83, 150, 1);
          }
          &.linkedin {
            background-image: url("../img/icons/ic_social_linkedin.svg");
            background-color: rgba(1, 115, 178, 1);
          }
        }
      }
      .login {
        p {
          line-height: 1.2em;
        }
      }
      .success-icon {
        height: 50px;
        background: url("../img/icons/check_icon.svg") no-repeat center;
        margin-bottom: 0.5em;
      }
    }
  }
}

@include mixins.breakpoint(variables.$small-tablet) {
  .modal-dialog {
    max-height: 350px;
  }
}

@include mixins.breakpoint(variables.$small_desktop) {
  .modal-dialog {
    .modal-content {
      .modal-body {
        h3 {
          font-size: 26px;
        }
        p {
          font-size: 22px;
          line-height: 1.3em;
        }
      }
    }
  }
}
