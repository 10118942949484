@use "../utils/mixins";
@use "../utils/variables";

.menu-ham__container {
  @include mixins.breakpoint() {
    margin: 0 16px 0 0;

    height: 100%;
    background-color: variables.$white;
    text-transform: uppercase;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  @include mixins.breakpoint(variables.$small_desktop) {
    margin: 0 16px;
  }

  //  SPECIAL PROP
  //  IF USER IS NOT LOGGED IN ON TABLET AND ABOVE WE DON'T SHOW MENU
  //  SINCE LINKS WILL BE AVAILABLE IN NAVIGATION
  &.hide-on-tablet-and-above {
    @include mixins.breakpoint(variables.$small_tablet) {
      display: none;
    }
  }

  p.invisible {
    display: none;
  }

  .menu-ham__trigger {
    @include mixins.breakpoint() {
      width: 24px;
      height: 100%;

      padding: 0;
      margin: 0;

      background-color: variables.$white;
      border: none;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      -webkit-tap-highlight-color: transparent;

      position: relative;
      z-index: 9;
    }

    &.border-focus {
      &:focus-visible {
        outline: 3px solid red;
      }
    }

    span {
      @include mixins.breakpoint() {
        display: block;

        width: 24px;
        height: 3px;

        background-color: variables.$dark_midnight_blue;

        margin: 0 0 3px 0;

        will-change: transform, opacity;
        transform-origin: center center;

        @include mixins.transition(all 300ms ease-in-out 0ms);

        border-radius: 1px;
      }

      &:first-child {
      }

      &:last-child {
        @include mixins.breakpoint() {
          margin: 0;
        }
      }
    }

    //  DEFAULT STATE
    & + .menu-ham__content {
      @include mixins.breakpoint() {
        width: 100vw;
        min-height: calc(100vh - 60px);

        position: absolute;
        z-index: 99;

        top: 60px;
        left: -100%;

        background-color: variables.$white;

        opacity: 0;
        will-change: position, opacity;

        @include mixins.transition(all 300ms ease-in-out 0ms);
      }
      @include mixins.breakpoint(variables.$small_tablet) {
        min-height: calc(100vh - 72px);

        top: 72px;

        width: 300px;
      }
      @include mixins.breakpoint(variables.$small_desktop) {
        min-height: calc(100vh - 90px);

        top: 90px;
      }
    }

    //  ACTIVE STATE
    &.is-active {
      span {
        @include mixins.breakpoint() {
          opacity: 0;
          margin: 0;
        }

        &:first-child,
        &:last-child {
          @include mixins.breakpoint() {
            height: 2px;
          }
        }

        &:first-child {
          @include mixins.breakpoint() {
            opacity: 1;
            @include mixins.transform(translate3d(0, 2px, 0) rotate(45deg));
          }
        }

        &:last-child {
          @include mixins.breakpoint() {
            @include mixins.breakpoint() {
              opacity: 1;
              @include mixins.transform(translate3d(0, -3px, 0) rotate(-45deg));
            }
          }
        }
      }

      & + .menu-ham__content {
        @include mixins.breakpoint() {
          position: absolute;

          left: 0;

          background-color: variables.$white;

          opacity: 1;

          max-height: 50vh;
          overflow: scroll;
        }
        @include mixins.breakpoint(variables.$small_desktop) {
          top: 90px;
          left: 0;
        }
      }
    }
  }
}
