@use "../utils/variables";

.forgot-login {
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  &.no-padding {
    padding: 0;
  }

  .top-label {
    font-size: 18px;
    line-height: 1.2em;
    font-family: "National", sans-serif;
    padding-bottom: 25px;
    font-weight: variables.$medium;
  }

  .forgot-form {
    input[type="text"],
    input[type="password"] {
      font-family: "National", sans-serif;
      border-radius: 1.5px;
      font-size: 16px;
      height: 43px;
    }
    input[type="text-disabled"] {
      font-family: National, sans-serif;
      font-size: 18px;
      height: 43px;
      background-color: transparent;
      border-width: 0px;
      margin-top: -10px;
      margin-left: -1px;
    }
    label {
      margin-top: 10px;
      font-family: National, sans-serif;
      font-size: 16px;
      font-weight: variables.$regular;
      &.disable {
        font-family: National, sans-serif;
        font-size: 18px;
        font-weight: variables.$medium;
      }
    }
    span.error {
      color: red;
      font-size: 15px;
      line-height: 15px;
      font-family: National, sans-serif;
      margin-top: 8px;
      margin-top: 8px;
    }
  }
  .show_password_wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .show_password {
    position: absolute;
    align-self: flex-end;
  }
  .show_button {
    padding: 0.3em 1.4em;
    color: variables.$legend_gray_dark;
  }
}

.field_place_holder {
  display: flex;
  margin-top: 6em;
}
