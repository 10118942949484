@use "../utils/mixins";
@use "../utils/variables";

footer {
  @include mixins.breakpoint() {
    margin: auto 0 0 0;
    background-color: variables.$white;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    padding: 20px 0;
  }

  .footer__container {
    @include mixins.breakpoint() {
      display: flex;
      flex-direction: column;
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      padding: 0 20px;
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      width: 1230px;

      margin: 0 auto;
      padding: 0;
    }

    //  INFORMATION
    .information__container {
      @include mixins.breakpoint(variables.$small_desktop) {
        display: flex;
        flex-direction: row;
      }
    }

    //  PARTNERS
    .partners {
      @include mixins.breakpoint() {
        padding: 20px;

        border-bottom: solid 1px variables.$bg_gray;
      }
      @include mixins.breakpoint(variables.$small_tablet) {
        border-bottom: none;
      }
      @include mixins.breakpoint(variables.$small_desktop) {
        flex: 1 1 0;
      }

      .partners__title {
        @include mixins.breakpoint() {
          color: variables.$dark_midnight_blue;

          font-size: 14px;
          line-height: 20px;
          font-weight: variables.$bold;

          text-align: center;
          text-transform: uppercase;

          margin: 0 0 40px 0;
        }
        @include mixins.breakpoint(variables.$small_tablet) {
          font-size: 16px;
        }
      }

      .partners__list {
        @include mixins.breakpoint() {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        @include mixins.breakpoint(variables.$small_tablet) {
          margin: 0;
        }
        @include mixins.breakpoint(variables.$small_desktop) {
          height: 140px;
          margin: 0;
        }

        .partner__item {
          width: 50%;
          flex: none;

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          margin: 0 0 14px 0;

          @include mixins.breakpoint(variables.$small_tablet) {
            width: auto;
            flex: 1 1 auto;
          }
          
          &.w-33 {
            @include mixins.breakpoint(variables.$tablet) {
              width: 33.333333%;
              flex: none;
            }
          }

          &.w-25 {
            @include mixins.breakpoint(variables.$tablet) {
              width: 25%;
              flex: none;
            }
          }

          :not(.american-express) {
            @include mixins.breakpoint(variables.$small_tablet) {
              padding: 0 10px;
            }
          }

          .partner__logo {
            width: 100%;
            height: 80px;

            display: block;
            object-fit: contain;
          }

          &.sentry {
            @include mixins.breakpoint(variables.$tablet) {
              display: flex;
              flex-direction: row;
            }
            .partner__logo {
              @include mixins.breakpoint() {
                width: auto;
                height: 48px;
              }
            }
          }
          &.american-express {
            @include mixins.breakpoint(variables.$tablet) {
              display: flex;
              flex-direction: row;
            }
            .partner__logo {
              @include mixins.breakpoint() {
                width: 90px;
                height: 90px;
              }
            }
          }
          &.ally {
            @include mixins.breakpoint(variables.$tablet) {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
            }
            .partner__logo {
              @include mixins.breakpoint() {
                width: 90px;
                height: 90px;
              }
            }
          }
          &.cisco {
            @include mixins.breakpoint(variables.$tablet) {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
            }
            .partner__logo {
              @include mixins.breakpoint() {
                width: 96px;
                height: 67px;
              }
            }
          }
          &.delloite {
            @include mixins.breakpoint(variables.$tablet) {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
            }
            .partner__logo {
              @include mixins.breakpoint() {
                width: 156px;
                height: 67px;
              }
            }
          }
          &.lexus {
            .partner__logo {
              margin-top: 15px;
              @include mixins.breakpoint() {
                width: 215px;
                height: 67px;
                justify-content: flex-start;
              }
            }
          }
          &.rolex {
            @include mixins.breakpoint(variables.$tablet) {
              display: flex;
              flex-direction: row;
            }
            .partner__logo {
              @include mixins.breakpoint() {
                width: 156px;
                height: 67px;
              }
            }
          }
        }
      }
    }

    //  GHIN MOBILE APP
    .mobile__app {
      @include mixins.breakpoint() {
        padding: 20px 0;
      }
      @include mixins.breakpoint(variables.$small_tablet) {
        flex: none;
        width: 280px;

        margin: 0 auto 20px auto;
      }
      @include mixins.breakpoint(variables.$small_desktop) {
        border-left: solid 1px variables.$bg_gray;
      }

      .app__title {
        @include mixins.breakpoint() {
          color: variables.$dark_midnight_blue;

          font-size: 14px;
          line-height: 20px;
          font-weight: variables.$bold;

          text-align: center;
          text-transform: uppercase;

          margin: 0 0 40px 0;
        }
        @include mixins.breakpoint(variables.$small_tablet) {
          font-size: 16px;
        }
      }

      .sentry-logo-container {
        @include mixins.breakpoint() {
          max-width: 100%;
        }
        .sentry-logo {
          @include mixins.breakpoint() {
            max-width: calc(100% - 40px);
            margin: 0 20px 20px;
          }
        }
      }

      .div-with-border {
        @include mixins.breakpoint(variables.$small_desktop) {
        }
      }

      .app__details {
        @include mixins.breakpoint() {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          flex-wrap: wrap;
        }
        @include mixins.breakpoint(variables.$small_desktop) {
          padding: 20px;
        }

        .app__logo {
          @include mixins.breakpoint() {
            width: 80px;
            height: 80px;

            display: block;
            margin: 0 20px 0 0;

            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: center;
            background-size: cover;

            background-image: url("../img/logos/logo-ghin-app-icon.svg");

            border-radius: 15px;
          }
        }

        .app__stores {
          @include mixins.breakpoint() {
            max-height: 100px;
          }

          li {
            @include mixins.breakpoint() {
              margin: 0 0 20px 0;
            }

            &:last-child {
              @include mixins.breakpoint() {
                margin: 0;
              }
            }

            .store {
              @include mixins.breakpoint() {
                width: 135px;
                height: 40px;

                display: block;
                margin: 0 0 20px 0;

                background-repeat: no-repeat;
                background-attachment: scroll;
                background-position: center center;
                background-size: cover;

                color: transparent;
                line-height: 0;
                font-size: 0;
              }

              &.app-store {
                @include mixins.breakpoint() {
                  background-image: url("../img/logos/badge_app_store.svg");
                }
              }

              &.google-play {
                @include mixins.breakpoint() {
                  background-image: url("../img/logos/badge_google_play.svg");
                }
              }

              &:focus-visible {
                outline: 3px solid red;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }

    //  FOOTER LINKS
    .footer__links {
      @include mixins.breakpoint() {
        display: flex;
        flex-direction: column;
      }
      @include mixins.breakpoint(variables.$small_tablet) {
        display: flex;
        flex-direction: row;
        justify-content: center;

        padding: 40px 0 20px 0;

        border-top: solid 1px variables.$bg_gray;
      }

      li {
        @include mixins.breakpoint() {
          border-top: solid 1px variables.$bg_gray;
        }
        @include mixins.breakpoint(variables.$small_tablet) {
          flex: none;

          border-top: none;
          border-right: solid 1px variables.$dark_midnight_blue;

          padding: 0 10px;
        }
        @include mixins.breakpoint(variables.$small_desktop) {
          padding: 0 20px;
        }

        &:last-child {
          @include mixins.breakpoint() {
            border-bottom: solid 1px variables.$bg_gray;
          }
          @include mixins.breakpoint(variables.$small_tablet) {
            border-bottom: none;
            border-right: none;
          }
        }

        .footer__link {
          @include mixins.breakpoint() {
            color: variables.$dark_midnight_blue;

            font-size: 14px;
            line-height: 20px;
            font-weight: variables.$bold;

            text-align: center;

            padding: 15px 0;
            display: block;

            &:focus-visible {
              color: red;
            }
          }
          @include mixins.breakpoint(variables.$small_tablet) {
            line-height: 18px;

            padding: 0;
          }
        }
      }
    }

    //  COPYRIGHT
    .copyright__container {
      @include mixins.breakpoint() {
        padding: 20px 0;
      }

      p {
        @include mixins.breakpoint() {
          color: variables.$legend_gray_dark;

          font-size: 14px;
          line-height: 20px;

          letter-spacing: -0.25px;

          text-align: center;
        }
        @include mixins.breakpoint(variables.$small_tablet) {
          letter-spacing: 0;
        }
      }
    }

    //  accessibility
    .accessibility__container {
      @include mixins.breakpoint() {
        padding: 15px 0;
      }

      @include mixins.breakpoint(variables.$small_tablet) {
        padding: 5px 0;
      }

      .logo {
        width: 100%;
        height: 30px;
        display: block;
        object-fit: contain;
      }
    }
  }
}
