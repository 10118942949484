@use "../utils/mixins";
@use "../utils/variables";

.notifications__container {

    @include mixins.breakpoint() {
        margin: 0 0 0 auto;
    }

    .notifications__trigger {

        @include mixins.breakpoint() {

            width: 32px;
            height: 32px;

            font-size: 24px;
            line-height: 32px;

            text-align: center;

            background-color: transparent;
            border: none;

            margin: 0;
            padding: 0;

            position: relative;

        }

        .material-icons-outlined {

            @include mixins.breakpoint() {

                color: variables.$dark_midnight_blue;

                font-size: 24px;
                line-height: 32px;

                display: block;

            }

        }

        .notifications__value {

            @include mixins.breakpoint() {

                color: variables.$white;

                font-size: 12px;
                line-height: 20px;
                font-weight: variables.$bold;

                text-align: center;

                min-width: 20px;
                display: inline-block;

                background-color: variables.$cardinal;
                border-radius: 50%;

                position: absolute;

                right: -8px;
                top: -8px;

            }

        }

    }

    .notifications__content {

        @include mixins.breakpoint() {

            display: none;

            //  TO DO

        }

    }

}