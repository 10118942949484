.end-of-year-button {
  padding: 0;
  border: none;
  background: none;
}

.end-of-year-span {
  font-family: "National";
}

.end-of-year-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.end-of-year-padding {
  padding: 30px !important;
}
.end-of-year-share-image {
  display: block;
  margin: auto;
}

.end-of-year-body {
  width: 440px;
  height: 783px;
}
.buttons-container {
  z-index: 10000;
  position: absolute;
  left: 10px;
  margin-right: 5px;
  bottom: 0px;

  .share-button {
    margin-right: 10px;
  }

  .twitter_icon {
    background: url("../img/icons/twitter.svg") no-repeat center;
    background-size: 24px !important;
    width: 50px !important;
    height: 50px !important;
    background-color: #000000;
    border-radius: 100px;
    margin: 0 2px 1px 0;
  }
}

@media only screen and (min-width: 391px) and (max-width: 1310px) {
  .end-of-year-body {
    width: 300px;
    height: 534px;
  }
}

@media only screen and (min-width: 1310px) and (max-width: 1440px) {
  .end-of-year-body {
    width: 339px;
    height: 603px;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1730px) {
  .end-of-year-body {
    width: 428px;
    height: 761px;
  }
}

@media only screen and (max-width: 391px) {
  .end-of-year-body {
    width: 200px;
    height: 356px;
  }
  
  .end-of-year-padding {
    padding: 5px !important;
  }
}
