@use "../utils/mixins";
@use "../utils/variables";

.usga_logo {
    
    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        color: variables.$dark_midnight_blue;

        font-size: 13px;
        line-height: 12px;
        font-weight: variables.$bold;

        text-transform: uppercase;

        @include mixins.user-select(none);

        height: 100%;
        background-color: variables.$white;

        position: relative;
        z-index: 9;
        cursor: pointer;

    }
    @include mixins.breakpoint(variables.$small_desktop) {

        font-size: 16px;
        line-height: 16px;

    }

    &::before {

        @include mixins.breakpoint() {

            content: '';
            display: block;

            width: 80px;
            height: 24px;

            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: center;

            background-size: contain;

            background-image: url('../img/logos/logo_usga.svg');

            margin: 0 8px 0 0;

        }
        @include mixins.breakpoint(variables.$small_desktop) {

            width: 90px;
            height: 32px;

        }

    }

}