@use "../utils/mixins";
@use "../utils/variables";

.page_container {
  width: 100%;
  max-width: 970px;
  margin: 0 auto;
  padding: 20px 0 50px 0;
  &.contact {
    max-width: 830px;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    padding: 20px;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    padding: 30px 0;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  // header styles
  h1 {
    color: variables.$dark_midnight_blue;
    font-size: 24px;
    font-weight: variables.$light;
    line-height: 1.2em;
    margin-bottom: 18px;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    h1 {
      font-size: 30px;
    }
  }
  @include mixins.breakpoint(variables.$small_desktop) {
    h1 {
      font-size: 35px;
    }
  }

  h3 {
    font-size: 25px;
    line-height: 1em;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    h3 {
      font-size: 28px;
    }
  }

  // text styles
  p,
  select,
  option,
  textarea,
  label {
    font-size: 16px;
    border-color: variables.$border_color;
  }
  input[type="text"].large,
  input[type="password"].large,
  input[type="email"].large {
    font-size: 16px;
    border-color: variables.$border_color;
    &:focus {
      border-color: variables.$border_color;
    }
    &.error {
      border-color: variables.$legend_light_red;
    }
  }
  @include mixins.breakpoint(variables.$small_desktop) {
    p,
    select,
    option,
    textarea,
    label {
      font-size: 18px;
    }
  }

  p {
    margin: 1em 0;
    line-height: 1.5em;
    font-family: "National", sans-serif;
  }
  .top-note {
    margin-top: 0;
    color: variables.$text_muted;
    font-size: 90%;
    font-style: italic;
    font-weight: variables.$regular;
    position: relative;
    top: 10px;
  }
  .help-text {
    margin: 0;
    font-size: 88%;
    color: variables.$text_muted;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: right;
    span {
      display: inline-block;
      min-width: 40px;
    }
  }
  .text-muted {
    color: variables.$text_muted;
  }

  // page content
  .box-content {
    padding: 0 20px;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    .box-content {
      padding: 0;
    }
  }

  .box-panel {
    background-color: variables.$white;
    padding: 20px;
    &.center {
      justify-content: center;
      display: flex;
    }
    &.no-margins {
      padding: 0;
      padding: 0;
    }
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    .box-panel {
      padding: 30px 30px;
      &.no-margins {
        padding: 0;
        padding: 0;
      }
    }
  }
  @include mixins.breakpoint(variables.$small_desktop) {
    .box-panel {
      padding: 25px 50px;
      &.no-margins {
        padding: 0;
        padding: 0;
      }
    }
  }

  // video full frame
  .video_wrapper {
    position: relative;
    padding: 28% 0 28% 0;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  // form layout
  .form-row {
    display: flex;
    flex-direction: column;
    padding: 0;
    .form-group {
      flex: 1 0 auto;
      padding: 12px 0;
      position: relative;
      span.error {
        position: absolute;
        bottom: -17px;
        left: 0;
        color: variables.$legend_light_red;
        font-style: italic;
        font-size: 14px;
      }
    }
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    .form-row {
      flex-direction: row;
      padding: 12px 0;
      .form-group {
        flex: 1 0 0;
        padding: 0 5px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .buttons-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    max-width: 380px;
    margin: 0 auto;
    .lnk {
      text-decoration: none;
      padding-left: 0;
    }
    .cardinal {
      flex-grow: 1;
    }
  }

  label {
    color: inherit;
    font-weight: variables.$regular;
  }
  textarea {
    border: 1px solid variables.$border_color;
    width: 100%;
    padding: 20px;
  }

  // GHIN Associations
  .box-content.fix-right-links {
    padding: 0 50px 0 20px;
    @include mixins.breakpoint(variables.$small_desktop) {
      padding: 0;
    }
  }

  &.ghin_associations {
    .out_link {
      box-sizing: border-box;
      display: inline-block;
      color: variables.$bright_blue;
      background-image: url("../img/icons/ic_out_link.svg");
      background-repeat: no-repeat;
      background-position: right 0;
      background-size: 18px 18px;
      padding-right: 25px;
      line-height: 27px;
      cursor: pointer;
    }

    .hr_anchor {
      text-align: center;
      border-bottom: 1px solid variables.$border_color;
      line-height: 0.1em;
      margin: 10px 0 0;
      padding-top: 30px;
      position: relative;
      top: -20px;
      span {
        font-size: 18px;
        color: variables.$dark_midnight_blue;
        background: #fff;
        padding: 0 20px;
      }
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      .hr_anchor {
        span {
          font-size: 22px;
        }
      }
    }

    .alinks_wrapper {
      position: fixed;
      right: 1%;
      top: 60px;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .alinks {
        display: flex;
        flex-direction: column;
        border: 1px solid variables.$black;
        background: rgba(variables.$white, 0.6);
        border-radius: 30px;
        padding: 8px 0;
        max-width: 100vh;
        overflow-y: auto;
        scrollbar-width: none;
        ::-webkit-scrollbar {
          display: none;
        }
        overflow: -moz-scrollbars-none;
        a,
        a:visited {
          flex: 1 1 auto;
          color: variables.$black;
          padding: 4px 15px;
          font-size: 17px;
          text-align: center;
          &:hover,
          &:active {
            color: variables.$dark_midnight_blue;
          }
        }
      }
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      .alinks_wrapper {
        right: 2%;
        top: 140px;
        .alinks {
          border: 2px solid variables.$black;
          a,
          a:visited {
            font-size: 18px;
          }
        }
      }
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      .alinks_wrapper {
        right: 4%;
        .alinks {
          a,
          a:visited {
            font-size: 20px;
          }
        }
      }
    }

    .assoc {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      li {
        height: 150px;
        flex: 0 0 100%;
        padding: 20px 0;
        h4, h2 {
          font-size: 19px;
          font-weight: variables.$medium;
        }
        p {
          font-size: 16px;
          margin: 10px 0;
        }
        a,
        a:visited {
          color: rgba(variables.$dark_midnight_blue, 0.8);
          &:hover {
            color: rgba(variables.$dark_midnight_blue, 1);
          }
        }
      }
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      .assoc {
        li {
          flex: 0 0 50%;
          padding: 20px 0;
        }
      }
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      .assoc {
        li {
          padding: 30px 0;
          h4, h2 {
            font-size: 24px;
          }
          p {
            font-size: 18px;
          }
        }
      }
    }
  }

  // ABOUT
  &.about {
    // logo header
    .logo_header {
      display: flex;
      justify-content: space-between;
      padding: 30px 20px;
      .ghin {
        display: flex;
        align-items: flex-end;
        background-position: right bottom;
        background-repeat: no-repeat;
        height: 40px;
      }
      .ghin {
        font-size: 24px;
        color: variables.$dark_midnight_blue;
        font-weight: variables.$light;
      }
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      .logo_header {
        padding: 30px 0;
        .ghin {
          background-size: auto 40px;
          padding-right: 125px;
          font-size: 28px;
        }
      }
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      .logo_header {
        .ghin {
          background-size: auto 40px;
          padding-right: 130px;
          font-size: 34px;
        }
      }
    }

    // section links
    .section_links {
      padding: 15px;
      background: variables.$white;
      border-bottom: 1px solid variables.$border_color;
      .tabs.light {
        margin: 0;
      }
    }

    h3 {
      color: variables.$dark_midnight_blue;
      margin-bottom: 20px;
    }
    p {
      line-height: 2em;
    }
    .bigstart::first-letter {
      font-size: 200%;
    }

    // socials
    .pos-rlt {
      position: relative;
      .socials {
        position: absolute;
        right: 10px;
        bottom: 10px;
        display: flex;
        justify-content: flex-end;
        a,
        button {
          width: 35px;
          height: 35px;
          margin-left: 10px;
          background-color: rgba(variables.$white, 0.3);
          border-radius: 50%;
          background-repeat: no-repeat;
          background-size: auto 16px;
          background-position: center center;
          border: none;
          cursor: pointer;
          text-indent: -99999px;
          &.share {
            background-image: url("../img/icons/ic_social_share.svg");
          }
          &.twitter {
            background-image: url("../img/icons/ic_social_twitter.svg");
            display: none;
          }
          &.facebook {
            background-image: url("../img/icons/ic_social_fb.svg");
            display: none;
          }
          &.linkedin {
            background-image: url("../img/icons/ic_social_linkedin.svg");
            display: none;
          }
        }
      }
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      .pos-rlt {
        .socials {
          right: 20px;
          bottom: 20px;
          a,
          button {
            &.share {
              display: none;
            }
            &.twitter,
            &.facebook,
            &.linkedin {
              display: block;
            }
          }
        }
      }
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      .pos-rlt {
        .socials {
          a,
          button {
            width: 40px;
            height: 40px;
            background-size: auto 17px;
          }
        }
      }
    }
  }

  // Create Profile
  &.create-profile-page {
    h4 {
      color: #4c7b93;
      margin-bottom: 20px;
      margin-top: -10px;
    }
  }
}
