//	BREAKPOINTS
$mobile: 23em; //	~= 375	iPhone 6/7/8
$large_mobile: 25em; //	~= 414	iPhone 6/7/8 Plus
$small_tablet: 48em; //	768			Tablet
$tablet: 64em; //	1024		Large Tablet OR Landscape Tablet
$small_desktop: 80em; //	1280		Notebooks
$desktop: 90em; //	1440		Large Notebooks	OR Desktops
$large_desktop: 120em; //	1920		Large Desktops

//  FIXED BREAKPOINTS
$mobile: 375px; //	~= 375	iPhone 6/7/8
$large_mobile: 414px; //	~= 414	iPhone 6/7/8 Plus
$small_tablet: 768px; //	768			Tablet
$tablet: 1024px; //	1024		Large Tablet OR Landscape Tablet
$small_desktop: 1280px; //	1280		Notebooks
$desktop: 1440px; //	1440		Large Notebooks	OR Desktops
$large_desktop: 1920px; //	1920		Large Desktops

//  COLORS
$white: #fff;
$black: #000;
$bg_gray: #eff0f2;
$border_color: #e5e5e5;
$light_gray: #f6f6f6;
$text_gray: #a3a5aa;
$lol_gray: #cacaca;
$mid_gray: #bbbbbb;
$web_gray: #ccc;
$dark_midnight_blue: #00365f;
$bright_blue: #0067e6;
$faded_blue: #305774;
$light_blue: #cce7f5;
$light_blue_2: #d6e1e8;
$cardinal: #cc233c;
$dark_cardinal: #aa1d32;
$text_muted: #73737d;
$so_many_grays: #e0e0e0;
$green: #20b123;
$light_blue_3: #e5ecf0;
$medium_blue: #7fa2b6;
$medium_blue_2: #6b8ea3;
$mid_gray_2: #d7d8d9;
$super_light_blue: #eff3f6;
$highlight: #ffffd6;
$highlight_red: rgba(218, 26, 49, 0.25);
$separator_color: #707070;

$legend_light_blue: #0088ce;
$legend_dark_blue: #00466e;
$legend_gray: #667c8e;
$legend_gray_dark: #4e515e;
$legend_light_red: #ca243e;
$legend_dark_red: #6d0020;

$table_even: #f2f3f5;
$table_odd: #f9fafa;

$is_active: $legend_light_blue;

//	FONT WEIGHTS
$light: 300;
$regular: 400;
$medium: 500;
$semi_bold: 600;
$bold: 700;
$extra_bold: 800;
