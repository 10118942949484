.delete-round-button {
  color: #174571!important;
  text-decoration: none!important;
  line-height: 16px!important;
}

.delete-round-modal {
  max-width: 516px;
  max-height: 570px;
  border-radius: 5px;
  height: auto;
  
  .modal_close {
    top: 8px!important;
    right: 10px!important;
    z-index: 1;
    color: #515151;
  }

  .icon {
    background: url("../img/icons/alert-icon-2.svg") no-repeat center;
    width: 24px;
    height: 24px;
    background-size: cover;
    margin-bottom: 4px;
  }

  .actions .btn {
    margin-bottom: 6px!important;
  }

  .lnk{
    padding: 0.75em 2.4em;
    font-weight: 600!important;
    margin-bottom: 8px;
  }
}

.delete-round-modal-header {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: #f2f2f6;
  border: none;
  width: 100%;
  padding: 10px 15px;
  text-align: left !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-style: italic;
  color: #515151;
}

.delete-round-modal-text-1 {
  font-weight: 600 !important;
  margin-bottom: 15px;
  font-size: 24px !important;
  padding: 0 15px;
}

.delete-round-modal-text-2 {
  font-size: 18px !important;
  padding: 0 15px;
  color: #475467;
  font-weight: 300 !important;
}

.delete-round-alert {
  background: #f5f0e2;
  border-radius: 50%;
  height: 56px;
  width: 56px;
  justify-content: center;
  display: inline-flex;
  align-items: center;
}

.delete-round-note{
  background: #f5f0e2;
  padding: 8px;
  font-size: 16px !important;
  color: #b0481a;
  border-radius: 8px;
  font-weight: 400!important;

  span{
    font-style: italic;
    font-weight: 600;
  }
}

.delete-round-modal button.btn {
  flex-basis: 290px !important;
}

@media screen and (min-width: 1280px) {
  .delete-round-modal .btn {
    font-size: 16px!important;
  }
}
