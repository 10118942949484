@use "../utils/mixins";
@use "../utils/variables";

//  POWERED BY
.powered-by__container {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;

    }

    &.margin0050 {
      @include mixins.breakpoint() {
        margin-bottom: 5px;
      }
    }
    
    .powered-by {

        @include mixins.breakpoint() {
    
            display: flex;
            flex-direction: row;
    
            flex: none;
            margin: 0 0 0 auto;

        }

        &.no-auto {

            @include mixins.breakpoint() {
                margin: 0 0 0 0;
            }

        }


        &.center-on-tablet {
            @include mixins.breakpoint(variables.$small_tablet) {
                margin: 0 auto;
            }
        }

        span {
    
            @include mixins.breakpoint() {
    
                color: variables.$black;
    
                font-size: 16px;
                line-height: 30px;
    
                margin: 0 4px 0 0;

                display: flex;
                flex-direction: row;
                align-items: center;

            }
    
        }
    
        .powered-by__logo {
    
            @include mixins.breakpoint() {
    
                width: 155px;
                height: 30px;
                
                object-fit: contain;
    
            }

            &.opacity05 {
              @include mixins.breakpoint() {
                opacity: 0.5;
              }
            }

            &.cisco {
                @include mixins.breakpoint() {
                    width: 90px;
                    height: 50px;
                    margin-left: 15px;
                }
            }

        }
    
    }

}