@use "../utils/mixins";
@use "../utils/variables";

label {
  @include mixins.breakpoint() {
    color: variables.$dark_midnight_blue;

    font-size: 14px;
    line-height: 1;
    font-weight: variables.$medium;

    margin: 0 0 10px 0;

    display: block;
  }

  &.white {
    @include mixins.breakpoint() {
      color: variables.$white;
    }
  }

  &.centered {
    @include mixins.breakpoint() {
      text-align: center;
    }
  }

  &.regular {
    @include mixins.breakpoint() {
      font-size: 16px;
      font-weight: variables.$regular;
    }
  }

  &.large {
    @include mixins.breakpoint() {
      font-size: 18px;
    }
  }

  &.black {
    @include mixins.breakpoint() {
      color: variables.$black;
    }
  }
}
