@use "../utils/mixins";
@use "../utils/variables";


/* general table */
@include mixins.breakpoint() {
  .tbl{
    display: flex;
    flex-direction: column;
  }
  .tbl-row {
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    flex-direction: row;
  }
  .tbl-cell {
    padding: 15px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .tbl--2cols .tbl-cell  { flex: 0 0 50%; }
  .tbl--3cols .tbl-cell  { flex: 0 0 33.33%; }
  .tbl--4cols .tbl-cell  { flex: 0 0 25%; }
  .tbl--5cols .tbl-cell  { flex: 0 0 20%; }
  .tbl--6cols .tbl-cell  { flex: 0 0 16.6%; }

  .tbl.phone__column{
    .tbl-row{
      flex-direction: column;
    }
    .tbl--2cols .tbl-cell,
    .tbl--3cols .tbl-cell,
    .tbl--4cols .tbl-cell,
    .tbl--5cols .tbl-cell,
    .tbl--6cols .tbl-cell {
      width: 100%;
    }
  }

}

@include mixins.breakpoint(variables.$small_tablet) {
  .tbl.phone__column{
    .tbl-row {
      flex-direction: row;
    }
    .tbl--2cols .tbl-cell  { flex: 0 0 50%; }
    .tbl--3cols .tbl-cell  { flex: 0 0 33.33%; }
    .tbl--4cols .tbl-cell  { flex: 0 0 25%; }
    .tbl--5cols .tbl-cell  { flex: 0 0 20%; }
    .tbl--6cols .tbl-cell  { flex: 0 0 16.6%; }
  }
}

/* general styles */
.tbl-cell{
  background-color: variables.$white;
  font-size: 16px;
  line-height: 16px;
  &.text-center{
    justify-content: center;
    text-align: center;
  }
  .subscript {
    font-size: 0.6em;
    vertical-align: sub;
  }
}
.tbl-row.active{
  .tbl-cell{
    background-color: variables.$light_gray;
  }
}

/* centered content */
.tbl{
  .tbl-cell.text-center{
    justify-content: center;
    text-align: center;
  }
  &.phone__column{
    .tbl-cell.text-center{
      justify-content: flex-start;
      text-align: left;
    }
  }
}
@include mixins.breakpoint(variables.$small_tablet) {
  .tbl,.tbl.phone__column{
    .tbl-cell{
      &.text-center{
        justify-content: center;
        text-align: center;
      }
    }
  }
}


.golfer__list {

  .tbl-cell{
    &.header{
      font-size: 14px;
      text-transform: uppercase;
      color: variables.$text_muted;
      font-weight: variables.$regular;
      font-style: normal;
    }
    .cap{
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      padding: 15px;
      background-color: variables.$light_gray;
      font-size: 14px;
      text-transform: uppercase;
      color: variables.$text_muted;
      font-weight: variables.$regular;
      font-style: normal;
      line-height: inherit;
      line-height: 16px;
    }

    &.score{
      color: variables.$dark_midnight_blue;
      font-weight: variables.$medium;
    }
    &.date{
      font-weight: variables.$light;
    }
    &.rating{
      font-weight: variables.$light;
    }
    &.diff{
      font-weight: variables.$light;
    }
    &.course{
      font-weight: variables.$medium;
    }
  }

  @include mixins.breakpoint(variables.$small_tablet) {
    .tbl--5cols:not(.keep-full-width) .tbl-cell  {
      flex: 0 0 20.9%;
      &.grow{
        flex-grow: 1;
      }
      &.small-cell {
        flex-basis: 4%;
      }
    }
    .tbl--6cols.has-grow .tbl-cell  {
      flex: 0 0 13.5%;
      &.grow{
        flex-grow: 1;
      }
      &.small-cell {
        flex-basis: 4%;
      }
    }
  }
  @include mixins.breakpoint(variables.$small_desktop) {
    .tbl--6cols.has-grow .tbl-cell  {
      flex: 0 0 13.5%;
      &.small-cell{
        flex-basis: 4%;
      }
    }
    .tbl--5cols.has-grow .tbl-cell  {
      flex: 0 0 20.9%;
      &.small-cell{
        flex-basis: 4%;
      }
    }
  }


  /* table header for each row*/
  @include mixins.breakpoint() {
    .tbl-cell {
      padding-left: 38%;
      .cap{
        width: 35%;
        display: flex;
        height: 100%;
      }
    }
    .tbl.small-cap{
      .tbl-cell{
        padding-left: 25%;
        .cap{
          width: 20%;
        }
      }
    }
    [data-cap]{
      &:before{
        content: attr(data-cap);
        margin-right: 8px;
      }
    }
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    .tbl-cell {
      padding-left: 15px;
      .cap{
        display: none;
      }
    }
    .tbl.small-cap{
      .tbl-cell {
        padding-left: 15px;
        .cap{
          display: none;
        }
      }
    }
    [data-cap]{
      &:before{
        content: '';
      }
    }
  }

  /* left action button for each row*/
  @include mixins.breakpoint() {
    .tbl-cell{
      .ic_c_minus{
        background-color: transparent;
        display: inline-block;
        position: absolute;
        top: 8px;
        right: 8px;
        width: 35px;
        height: 35px;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('../img/icons/ic_circle_minus.svg');
        background-size: cover;
        border: none;
        flex-grow: 0;
        flex-shrink: 0;
        &.hidden{
          background-image: none;
        }
      }
    }
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    .tbl-cell{
      .ic_c_minus{
        position: relative;
        top: -2px;
        left: 0;
        margin-right: 15px;
        vertical-align: middle;
      }
    }
  }
  @include mixins.breakpoint(variables.$desktop) {
    .tbl-cell{
      .ic_c_minus{
        width: 25px;
        height: 25px;
        margin-right: 18px;
      }
    }
  }

  @include mixins.breakpoint() {
    .hide-on-tablet{
      display: none;
    }
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    .hide-on-tablet{
      display: block;
    }
    .tbl-cell{
      font-size: 18px;
      line-height: 21px;
      &.score{
        background-color: variables.$light_gray;
        font-size: 21px;
        &.highlight {
          background-color: variables.$highlight;
        }
        &.nineHole {
          background-color: variables.$highlight_red;
        }
      }
    }
  }

  .table-legend__container {
    justify-content: flex-end;
    flex-shrink: 0;
    margin: 0 0 5px;
  }

  .table-legend__item {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .legend__item-color {
    width: 46px;
    height: 14px;
    border: 1px solid #fff;
    margin: 0 8px 0 0;
  }

  .faded-yellow {
    background-color: variables.$highlight;
  }

  .faded-red {
    background-color: variables.$highlight_red;
  }

  .legend__item-name {
    color: #999;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
}

  .section_tabs{
    background: variables.$white;
    padding: 20px 0;
    border-bottom: 1px solid #e5e5e5;
    .tabs{
      justify-content: center;
      li{
        border: 1px solid variables.$border_color;
        flex: 0 0 auto;
        a{
          padding: 2px 20px;
        }
      }
      li+li{
        margin-left: -1px;
      }
      margin-bottom: 0;
    }
  }

  @include mixins.breakpoint(variables.$small_tablet) {
    .section_tabs{
      .tabs{
        justify-content: flex-end;
      }
    }
  }


  h4{
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: variables.$dark_midnight_blue;
    background: variables.$white;
    text-transform: uppercase;
    padding: 20px 0;
    border-bottom: 1px solid #e5e5e5;
  }
  .rightView {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    @include mixins.breakpoint(variables.$small_tablet) {
      justify-content: flex-end;
      margin-top: -15px;
      margin-right: 20px;
    }
  }

}


.handicap__list{
  @include mixins.breakpoint() {
    .tbl-cell{
      font-size: 15px;
      font-weight: variables.$regular;
      padding: 20px 0;
      text-align: center;
      &.revision{
        background-color: variables.$light_gray;
      }
      &.handicap{
        font-size: 17px;
      }
      &.header{
        font-weight: variables.$bold;
        color: variables.$dark_midnight_blue;
      }
    }
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    .tbl-cell{
      font-size: 18px;
      padding: 25px 0;
      &.handicap{
        font-size: 22px;
      }
      &.header{
        font-size: 16px;
      }
    }
  }
}

.info-modal-trigger {
  color: #00365f;
  font-size: 12px;
  width: 18px;
  height: 18px;
  font-weight: 700;
  border-radius: 50%;
  border: 2px solid #00365f;
  background-color: initial;
  display: inline-block;
  padding: 0!important;
  cursor: pointer;
  &.margin-left {
    margin-left: 4px;
  }
  &.margin-right {
    margin-right: 4px;
  }
  &.white {
    color: #fff;
    border: 2px solid #fff;
  }
  &.mobile-only-table-pcc {
    @include mixins.breakpoint(variables.$small_tablet) {
      display: none;
    }
  }
}

//  OVERRIDES FOR INFO BTN
.tbl-cell {
  .btn.blank {
    @include mixins.breakpoint() {
      width: 16px;
      height: 16px;
      position: relative;
    }
    .material-icons-outlined {
      @include mixins.breakpoint() {
        top: -4px;
        left: -4px;
        position: absolute;
      }
    }
  }
}

//  OVERRIDES FOR SELECT
.tbl-cell {

  @include mixins.breakpoint() {

    min-height: 16px;
    box-sizing: content-box;

    display: flex;
    flex-direction: row;
    align-items: center;

  }

  .default_select {

    @include mixins.breakpoint() {
      width: 140px;
    }

    .ds__control {

      @include mixins.breakpoint() {

        border: none;

        height: 16px;
        min-height: 16px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

      }

      .ds__value-container {

        @include mixins.breakpoint() {

          padding: 0;

          height: 16px;
          line-height: 16px;

        }

        .ds__placeholder {

        }

        .ds__single-value {

          small {
            @include mixins.breakpoint() {
              display: none;
            }
          }

        }

      }

      .ds__indicators {

        @include mixins.breakpoint() {

          width: 16px;
          height: 16px;

        }

        .ds__indicator {

          @include mixins.breakpoint() {

            padding: 0;

            width: 16px;
            height: 16px;

            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: center center;

            background-image: url('../img/icons/ic_expand_more_24px.svg');

          }

          svg {
            @include mixins.breakpoint() {
              display: none;
            }
          }

        }

      }

    }

  }

}

//  FIX
// .tbl-row {
//   .tbl-cell {
//     @include breakpoint() {
  // box-sizing: border-box;
  // flex-grow: 1 !important;
//     }
//   }
// }

.golfer__list {

  //  HIDE ON TABLET HEADER
  .tbl.tbl--5cols.has-grow.keep-full-width,
  //  BODY
  .tbl.phone__column tbl--5cols has-grow keep-full-width {
    .tbl-row {
      .tbl-cell {
        box-sizing: border-box;
        flex-grow: 1 !important;
      }
    }
  }

  //  4 COLUMN FIX
  .tbl {
    &.tbl--4cols {
      .tbl-row {
        .tbl-cell {
          @include mixins.breakpoint(variables.$small_tablet) {
            flex-grow: 1;
            flex-shrink: 1;
          }
        }
      }
    }
  }

  //  BTN FIX
  .tbl {
    .tbl-cell {
      .trigger-container {
        @include mixins.breakpoint(variables.$small_tablet) {
          margin: 0 0 0 8px;
          flex: none;
          width: 22px;
          height: 22px;
        }
        .btn.blank {
          @include mixins.breakpoint(variables.$small_tablet) {
            width: 22px;
            height: 22px;
            padding: 0;
            margin: 0;
          }
          .material-icons-outlined {
            @include mixins.breakpoint(variables.$small_tablet) {
              position: static;
            }
          }
        }
      }
      &.pcc {
        @include mixins.breakpoint(variables.$small_tablet) {
          flex: none;
          width: 80px;
          flex-basis: auto !important;
        }
      }
    }
  }

  //  CHC FIX
  .chc_row_c {
    .tbl-row {
      .tbl-cell {
        @include mixins.breakpoint(variables.$small_tablet) {
          flex: none;
          width: 25%;
        }
        .default_select.smallwidth {
          @include mixins.breakpoint(variables.$small_tablet) {
            min-width: 100%;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .ds__control {
              width: 100%;
            }

          }
        }
        &.tees-select {
          @include mixins.breakpoint(variables.$small_tablet) {
            width: 30%;
          }
        }
        &.golfer-name-cell {
          @include mixins.breakpoint(variables.$small_tablet) {
            width: 20%;
          }
        }
      }
    }
  }

}

//  NEW HANDICAP CALCULATOR TABLE
//  FROM TABLET AND ABOVE
.golfer__list {
  .chc_row_c {
    &.tbl {
      &.tbl--handicap-calc {
        .tbl-row {
          &.tbl--handicap-calc {
            .tbl-cell {
              @include mixins.breakpoint(variables.$small_tablet) {

              }
              &.golfer-name-cell {
                @include mixins.breakpoint(variables.$small_tablet) {

                }
              }
              &.tees-select {
                @include mixins.breakpoint(variables.$small_tablet) {

                }
              }
              &.handicap-index {
                @include mixins.breakpoint(variables.$small_tablet) {

                }
              }
              &.course-handicap {
                @include mixins.breakpoint(variables.$small_tablet) {
                  width: 100px;
                }
              }
              &.playing-handicap {
                @include mixins.breakpoint(variables.$small_tablet) {
                  width: 100px;
                }
              }
              &.shots-off {
                @include mixins.breakpoint(variables.$small_tablet) {
                  width: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.golfer_list {
  .chc_row_c.tbl.tbl--handicap-calc,
  .chc_row_c.tbl.phone__column.small-cap {
    .tbl-row.tbl--handicap-calc {
      .tbl-cell {
        &.golfer-name-cell {
          @include mixins.breakpoint(variables.$small_tablet) {
            width: 100px;
          }
          @include mixins.breakpoint(variables.$small_desktop) {
            width: auto;
            flex-grow: 1;
          }
        }
        &.tees-select {
          @include mixins.breakpoint(variables.$small_tablet) {
            width: 70px;
          }
          @include mixins.breakpoint(variables.$small_desktop) {
            width: 160px;
          }
        }
        &.handicap-index {
          @include mixins.breakpoint(variables.$small_tablet) {
            width: 100px;
          }
          @include mixins.breakpoint(variables.$small_desktop) {
            width: 120px;
          }
        }
        &.course-handicap {
          @include mixins.breakpoint(variables.$small_tablet) {
            width: 90px;
          }
          @include mixins.breakpoint(variables.$small_desktop) {
            width: 120px;
          }
        }
        &.playing-handicap {
          @include mixins.breakpoint(variables.$small_tablet) {
            width: 90px;
          }
          @include mixins.breakpoint(variables.$small_desktop) {
            width: 100px;
          }
        }
        &.shots-off {
          @include mixins.breakpoint(variables.$small_tablet) {
            width: 100px;
          }
          @include mixins.breakpoint(variables.$small_desktop) {
            width: 100px;
          }
        }
      }
    }
  }
}