@use "../utils/mixins";
@use "../utils/variables";

.gir-chart {
  .gir_chart-title {
    top: 0;
    width: 100%;
    left: 50%;
    @include mixins.transform(translate3d(-50%, 0, 0));
    line-height: 25px;
    text-align: center;
    padding: 5px 0 5px 0;
    position: relative;
    @include mixins.breakpoint() {
      font-size: 18px;
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      font-size: 20px;
    }
  }
  .gir-chart__body {
    width: 100%;
    height: 160px;
    position: relative;
    .gir-chart-label {
      font-size: 12px;
      line-height: 16px;
      display: block;
      position: absolute;
      @include mixins.breakpoint(variables.$large_mobile) {
        font-size: 14px;
      }
      @include mixins.breakpoint(variables.$small_tablet) {
        font-size: 12px;
      }
      @include mixins.breakpoint(variables.$small_desktop) {
        font-size: 16px;
      }
      &.north {
        top: 0;
        left: 50%;
        @include mixins.transform(translate3d(-50%, 0, 0));
      }
      &.east {
        right: 8px;
        top: calc(50% - 20px);
        @include mixins.breakpoint(variables.$small_tablet) {
          right: 0;
        }
      }
      &.south {
        bottom: 0;
        left: 50%;
        @include mixins.transform(translate3d(-50%, 0, 0));
      }
      &.west {
        left: 8px;
        top: calc(50% - 20px);
        @include mixins.breakpoint(variables.$small_tablet) {
          left: 0;
        }
      }
    }
    .gir-chart-circle {
      width: 70px;
      height: 70px;

      border-radius: 50%;
      background-color: variables.$dark_midnight_blue;
      position: absolute;
      left: 50%;
      top: 50%;
      @include mixins.transform(translate3d(-50%, -50%, 0));
      z-index: 5;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        color: variables.$white;
      }
      strong {
        font-size: 24px;
        color: variables.$white;
        font-weight: variables.$bold;
        sup {
          font-size: 12px;
          vertical-align: super;
        }
      }
    }
    .gir-chart-line {
      display: block;
      background-color: variables.$cardinal;
      position: absolute;
      &::before,
      &::after {
        content: "";
        display: block;

        width: 0;
        height: 0;

        border-style: solid;
        border-width: 0 4px 4px 4px;
        border-color: transparent transparent variables.$cardinal transparent;

        position: absolute;
      }
      &.horizontal {
        width: 100%;
        height: 2px;
        top: 50%;
        left: 0;
        margin: -1px 0 0 0;
        &::before {
          left: -4px;
          top: -1px;
          @include mixins.transform(rotate(-90deg));
        }
        &::after {
          right: -4px;
          top: -1px;
          @include mixins.transform(rotate(90deg));
        }
      }
      &.vertical {
        height: calc(100% - 40px);
        width: 2px;
        top: 20px;
        left: 50%;
        margin: 0 0 0 -1px;
        &::before {
          top: -4px;
          left: -3px;
        }
        &::after {
          bottom: -4px;
          left: -3px;
          @include mixins.transform(rotate(180deg));
        }
      }
    }
  }
  .gir-chart__footer {
    padding-block: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    button {
      margin-left: 8px;
    }
  }
}
