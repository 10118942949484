@use "../utils/mixins";
@use "../utils/variables";

.posted-score {

    @include mixins.breakpoint() {
        padding: 35px;
    }

    .posted-score__title {

        @include mixins.breakpoint() {

            font-family: 'National Book',sans-serif;

            color: variables.$dark_midnight_blue;

            font-size: 24px;
            line-height: 1;

            margin: 0 0 10px 0;

        }
        @include mixins.breakpoint(variables.$small_tablet) {

            text-align: center;

        }
        @include mixins.breakpoint(variables.$small_desktop) {

            font-size: 35px;
            margin: 0 0 35px;

        }

    }

}

.score-advanced-stats-status {
  background: #eff0f2;
  color: #00365f;
  text-align: center;
  padding: 30px 0px 0px 0px;
  font-size: 18px;
}

.share-round_summary__container {
  .share-round_summary__row {
    @include mixins.breakpoint()  {
      margin: 10px -8px;
    }
  }
  .share-round_summary__button {
    @include mixins.breakpoint()  {
      width: 380px;
    }
  }
}
