@use "../utils/mixins";
@use "../utils/variables";

.contact-buttons-container {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;

  &.in-modal {
    .contact-buttons-item {
      @include mixins.breakpoint() {
        margin: 30px 0;
      }
      .btn {
        &.fill {
          &.blue {
            &.sixty {
              @include mixins.breakpoint() {
                width: 100%;
              }
              @include mixins.breakpoint(variables.$small_tablet) {
                width: 65%;
              }
            }
          }
        }
      }
    }
  }

}

.contact-buttons-item {
  margin: 30px;
  text-align: center;

  .btn {
    &.fill {
      &.blue {
        &.sixty {
          @include mixins.breakpoint() {
            width: 100%;
            padding: 0.75em 12px;
          }
          @include mixins.breakpoint(variables.$small_tablet) {
            width: 60%;
          }
        }
      }
    }
  }

}

.contact-buttons-item {
  .btn {
    &.fill {
      &.blue {
        &.sixty {
          @include mixins.breakpoint() {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}