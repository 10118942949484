@use "../utils/variables";

.select-golfer {
  padding-top: 35px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .bottom_action {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .golfer-item {
    flex-direction: column;
    align-self: flex-center;
    max-width: 450px;
    min-width: 250px;
    align-items: center;
    justify-content: center;
    &.top {
      margin-top: -10px;
    }
  }

  .golfer-item-box {
    display: flex;
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    align-items: center;

    .name-wrapper {
      margin-left: 20px;
      .name {
        font-size: 18px;
        font-family: "National", sans-serif;
        text-align: left;
        padding-bottom: 8px;
        font-weight: variables.$medium;
      }
      .number {
        font-size: 16px;
        font-family: "National", sans-serif;
      }
    }
    .icon {
      cursor: pointer;
      border: none;
      width: 26px;
      height: 26px;
      background: url("../img/icons/radio_unchecked.svg") no-repeat center;
      &.checked {
        background: url("../img/icons/radio_checked.svg") no-repeat center;
      }
      input[type="radio"] {
        position: absolute;
        margin: 0;
        width: 1px;
        height: 1px;
      }

      &:focus-within {
        border: 3px solid red;
        border-radius: 100%;
      }
    }
  }
}

.separator-label {
  font-size: 16px;
  font-family: "National", sans-serif;
  color: variables.$dark_midnight_blue;
  font-weight: variables.$medium;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-top: solid 1px variables.$border_color;
  border-bottom: solid 1px variables.$border_color;
  width: 100%;
  padding-right: 2em;
}
