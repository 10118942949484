@use "../utils/mixins";
@use "../utils/variables";

#handicap_calculator {
  padding: 10px 0;

  .promo_banner {
    display: none;
    margin-bottom: 30px;
    img {
      width: 100%;
    }
  }
  @include mixins.breakpoint(variables.$tablet) {
    display: block;
  }

  h1 {
    color: variables.$dark_midnight_blue;
    font-size: 24px;
    font-weight: variables.$light;
    line-height: 1.2em;
    margin: 25px 0 15px 0;
    padding-left: 15px;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    h1 {
      font-size: 28px;
    }
  }
  @include mixins.breakpoint(variables.$small_desktop) {
    h1 {
      font-size: 35px;
      padding-left: 0;
    }
  }

  //  HEADERS
  .header_club_selected {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    background-color: variables.$white;
    margin-bottom: 20px;
    h4 {
      color: variables.$dark_midnight_blue;
      font-size: 20px;
      font-weight: variables.$regular;
      text-transform: none;
      border-bottom: none;
      text-align: left;
      padding: 0 30px 0 0;
      max-width: 350px;
    }
    > a {
      color: variables.$bright_blue;
    }
    .tabs.auto {
      width: 100%;
      margin: 20px auto 0 auto;
    }
  }
  @include mixins.breakpoint(variables.$tablet) {
    .header_club_selected {
      .tabs.auto {
        width: auto;
        margin: 0 0 0 auto;
      }
    }
  }

  .section_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0 15px 0;
    align-items: flex-start;
    h1 {
      margin: 0 0 15px 0;
    }
    .tabs {
      width: 100%;
      li {
        flex: 1 1 auto;
        a {
          font-size: 15px;
          text-transform: none;
          line-height: 1em;
          white-space: nowrap;
          padding: 0.8em 1em;
          @include mixins.breakpoint(variables.$small_tablet) {
            padding: 0.8em 2em;
          }
        }
      }
    }
    &.filters {
      padding: 35px 25px;
      border-bottom: solid 1px variables.$border_color;
    }
    &.patched {
      @include mixins.breakpoint() {
        padding: 0 20px;
        margin: 0 0 15px 0;
      }
    }
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    .section_header {
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 0;
      align-items: flex-end;
      h1 {
        margin: 0;
      }
      .tabs {
        width: auto;
      }
    }
  }
  @include mixins.breakpoint(variables.$small_desktop) {
    .section_header {
      .tabs {
        li {
          a {
            font-size: 16px;
          }
        }
      }
    }
  }

  //  FOOTER
  .row-bottom-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
  }

  .alert {
    padding: 10px;
    font-size: 16px;
    text-align: center;
    &.alert-primary {
      color: variables.$white;
      background-color: variables.$dark_midnight_blue;
    }
  }
  @include mixins.breakpoint(variables.$tablet) {
    .alert {
      font-size: 18px;
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    padding: 40px 20px;
    background-color: variables.$white;
    .btn.fill {
      width: 100%;
    }
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    .btn-wrapper {
      .btn.fill {
        width: auto;
      }
    }
  }
}
@include mixins.breakpoint(variables.$small_tablet) {
  #handicap_calculator {
    padding: 20px 0 30px;
  }
}

.manually-form {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  padding: 10px 20px;
  align-items: flex-end;

  .full-width {
    width: -webkit-fill-available;
  }

  .margin-top {
    margin-top: 20px;
  }

  .error {
    display: flex;
    color: #eb0000;
    margin-top: 10px;
  }

  .center {
    text-align: center;
  }

  @include mixins.breakpoint(variables.$small_tablet) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 20px;

    .full-width {
      width: auto;
    }

    .margin-top {
      margin-left: 10px;
      margin-right: 10px;
    }

    .center {
      text-align: center;
    }
  }

  .ic_c_plus {
    display: inline-block;
    width: 70px;
    height: 40px;
    min-width: 40px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 40px 40px;
    background-image: url("../img/icons/ic_circle_plus.svg");
    &.active {
      background-image: url("../img/icons/ic_circle_plus_filled.svg");
    }
  }

  .ic_c_minus {
    display: inline-block;
    width: 70px;
    height: 40px;
    min-width: 40px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 40px 40px;
    background-image: url("../img/icons/ic_circle_minus.svg");
  }

  .btn-ic {
    border: none;
    background-color: transparent;
    margin: 0px 15px 5px 5px;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    .ic_c_plus {
      width: 60px;
      background-size: 35px 35px;
    }
    .ic_c_minus {
      width: 60px;
      background-size: 35px 35px;
    }
  }
  @include mixins.breakpoint(variables.$desktop) {
    .ic_c_plus {
      width: 60px;
      background-size: 25px 25px;
    }
    .ic_c_minus {
      width: 60px;
      background-size: 25px 25px;
    }
  }
}

.infoNote {
  padding: 20px;
  color: #757575;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.25px;

  .note {
    font-weight: variables.$bold;
  }
}

.header_club_selected {
  .handicap-allowance__container {
    width: 100%;
    margin: 20px 0 0 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include mixins.breakpoint(variables.$small_desktop) {
      justify-content: flex-end;
    }

    label {
      padding: 15px;
      font-size: 14px;
      text-transform: uppercase;

      color: variables.$text_muted;
      font-weight: 400;
      font-style: normal;
      line-height: inherit;
      line-height: 16px;

      margin: 0;

      white-space: nowrap;
    }

    .btn {
      margin: 0 15px 0 0;
    }

    .default_select {
      width: 120px;
    }
  }
}

//  UPDATE
.manually-form {
  &.hand-form {
    @include mixins.breakpoint(variables.$small_tablet) {
      align-items: stretch;
    }
    .col {
      width: 100%;
      @include mixins.breakpoint(variables.$small_tablet) {
        width: auto;
      }
      //  ERROR
      .error {
        &:empty {
          display: none;
        }
      }
      //  LABEL
      label {
        @include mixins.breakpoint(variables.$small_tablet) {
          min-height: 32px;
          max-height: 32px;

          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
        &.type-alpha {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          @include mixins.breakpoint(variables.$small_tablet) {
            justify-content: flex-start;
          }
          .material-icons-outlined {
            height: 19px;
            margin: 0 0 0 2px;
          }
        }
      }
      //  SELECT UPDATE
      .default_select {
        max-height: 42px;
        height: 42px;
        @include mixins.breakpoint(variables.$small_tablet) {
          min-width: 165px;
          max-width: 165px;
        }
        .ds__control {
          max-height: 42px;
          height: 42px;
          .ds__value-container {
            .ds__single-value {
              font-size: 24px;
            }
          }
        }
        .ds__menu {
          @include mixins.breakpoint(variables.$small_tablet) {
            min-width: 165px;
            max-width: 165px;
          }
        }
      }
    }
  }
}

.manually-form {
  &.add-glf-frm {
    @include mixins.breakpoint(variables.$small_tablet) {
      align-items: stretch;
    }
    .col {
      width: 100%;
      @include mixins.breakpoint(variables.$small_tablet) {
        flex: 1 1 auto;
      }
      &.w-40 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        @include mixins.breakpoint(variables.$small_tablet) {
          flex: none;
          width: 40px;
        }
      }
      .error {
        &:empty {
          display: none;
        }
      }
      .default_select {
        height: 42px;
        max-height: 42px;
        .ds__control {
          height: 42px;
          max-height: 42px;
          .ds__value-container {
            .ds__single-value {
              font-size: 23px;
            }
          }
        }
        .ds__menu {
          @include mixins.breakpoint(variables.$small_tablet) {
            min-width: 165px;
            max-width: 165px;
          }
        }
      }
      .btn-ic {
        width: 40px;
        padding: 0;

        cursor: pointer;

        @include mixins.breakpoint(variables.$small_tablet) {
          margin: 24px 0 0 0;
        }

        &.ic_c_plus {
        }
      }
    }
  }
}
