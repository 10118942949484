@use "../utils/mixins";
@use "../utils/variables";

//  REGULAR
input[type="checkbox"] {
  &.regular {
    @include mixins.breakpoint() {
      display: none;
    }

    & + label {
      .link {
        text-decoration: underline;
        color: variables.$black;
        display: contents;
      }
      @include mixins.breakpoint() {
        font-size: 15px;
        line-height: 23px;

        display: flex;
        flex-direction: row;

        margin: 0;

        cursor: pointer;

        position: relative;
      }

      &::before {
        @include mixins.breakpoint() {
          content: "";
          display: block;

          width: 18px;
          height: 18px;

          margin: 0 8px 0 0;

          background-color: variables.$white;

          border: solid 1px #818181;

          cursor: pointer;
        }
      }
    }

    &:checked {
      & + label {
        &::after {
          @include mixins.breakpoint() {
            content: "";
            display: block;

            position: absolute;

            width: 4px;
            height: 10px;

            border-bottom: solid 4px variables.$dark_midnight_blue;
            border-right: solid 4px variables.$dark_midnight_blue;

            @include mixins.transform(rotate(45deg));

            top: 2px;
            left: 6px;
          }
        }
      }
    }
  }

  &.focusable {
    @include mixins.breakpoint() {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: 0;
    }

    & + label {
      @include mixins.breakpoint() {
        font-size: 15px;
        line-height: 23px;

        display: flex;
        flex-direction: row;

        margin: 0;

        cursor: pointer;

        position: relative;
      }

      &::before {
        @include mixins.breakpoint() {
          content: "";
          display: block;

          width: 18px;
          height: 18px;

          margin: 0 8px 0 0;

          background-color: variables.$white;

          border: solid 1px #818181;

          cursor: pointer;
        }
      }
    }

    &:checked {
      & + label {
        &::after {
          @include mixins.breakpoint() {
            content: "";
            display: block;

            position: absolute;

            width: 4px;
            height: 10px;

            border-bottom: solid 4px variables.$dark_midnight_blue;
            border-right: solid 4px variables.$dark_midnight_blue;

            @include mixins.transform(rotate(45deg));

            top: 2px;
            left: 6px;
          }
        }
      }
    }

    &:focus-visible + label {
      outline: 3px solid red;
    }
  }
}

//  SWITCH
.switch-container {
  @include mixins.breakpoint() {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  input[type="checkbox"] {
    @include mixins.breakpoint() {
      display: none;
    }

    & + label {
      @include mixins.breakpoint() {
        color: variables.$black;

        font-size: 14px;
        line-height: 30px;

        margin: 0;

        display: flex;
        flex-direction: row;
        align-items: center;

        cursor: pointer;

        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      }

      .switch {
        @include mixins.breakpoint() {
          width: 52px;
          height: 26px;

          border-radius: 13px;

          background-color: variables.$mid_gray;

          display: flex;
          flex-direction: row;
          align-items: center;

          will-change: background-color;

          @include mixins.transition(all 300ms ease-in-out 0ms);

          margin: 0 0 0 10px;
        }

        &::before {
          @include mixins.breakpoint() {
            content: "";
            display: block;

            width: 16px;
            height: 16px;

            background-color: variables.$white;

            border-radius: 50%;

            margin: 0 5px;

            will-change: transform;

            @include mixins.transition(all 300ms ease-in-out 0ms);

            pointer-events: none;
          }
        }

        //  STATUS VISIBLE ON TABLETS AND ABOVE
        & + .switch-status {
          &::before {
            @include mixins.breakpoint() {
              display: none;
            }
            @include mixins.breakpoint(variables.$small_tablet) {
              content: "OFF";
              display: block;

              color: variables.$black;

              font-size: 16px;
              line-height: 20px;

              margin: 0 0 0 8px;
            }
          }
          &.visible {
            width: 33px;
            &::before {
              @include mixins.breakpoint() {
                content: "OFF";
                display: block;

                color: variables.$black;

                font-size: 16px;
                line-height: 20px;

                margin: 0 0 0 8px;
              }
            }
          }
        }
      }
    }

    &:checked {
      & + label {
        .switch {
          @include mixins.breakpoint() {
            background-color: variables.$dark_midnight_blue;
          }

          &::before {
            @include mixins.breakpoint() {
              @include mixins.transform(translate3d(26px, 0, 0));
            }
          }

          //  STATUS VISIBLE ON TABLETS AND ABOVE
          & + .switch-status {
            &::before {
              @include mixins.breakpoint(variables.$small_tablet) {
                content: "ON";
                color: variables.$dark_midnight_blue;
              }
            }
            &.visible {
              &::before {
                @include mixins.breakpoint() {
                  content: "ON";
                  color: variables.$dark_midnight_blue;
                }
              }
            }
          }
        }
      }
    }
  }
}
