@use "sass:math";
@use "mixins";

.row {
  @include mixins.breakpoint() {
    display: flex;
    flex-direction: row;

    margin: 0 -8px 20px -8px;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &:only-of-type {
    @include mixins.breakpoint() {
      margin: 20px -8px;
    }
  }

  &.no-margin {
    @include mixins.breakpoint() {
      margin: 0;
    }
  }

  .col {
    @include mixins.breakpoint() {
      padding: 0 8px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      flex: 1 1 0;
    }

    &.is-full {
      @include mixins.breakpoint() {
        flex: none;
        width: 100%;
      }
    }

    &.push-right {
      @include mixins.breakpoint() {
        margin-left: auto;
      }
    }

    &.h-centered {
      @include mixins.breakpoint() {
        margin: 0 auto;
      }
    }

    &.push-left {
      @include mixins.breakpoint() {
        margin-right: auto;
      }
    }

    &.auto {
      @include mixins.breakpoint() {
        flex: 0 0 0;
      }
    }

    &.align-center {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    @for $i from 1 through 16 {
      @for $j from 1 through 16 {
        @if ($i > $j) or
          ($i == $j) or
          ((math.round(calc($j/$i)) == calc($j/$i)) and not ($j > $i))
        {
          //  DO NOTHING
        } @else {
          &.is-#{$i}-of-#{$j} {
            @include mixins.breakpoint() {
              flex: none;
              width: math.percentage(calc($i/$j));
            }
          }
        }
      }
    }
  }
}

.columns {
  @include mixins.breakpoint() {
    display: flex;
    flex-direction: column;
  }
}
