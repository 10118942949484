@use "../utils/mixins";
@use "../utils/variables";

.selected {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: column;

        padding: 0 0 16px 0;
        margin: 0 0 0 0;

        border-bottom: solid 1px variables.$border_color;

    }

    .selected__item {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            justify-content: space-between;

        }

        .selected__details {

            @include mixins.breakpoint() {
    
                color: variables.$dark_midnight_blue;
    
                font-size: 17px;
                line-height: 20px;
    
                display: flex;
                flex-direction: column;
    
            }
    
        }
    
        .selected__controls {
    
            @include mixins.breakpoint() {
    
                display: flex;
                flex-direction: row;
                align-items: center;
    
            }
    
            a {
    
                @include mixins.breakpoint() {
    
                    color: variables.$bright_blue;
    
                    font-size: 14px;
                    line-height: 20px;
    
                    display: block;
    
                }
    
            }
    
        }

    }

}