@use "../utils/mixins";
@use "../utils/variables";

.form {

    @include mixins.breakpoint() {
        padding: 20px 25px;
    }
    @include mixins.breakpoint(variables.$small_tablet) {

        padding: 40px 0;
        width: 400px;

        margin: 0 auto;

    }
    @include mixins.breakpoint(variables.$small_desktop) {

        width: 460px;

    }

}