@use "../utils/mixins";

.gir-donut {

  .gir-donut__container {

    @include mixins.breakpoint() {

      position: relative;

    }

    .gir-donut-mid {

      @include mixins.breakpoint() {

        width: 70px;
        height: 70px;

        position: absolute;

        left: 10px;
        top: 10px;

        border-radius: 50%;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

      }

      .gir-donut-mid__percentage {

        @include mixins.breakpoint() {

          font-family: 'National Book',sans-serif;

          font-size: 20px;
          line-height: 1;

          position: relative;

        }

        sup {

          @include mixins.breakpoint() {

            font-size: 16px;

            vertical-align: super;

          }

        }

      }

    }

  }

}