@use "../utils/mixins";
@use "../utils/variables";

.custom-chart {

    @include mixins.breakpoint() {

        position: relative;

        margin: 0 -20px;
        padding: 0 20px;

    }

    .cusutom-chart__container {

        @include mixins.breakpoint() {
    
            overflow: visible;
    
            position: relative;
    
            margin: 0 -20px;
    
            border-left: solid 20px transparent;
            border-right: solid 20px transparent;
    
        }
        @include mixins.breakpoint(variables.$small_tablet) {
            border-left-width: 75px;
            border-right-width: 75px;
        }
    
        .custom-chart__timeline {
    
            @include mixins.breakpoint() {
    
                height: 240px;
                margin: 20px 0 30px 0;
    
                display: flex;
                flex-direction: row;
                align-items: flex-end;
    
                position: relative;
    
            }
            @include mixins.breakpoint(variables.$tablet) {
                padding: 0 8px;
            }
    
            .custom-chart__bar {
    
                @include mixins.breakpoint() {
    
                    background-color: variables.$dark_midnight_blue;
    
                    width: 40px;
                    max-width: 70px;
    
                    flex: 1;
    
                    margin: 0 11px;
    
                    position: relative;
    
                }
                @include mixins.breakpoint(variables.$small_tablet) {
                    margin: 0 15px;
                }
                @include mixins.breakpoint(variables.$small_desktop) {
                    width: 50px;
                }
    
                &.is-hidden {
                    @include mixins.breakpoint() {
                        display: none;
                    }
                }

                .custom-chart__bar__value {
    
                    @include mixins.breakpoint() {
    
                        color: variables.$black;
    
                        font-display: 14px;
                        line-height: 20px;
    
    
                        position: absolute;
                        top: -25px;
                        left: 50%;
    
                        @include mixins.transform(translate3d(-50%,0,0));
    
                    }
    
                }
    
                .custom-chart__bar__date {
    
                    @include mixins.breakpoint() {
    
                        color: variables.$black;
    
                        font-size: 11px;
                        line-height: 20px;
    
                        position: absolute;
                        bottom: -30px;
                        left: 50%;
    
                        @include mixins.transform(translate3d(-50%,0,0));
    
                    }
    
                }
                .hover_tooltip{
                    visibility: hidden;
                    position: absolute;
                    width: 240px;
                    height: 107px;
                    top: -117px;
                    left: -moz-calc(50% - 125px);
                    left: -webkit-calc(50% - 125px);
                    left: -o-calc(50% - 125px);
                    left: calc(50% - 125px);
                    border: 1px solid #ccc;
                    background: variables.$white;
                    font-size: 14px;
                    line-height: 15px;
                    padding: 15px;
                    text-align: center;
                    z-index: 100;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    p{
                        font-weight: variables.$bold;
                        margin-bottom: 0;                        
                        display: inline-block;
                        max-width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .score_info{
                        margin-top: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: rgba(0,0,0,0.65);
                        span{
                            font-weight: bold;
                            color: rgba(0,0,0,1);
                        }

                        .subscript {
                            font-size: 0.8em;
                            vertical-align: sub;
                          }

                        &.trial {
                            margin-top: 10px;
                            justify-content: center;
                        }
                    }

                    &.trial {
                        width: 150px;
                        left: calc(50% - 76px);
                    }
                    &:after{
                        //  BAD IMPLEMENTATION
                        content: '';
                        display: none;
                        position: absolute;
                        bottom: -7px;
                        left: 50%;
                        width: 11px;
                        height: 11px;
                        background: #FFFFFF;
                        border-right: 1px solid #CCC;
                        border-bottom: 1px solid #CCC;
                        -moz-transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                    }
                }
                &:hover{
                    background: variables.$legend_light_blue;
                    .hover_tooltip{
                        visibility: visible;
                    }
                }

              //  CUSTOM CHART BAR HOVER
              &:hover {
                //DISABLED BY https://github.com/golfgenius/ghin/issues/326
                &::after {
                    @include mixins.breakpoint() {

                        content: '';
                        display: block;

                        position: absolute;
                        z-index: 101;

                        top: -18px;
                        left: 50%;

                        margin: 0 0 0 -6px;

                        width: 12px;
                        height: 12px;

                        background-color: variables.$white;

                        border: solid 1px variables.$web_gray;

                        border-left-color: variables.$white;
                        border-top-color: variables.$white;

                        @include mixins.transform(rotate(45deg));

                    }
                }
              }
              
              &.no-hover {
                background-color: variables.$dark_midnight_blue;

                &::after {
                  @include mixins.breakpoint() {
                    display: none;
                  }
                }
              }

                //  MODIFIERS FOR HOVER TOOLTIP

                //  FIRST CHILD ON MOBILE SHOULD HAVE TOOLTIP RIGHT ALIGNED
                &:nth-child(1),
                &:nth-child(2) {

                    .hover_tooltip {
                        @include mixins.breakpoint() {
                            left: -20px;
                        }
                        @include mixins.breakpoint(variables.$small_tablet) {
                            left: calc(50% - 125px);
                        }
                    }

                }

                //  LAST CHILD ON MOBILE SHOULD HAVE TOOLTIP LEFT ALIGNED
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6) {

                    .hover_tooltip {
                        @include mixins.breakpoint() {
                            left: auto;
                            right: -20px;
                        }
                        @include mixins.breakpoint(variables.$small_tablet) {
                            left: calc(50% - 125px);
                        }
                    }

                }
                &:nth-child(4) {

                    .hover_tooltip {
                        @include mixins.breakpoint() {
                            left: calc(50% - 125px);
                            right: unset;
                        }
                        @include mixins.breakpoint(variables.$small_tablet) {
                            left: calc(50% - 125px);
                        }
                    }

                }

            }
    
            .custom-chart_h {
    
                @include mixins.breakpoint() {
    
                    border-bottom: solid 1px variables.$border_color;
    
                    width: 100%;
    
                    display: block;
    
                    position: absolute;
    
                    bottom: 0;
    
                }
    
            }    
    
        }
    
    }

    .custom-chart__control {
    
        @include mixins.breakpoint() {

            width: 24px;
            height: 40px;

            display: block;

            color: variables.$dark_midnight_blue;

            font-size: 24px;
            line-height: 40px;

            text-align: center;

            border: none;
            background-color: transparent;

            position: absolute;
            top: 50%;

            padding: 0;
            margin: -20px 0 0 0;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            cursor: pointer;

        }
        @include mixins.breakpoint(variables.$small_tablet) {

            width: 40px;
            height: 40px;

        }

        .material-icons-outlined {
            @include mixins.breakpoint(variables.$small_tablet) {
                font-size: 32px;
            }
        }

        &.prev {

            @include mixins.breakpoint() {
                left: 6px;
            }
            @include mixins.breakpoint(variables.$small_tablet) {
                left: 24px;
            }

        }

        &.next {

            @include mixins.breakpoint() {
                right: 6px;
            }
            @include mixins.breakpoint(variables.$small_tablet) {
                right: 24px;
            }

        }

    }

}
