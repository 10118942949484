@use "../utils/mixins";

//  LOADER SETUP
.loader {
  @include mixins.breakpoint() {
    width: 120px;
    height: 40px;

    display: inline-block;

    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: initial;

    background-image: url("../img/icons/loader.svg");
  }
}

.loader-small {
  @include mixins.breakpoint() {
    width: 100px;
    height: 20px;

    display: inline-block;

    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: initial;

    background-image: url("../img/icons/loader-small.svg");
  }
}

.loader-small-reverse {
  @include mixins.breakpoint() {
    width: 100px;
    height: 20px;

    display: inline-block;

    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: initial;

    background-image: url("../img/icons/loader-small-reverse.svg");
  }
}
