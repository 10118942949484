@use "../utils/mixins";
@use "../utils/variables";

.stats__container {
  &.margin00200 {
    @include mixins.breakpoint() {
      margin-bottom: 20px;
    }
  }

  .separator {
    @include mixins.breakpoint() {
      height: 1px;
      margin-bottom: 25px;
      background-color: #e5e5e5;
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      border-right: 1px solid #e5e5e5;
      height: 130px;
    }
  }

  .stats__row {
    @include mixins.breakpoint() {
      display: flex;
      flex-direction: column;
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      flex-direction: row;
    }
    .stats__column {
      @include mixins.breakpoint() {
        margin: 0 0 25px 0;
      }

      @include mixins.breakpoint(variables.$small_tablet) {
        flex: 1 1 0;
      }

      &:first-of-type {
        @include mixins.breakpoint(variables.$small_tablet) {
          margin: 0 10px 0 0;
        }
      }

      &:last-of-type {
        @include mixins.breakpoint(variables.$small_tablet) {
          margin: 0 0 0 10px;
        }
      }

      &.combined-score {
        @include mixins.breakpoint() {
          align-self: flex-start;
        }
        @include mixins.breakpoint(variables.$small_tablet) {
          align-self: center;
        }
      }

      .combined-score-info {
        color: #999999;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.25px;
        text-align: left;
        margin-left: 25px;
        margin-top: 5px;
        .title {
          font-size: 19px;
          font-weight: bold;
        }
      }
    }
  }
}
