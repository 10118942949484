@use "../utils/mixins";
@use "../utils/variables";

// FIXED TOP HEADER
.s-navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.25s ease-out;
  transform: translateY(0);
  &.is-scrolled-down {
    transition-delay: 0.15s;
    transform: translateY(-100%);
  }
  &.is-scrolled-up {
    transform: translateY(0);
  }
}
.s-navigation-placeholder {
  height: 60px;
  @include mixins.breakpoint(variables.$small_tablet) {
    height: 110px;
  }
  @include mixins.breakpoint(variables.$small_desktop) {
    height: 130px;
  }
  &.smaller-height {
    height: 60px;
    @include mixins.breakpoint(variables.$small_tablet) {
      height: 70px;
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      height: 90px;
    }
  }
}

header {
  @include mixins.breakpoint() {
    background-color: variables.$white;
    height: 60px;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    height: 72px;
  }
  @include mixins.breakpoint(variables.$small_desktop) {
    height: 90px;
  }

  .header__container {
    @include mixins.breakpoint() {
      height: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      margin: 0 auto;
    }

    .header-sentry-logo {
      display: block;

      height: 50px;
      width: auto;

      margin: 0 0 0 auto;

      @include mixins.breakpoint(variables.$small_tablet) {
        margin: 0 0 0 32px;
      }
    }

    .hide-link {
      position: absolute;
      left: 47%;
      top: 0;
      color: variables.$dark_midnight_blue;
      font-weight: 600;
      transform: scale(0);

      &:focus {
        display: flex;
        align-items: center;
        padding: 5px;
        transform: scale(1);
      }
    }
  }
}

.main__alert {
  @include mixins.breakpoint() {
    cursor: pointer;
    width: 100%;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    padding: 8px 16px;
    display: flex;
    background: #cc0000;
    justify-content: center;
    align-items: center;
    font-family: National;
    font-weight: bold;
    color: #fff;
    font-size: 16px;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    padding: 8px 56px;
  }
  .icon {
    @include mixins.breakpoint() {
      background: url("../img/icons/alert-icon.svg") no-repeat center;
      width: 40px;
      height: 30px;
    }
  }
  span {
    @include mixins.breakpoint() {
      padding: 4px 16px 0px;
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      margin-left: 16px;
    }
  }
}
.hide_alert {
  @include mixins.breakpoint() {
    cursor: pointer;
    border: none;
    width: 16px;
    height: 16px;
    background: url("../img/icons/alert-close.svg") no-repeat center;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    position: absolute;
    right: 32px;
    top: 16px;
  }
}

@include mixins.breakpoint() {
  .smartbanner-show {
    .smartbanner {
      display: block !important;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999999;
    }
    .s-navigation {
      top: 80px !important;
    }
    .s-navigation-placeholder {
      height: 140px;
      margin-top: 0 !important;
    }
  }
}
@include mixins.breakpoint(variables.$small_tablet) {
  .smartbanner-show {
    .smartbanner {
      display: block !important;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }
    .s-navigation {
      top: 80px !important;
    }
    .s-navigation-placeholder {
      height: 180px !important;
    }
  }
}

//page correction
.smartbanner-show {
  .page_container.ghin_associations .alinks_wrapper {
    top: 150px;
  }
}
