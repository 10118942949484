@use "../utils/mixins";
@use "../utils/variables";
@use "sass:color";

.modal {

  .modal__overlay {

    @include mixins.breakpoint() {

      position: fixed;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      z-index: 99;

      background-color: rgba(variables.$black, 0.3);

      @include mixins.animation(fade_in 600ms both);

      overflow-y: auto;

      display: flex;
      flex-direction: row;

    }

  }

  .modal__content {

    @include mixins.breakpoint() {

      width: auto;

      -webkit-overflow-scrolling: touch;

      outline: none;

      position: relative;

      pointer-events: none;

      margin: auto;

      //  MAGIC TOP / BOTTOM SPACING
      border-top: solid 32px transparent;
      border-right: solid 16px transparent;
      border-bottom: solid 32px transparent;
      border-left: solid 16px transparent;

    }

    .modal__container {

      @include mixins.breakpoint() {

        pointer-events: initial;

        @include mixins.box-shadow(0 5px 25px 0 rgba(variables.$black, 0.5));

        background-color: variables.$white;

        padding: 40px 35px;

        position: relative;

      }
      @include mixins.breakpoint(variables.$small_tablet) {
        padding: 70px 100px;
      }

      &.regular {

        @include mixins.breakpoint() {
          width: calc(100vw - 40px);
        }
        @include mixins.breakpoint(variables.$small_tablet) {
          width: 600px;
        }
        @include mixins.breakpoint(variables.$tablet) {
          width: 770px;
        }
        @include mixins.breakpoint(variables.$small_desktop) {
          width: 800px;
        }

      }

      &.narrow {
      }

      &.wide {
      }

      .modal__close {

        @include mixins.breakpoint() {

          color: variables.$dark_midnight_blue;

          background-color: transparent;
          border: none;

          width: 32px;
          height: 32px;

          line-height: 32px;
          text-align: center;

          position: absolute;
          top: 10px;
          right: 10px;

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          padding: 0;
          margin: 0;

        }
        @include mixins.breakpoint(variables.$small_tablet) {

          top: 20px;
          right: 20px;

        }

        &:hover {
        }

        .material-icons-outlined {
          @include mixins.breakpoint() {
            font-size: 32px;
          }
        }

      }

      .modal__body {

        h2 {

          @include mixins.breakpoint() {

            color: variables.$dark_midnight_blue;

            font-size: 20px;
            line-height: 1.25;
            font-weight: variables.$medium;

            text-align: center;

            margin: 0 0 20px 0;

          }

        }

        p {

          @include mixins.breakpoint() {

            font-size: 18px;
            line-height: 1.5;

          }

        }

        h3 {

          @include mixins.breakpoint() {

            color: variables.$dark_midnight_blue;

            font-size: 18px;
            line-height: 1.25;

            margin: 0 0 10px 0;

          }

          &.uppercase {
            @include mixins.breakpoint() {
              text-transform: uppercase;
            }
          }

        }

        ul {

          &.blank {

            @include mixins.breakpoint() {
              list-style: none;
            }

            li {

              @include mixins.breakpoint() {

                color: variables.$black;

                font-size: 18px;
                line-height: 1;

                margin: 0 0 10px 0;

              }

              &:last-child {
                @include mixins.breakpoint() {
                  margin: 0;
                }
              }

            }

          }

        }

      }

    }

  }

}


.custom__modal {
  .modal__overlay {

    @include mixins.breakpoint() {

      position: fixed;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      z-index: 9999;

      background-color: rgba(variables.$black, 0.5);

      @include mixins.animation(fade_in 600ms both);

      overflow-y: auto;

      display: flex;
      flex-direction: row;

    }

  }

  .modal__content {
    @include mixins.breakpoint() {

      width: auto;

      -webkit-overflow-scrolling: touch;

      outline: none;

      position: relative;

      pointer-events: none;

      margin: auto;

      //  MAGIC TOP / BOTTOM SPACING
      border-top: solid 64px transparent;
      border-bottom: solid 64px transparent;

    }

    .modal__container {

      @include mixins.breakpoint() {

        pointer-events: initial;

        @include mixins.box-shadow(0 5px 25px 0 rgba(variables.$black, 0.5));

      }

      &.very-narrow {
        width: calc(100% - 32px);
        margin: 16px;
        @include mixins.breakpoint(variables.$small_tablet) {
          width: 520px;
        }
      }

      .modal__head {

        @include mixins.breakpoint() {

          height: 64px;

          padding: 0 16px;

          display: flex;
          flex-direction: row;
          align-items: center;

          background-color: #CC0000;

          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          .modal__icon {
            margin: 0 16px 0 0;
            background: url("../img/icons/alert-icon.svg") no-repeat center;
            width: 30px;
            height: 30px;
          }

          .modal__title {

            @include mixins.breakpoint() {

              color: variables.$white;

              font-size: 24px;
              line-height: 32px;
              font-weight: variables.$medium;

              margin: 0;

            }

            &.long {
              padding: 16px 0px;
            }

          }

        }
      }

      .modal__body {
        padding: 48px;
        background-color: #fff;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        @include mixins.breakpoint(variables.$small_tablet) {
          
        }
        p {
          color: #333333;
          text-align: justify;
          font-size: 16px;
          line-height: 1.2;
        }

        strong {
          font-weight: bold;
        }

        .btn {

          width: auto;
          height: 40px;

          border: none;

          font-size: 16px;
          line-height: 40px;
          font-weight: variables.$medium;
          font-family: 'National',sans-serif;

          cursor: pointer;

          border-radius: 4px;

          text-align: center;
          text-transform: capitalize;
          text-decoration: none!important;

          padding: 0 16px;

          @include mixins.user-select(none);

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          white-space: nowrap;
          
          @include mixins.breakpoint() {

            color: variables.$white;
            background-color: #CC0000;

          }

          &:hover {

            @include mixins.breakpoint() {

              background-color: color.adjust(#CC0000, $lightness: -5%);

            }

          }

        }
      }
    }
  }


}
