@use "../utils/mixins";
@use "../utils/variables";

.choose {
  display: flex;
  flex-direction: column;
  .choose_body {
    display: flex;
    flex-direction: column;

    .choose-option {
      border: solid 1px variables.$border_color;
      padding: 16px;

      display: flex;
      flex-direction: column;

      cursor: pointer;
      border-radius: 8px;

      margin: 0 0 16px 0;

      background-color: variables.$white;

      transition: all 300ms ease-in-out 0ms;

      @include mixins.user-select(none);

      &:last-child {
        margin: 0;
      }

      &:hover {
        background-color: variables.$light_gray;
      }

      strong {
        font-size: 19px;
        line-height: 1;
        font-weight: variables.$bold;

        margin: 0 0 8px 0;
      }
      span {
        margin: 0 0 8px 0;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .choose_footer {
    border-top: solid 1px variables.$border_color;
    margin: 16px 0 0 0;
    padding: 16px 0 0 0;
  }
}
