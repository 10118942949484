@use "../utils/mixins";
@use "../utils/variables";

.hide-on-tablet-and-above {
    @include mixins.breakpoint(variables.$small_tablet) {
        display: none;
    }
}
.hide-on-phone {
    @include mixins.breakpoint(){
        display: none;
    }
    @include mixins.breakpoint(variables.$small_tablet){
        display: block;
    }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.blurred{
    background-color: transparent;
    filter: blur(0.7px);
    -webkit-filter: blur(0.7px);
    -moz-filter: blur(0.7px);
    -o-filter: blur(0.7px);
    -ms-filter: blur(0.7px);
}

.blackWhite {
    opacity: 0.5;
    filter: grayscale(100%);
    -webkit-filter: grayscale(0.7px);
}