@use "../utils/mixins";
@use "../utils/variables";

.item-list {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: column;

    }

    .item-list__head {

        @include mixins.breakpoint() {
            
            display: flex;
            flex-direction: row;

        }

        .item-list__title {
            
            @include mixins.breakpoint() {

                color: variables.$black;

                font-size: 14px;
                line-height: 1;

                width: 100%;
                display: block;

                padding: 20px 25px;

                text-transform: uppercase;

                border-bottom: solid 1px variables.$border_color;

            }

        }

    }

    .item-list__actions {

        @include mixins.breakpoint() {

            padding: 20px 25px;
            border-bottom: solid 1px variables.$border_color;

        }

    }

    .item-list__action {

      @include mixins.breakpoint() {
        align-self: center;
        margin-top: 15px;
      }
  
      @include mixins.breakpoint(variables.$small_tablet) {
        align-self: flex-end;
        margin-top: -38px;
        margin-right: 20px;
        margin-bottom: 18px;
      }
    }
    
    &.courses {

        .item-list__body {

            @include mixins.breakpoint() {

                display: flex;
                flex-direction: column;

            }

            .item {

                @include mixins.breakpoint() {

                    display: flex;
                    flex-direction: column;

                    padding: 20px 25px;

                    border-bottom: solid 1px variables.$border_color;

                    background-color: variables.$white;

                    @include mixins.transition(all 300ms ease-in-out 0ms);

                }
                @include mixins.breakpoint(variables.$small_tablet) {
                    padding: 30px 40px;
                }

                .item__details {

                    @include mixins.breakpoint(variables.$small_tablet) {

                        position: relative;

                    }

                    .item__name {

                        @include mixins.breakpoint() {

                            color: variables.$dark_midnight_blue;

                            font-size: 17px;
                            line-height: 20px;
                            font-weight: variables.$regular;

                        }
                        @include mixins.breakpoint(variables.$small_tablet) {

                            font-size: 20px;
                            line-height: 24px;

                        }

                    }

                    .item__extras {

                        @include mixins.breakpoint() {

                            color: variables.$dark_midnight_blue;

                            font-size: 17px;
                            line-height: 20px;
                            font-weight: variables.$regular;

                        }
                        @include mixins.breakpoint(variables.$small_tablet) {

                            font-size: 20px;
                            line-height: 24px;

                            position: absolute;

                            top: 50%;
                            right: 0;

                            @include mixins.transform(translate3d(0,-50%,0));

                        }

                    }

                    .item__location {

                        @include mixins.breakpoint() {

                            color: variables.$black;

                            font-size: 14px;
                            line-height: 20px;
                            font-weight: variables.$regular;

                        }
                        @include mixins.breakpoint(variables.$small_tablet) {

                        }

                    }

                }

                &:hover,
                &:focus {
                    @include mixins.breakpoint() {
                        background-color: variables.$light_gray;
                    }
                }

            }

        }

    }

}

.overflow-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}