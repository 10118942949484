@use "../utils/mixins";
@use "../utils/variables";

.registration_container {
  background: #fff; /* White background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 0px; /* Space inside the box 0*/
  max-width: 200px; /* Maximum width of the form 200*/
  margin: 50px auto; /* Centering the form 10*/
}

.registration_form {
  padding: 20px;
  margin-top: 0px;

  input[type="email"]:focus-visible,
  input[type="text"]:focus-visible,
  input[type="password"]:focus-visible {
    border: 3px solid red;
  }
}

.trial_form {
  padding: 40px 20px;
  @include mixins.breakpoint(variables.$small_tablet) {
    padding: 60px 40px 30px 40px;
  }
}


.lnk{
  margin-left: auto;
  margin-right: auto;
}

.registration_form h2 {
  color: #333; /* Dark text for the title */
  margin-bottom: 10px; /* Space below the title */
}

.registration_form p {
  color: #666; /* Lighter text for the subtitle */
  margin-bottom: 20px; /* Space below the subtitle */
}

.registration_form label {
  display: block; /* Ensure labels are on their own line */
  margin-bottom: 7px; /* Space below each label */
}

.registration_form input[type="text"],
.registration_form input[type="password"],
.registration_form input[type="radio"] {
  width: 100%; /* Full width fields */
  padding: 10px; /* Padding inside the fields */
  margin-bottom: 15px; /* Space below the fields */
  border: 1px solid #ccc; /* Border around the fields */
  border-radius: 4px; /* Rounded corners on the fields */
}

.registration_form .row {
  margin-bottom: 10px; /* Space between rows */
}

.registration_form .error {
  color: red; /* Error message color */
  font-size: 0.8rem; /* Smaller font size for error messages */
  display: inline-block;
  height: 0px;
  width: 100%;
}

.registration_form .btn {
  width: 100%; /* Full width buttons */
  padding: 10px; /* Padding inside the buttons */
  margin-top: 10px; /* Space above the buttons */
  border: none; /* No border for buttons */
  border-radius: 40px; /* Rounded corners for buttons */
  background-color: #CE0E2D; /* Red background for primary button */
  color: variables.$dark_midnight_blue; /* White text for buttons */
  cursor: pointer; /* Pointer cursor on hover */
}

.registration_form .btn:hover {
  background-color: #c0392b; /* Darker red on hover */
  color: white;
}

.registration_form .link {
  text-align: center; /* Center link text */
  display: block; /* Ensure links are on their own line */
  margin-top: 15px; /* Space above the link */
  color: #3498db; /* Blue color for links */
  text-decoration: none; /* No underline on links */
}

.registration_form .link:hover {
  text-decoration: underline; /* Underline on hover */
}

.gender-inputs .gender-option-male {
  display: inline-block;
  margin-right: 0px; /* Spacing between options */
  position: relative;
  padding: 10px 17px;
  border: 1px solid #ccc; /* Border color */
  border-radius: 4px 0px 0px 4px; /* Rounded corners for the border */
  cursor: pointer;
}

.gender-inputs .gender-option-female {
  display: inline-block;
  margin-right: 0px; /* Spacing between options */
  position: relative;
  padding: 10px 10px;
  border: 1px solid #ccc; /* Border color */
  border-radius: 0 4px 4px 0; /* Rounded corners for the border */
  cursor: pointer;
}

.gender-inputs .gender-radio {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.gender-checked {
  background-color: variables.$cardinal;
  color: white;
}

.input-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include mixins.breakpoint(variables.$large_mobile) {
    flex-direction: row;
  }
}

.col.is-half {
  // flex: 1; /* Makes the input take up half of the container */
  padding-right: 8px; /* Adjust the spacing between inputs */

  @include mixins.breakpoint(variables.$large_mobile) {
    padding-right: 50px;
  }
}

.gender-inputs {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  @include mixins.breakpoint(variables.$large_mobile) {
    margin-top: 0px;
  }
}

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns children to the start of the cross axis */
  justify-content: center;
  margin: 0 auto; /* Centers the container */
  width: fit-content; /* Shrink-wraps the content */
}

.step-container {
  display: flex;
  align-items: center;
  margin-bottom: 0px; /* Space between steps */
}

.step-number {
  color: #CE0E2D;
  font-size: 78px;
  font-weight: 300;
  font-family: "National", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px; /* Space between number and text */
}

.step-subtext{
  color:#333;
}

.step-text {
  color:#043767;
  display: flex;
  margin-bottom: 5px;
  font-size: 20px;
  flex-direction: column;
}

.step-text h3, .step-text p {
  margin: 0;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.checkboxLabelContainer {
  display: flex;
  align-items: flex-start;
}

.checkboxLabel {
  margin-left: 0px;
}

.overlay {
  position: relative;
  border-radius: 8px; /* Rounded corners */
  max-width: 350px; /* Adjust as necessary */
  margin: auto;
  margin-top: 0px;
  margin-bottom: 30px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.973);
  text-align: center;
  padding: 20px;
  @include mixins.breakpoint(variables.$small_tablet) {
    max-width: 500px;
    margin-top: 60px;
    margin-bottom: 0px;
    right: 30px;
  }
}

.overlay img {
  max-width: 100%;
  height: auto;
}

.overlay-title {
  font-size: 18px;
  color: #043767;
  margin: 16px 0;
  @include mixins.breakpoint(variables.$tablet) {
    font-size: 20px;
  }
}

.overlay-image-cover {
  height: 70px;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include mixins.breakpoint(variables.$small_tablet) {
    height: 120px;
  }
}

.overlay-subtext {
  font-size: 12px;
  margin-bottom: 16px;
  padding: 15px 15px;
  @include mixins.breakpoint(variables.$tablet) {
    font-size: 16px;
  }
}

.overlay button {
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  border-radius: 15px 15px 15px 15px;
  letter-spacing: 0.1px;
  cursor: pointer;
  @include mixins.breakpoint(variables.$small_tablet) {
    font-size: 15px;
  }
}

.thanks-button {
  margin-top: 70px;
  margin-bottom: 30px;
}