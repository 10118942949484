@use "../utils/mixins";
@use "../utils/variables";

.static-umbrella {
  @include mixins.breakpoint() {
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @include mixins.breakpoint(variables.$small_desktop) {
    padding: 0 0 10px 0;
    margin: 10px 20px 0 20px;
  }

  .static-umbrella__container {
    @include mixins.breakpoint() {
      width: 100%;
      height: 80px;

      padding: 40px 0 0 0;

      position: relative;
      box-sizing: content-box;

      overflow: hidden;
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      height: 110px;
      padding: 30px 0 0 0;
    }

    .umbrella {
      @include mixins.breakpoint() {
        width: 720px;
        height: 720px;

        border-radius: 50%;

        transform-origin: center center;

        border-style: solid;
        border-width: 200px;

        position: absolute;

        top: 40px;
        left: 50%;

        margin: 0 0 0 -360px;

        @include mixins.box-shadow(0 0 0 5px variables.$white);
      }
      @include mixins.breakpoint(variables.$small_desktop) {
        top: 30px;
      }

      &.layer-one {
        @include mixins.breakpoint() {
          @include mixins.transform(rotate(180deg));
        }
      }

      &.layer-two {
        @include mixins.breakpoint() {
          @include mixins.transform(rotate(300deg));
        }
      }

      &.layer-three {
        @include mixins.breakpoint() {
          @include mixins.transform(rotate(-30deg));
        }
      }

      &.red {
        @include mixins.breakpoint() {
          border-style: solid;

          border-right-color: variables.$cardinal;
          border-bottom-color: variables.$cardinal;
          border-left-color: transparent;
          border-top-color: transparent;
        }
      }

      &.blue {
        @include mixins.breakpoint() {
          border-style: solid;

          border-right-color: variables.$dark_midnight_blue;
          border-bottom-color: variables.$dark_midnight_blue;
          border-left-color: transparent;
          border-top-color: transparent;
        }
      }
    }

    .umbrella__separator {
      @include mixins.breakpoint() {
        width: 4px;
        height: 720px;

        background-color: variables.$white;

        display: block;

        transform-origin: center center;

        position: absolute;

        left: calc(50% - 2px);

        z-index: 9;
      }

      &.one {
        @include mixins.breakpoint() {
          @include mixins.transform(rotate(-15deg));
        }
      }

      &.two {
        @include mixins.breakpoint() {
          @include mixins.transform(rotate(15deg));
        }
      }
    }

    //  LABELS
    .static-umbrella__labels {
      @include mixins.breakpoint() {
        height: 100%;

        position: relative;
      }

      .static-umbrella__label {
        @include mixins.breakpoint() {
          position: absolute;

          width: 80px;
        }
        @include mixins.breakpoint(variables.$small_desktop) {
          width: 120px;
        }

        .label__title {
          @include mixins.breakpoint() {
            white-space: nowrap;
          }
        }

        .material-icons-outlined {
          @include mixins.breakpoint(variables.$small_desktop) {
            font-size: 32px;
          }
        }

        .label__percentage {
          @include mixins.breakpoint() {
            color: variables.$white;

            font-size: 48px;
            line-height: 1;

            position: relative;
          }

          sup {
            @include mixins.breakpoint() {
              font-size: 18px;

              vertical-align: super;

              position: absolute;
            }
          }
        }

        &.left {
          @include mixins.breakpoint() {
            display: flex;
            flex-direction: column;

            position: absolute;
            left: 0;

            height: 100%;
          }

          .label__percentage {
            @include mixins.breakpoint() {
              font-size: 32px;
              text-align: center;

              position: absolute;
              bottom: 5px;

              width: 100%;
            }
            @include mixins.breakpoint(variables.$small_desktop) {
              font-size: 40px;
              bottom: 10px;
            }
          }

          .label__title {
            @include mixins.breakpoint() {
              font-size: 16px;
              line-height: 20px;

              display: block;

              position: absolute;
              left: 0;
              top: -40px;
            }
            @include mixins.breakpoint(variables.$small_desktop) {
              font-size: 18px;
              top: -30px;
            }
          }

          .material-icons-outlined {
            @include mixins.breakpoint() {
              position: absolute;
              top: 0;
              left: 0;

              opacity: 0.5;
            }
            @include mixins.breakpoint(variables.$small_desktop) {
              top: 10px;
            }
          }
        }

        &.mid {
          @include mixins.breakpoint() {
            left: 50%;
            @include mixins.transform(translate3d(-50%, 0, 0));

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            position: absolute;
            bottom: 0;
          }
          @include mixins.breakpoint(variables.$small_desktop) {
            bottom: 10px;
          }

          .label__title {
            @include mixins.breakpoint() {
              color: variables.$white;

              display: block;

              font-size: 18px;
              line-height: 20px;
            }
          }
        }

        &.right {
          @include mixins.breakpoint() {
            display: flex;
            flex-direction: column;

            position: absolute;
            right: 0;

            height: 100%;
          }

          .label__percentage {
            @include mixins.breakpoint() {
              font-size: 32px;
              text-align: center;

              position: absolute;
              bottom: 5px;

              width: 100%;
            }
            @include mixins.breakpoint(variables.$small_desktop) {
              font-size: 40px;
              bottom: 10px;
            }
          }

          .label__title {
            @include mixins.breakpoint() {
              font-size: 16px;
              line-height: 20px;

              display: block;

              position: absolute;
              right: 0;
              top: -40px;
            }
            @include mixins.breakpoint(variables.$small_desktop) {
              font-size: 18px;
              top: -30px;
            }
          }

          .material-icons-outlined {
            @include mixins.breakpoint() {
              position: absolute;
              top: 0;
              right: 0;

              opacity: 0.5;
            }
            @include mixins.breakpoint(variables.$small_desktop) {
              top: 10px;
            }
          }
        }
      }
    }
  }

  .static-umbrella__title {
    @include mixins.breakpoint() {
      color: variables.$black;

      font-size: 18px;
      line-height: 1;

      text-align: center;

      margin: 0 0 5px 0;
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      font-size: 20px;
    }
  }

  .static-umbrella__footer {
    @include mixins.breakpoint() {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      padding: 10px 0 0 0;
    }

    .footer-item {
      @include mixins.breakpoint() {
        font-size: 16px;
        white-space: nowrap;
      }
      @include mixins.breakpoint(variables.$small_desktop) {
        font-size: 18px;
      }
    }
  }
}
