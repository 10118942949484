@use "mixins";
@use "variables";

.row {
  &.manual-handicap__row {
    padding: 0 20px;
    margin: 0 0 20px 0;

    flex-direction: column;
    @include mixins.breakpoint(variables.$small_tablet) {
      flex-direction: row;
      margin: 0 -8px 20px -8px;
    }
    .col {
      &:first-of-type {
        margin: 0 0 20px 0;
      }
    }
  }
}
