@use "../utils/variables";

.auth__container {
  max-width: 450px;
  min-width: 350px;
  .center {
    text-align: center;
    align-self: center;
    justify-content: center;
    align-items: center;
  }

  .bottom-actions {
    display: flex;
    flex-direction: row;
    margin-top: 2.5em;
    margin-bottom: 2em;
    justify-content: center;
  }

  .bottom-link {
    font-size: 16px;
    line-height: 1.2em;
    font-family: "National", sans-serif;
    text-align: center;
    a {
      text-decoration: underline;
      color: variables.$black;
    }
  }

  .success-icon {
    height: 50px;
    background: url("../img/icons/check_icon.svg") no-repeat center;
    margin-top: 2em;
  }

  .success-title {
    color: variables.$dark_midnight_blue;
    text-align: center;
    font-size: 1.4em;
    margin-top: 1.5em;
    margin-bottom: 2.5em;
  }
}
