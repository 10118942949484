@use "../utils/mixins";
@use "../utils/variables";

.putting {
  font-size: 15px;
  @include mixins.breakpoint() {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .putting_chart-title {
    top: 0;
    width: 100%;
    left: 50%;
    @include mixins.transform(translate3d(-50%, 0, 0));
    line-height: 25px;
    text-align: center;
    padding: 5px 0 0 0;
    position: relative;
    @include mixins.breakpoint() {
      font-size: 18px;
    }
    @include  mixins.breakpoint(variables.$small_desktop) {
      font-size: 20px;
    }
  }

  //  PUTTING
  .putting__container {
    @include mixins.breakpoint() {
      position: relative;
    }

    //  RECHARTS
    .recharts-wrapper {
      svg {
        .recharts-pie {
          .recharts-pie-labels {
            .putting__label {
              @include mixins.breakpoint() {
                vertical-align: top;
              }

              .label-value {
                @include mixins.breakpoint() {
                  font-size: 1.3em;
                  line-height: 25px;
                }
              }

              .label-percent {
                @include mixins.breakpoint() {
                  font-size: 1em;
                  line-height: 25px;
                  font-weight: variables.$light;
                }
              }
            }
          }
        }
      }
    }

    //  PUTTING MID
    .putting-mid {
      @include mixins.breakpoint() {
        width: 90px;
        height: 90px;
        display: block;
        border-radius: 50%;
        background-color: variables.$white;
        position: absolute;
        top: 55px;
        left: 55px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .putting-mid__percentage {
        @include mixins.breakpoint() {
          color: variables.$black;
          font-family: 'National Book', sans-serif;
          font-size: 2.5em;
          line-height: 40px;
          letter-spacing: -6px;
          text-align: center;
          position: relative;
          margin: 0;
        }
        sup {
          @include mixins.breakpoint() {
            color: variables.$black;
            font-size: 15px;
            line-height: 20px;
            font-weight: variables.$bold;
            position: absolute;
            top: 0;
            right: -10px;
          }
        }
      }

      .putting-mid__label {
        @include mixins.breakpoint() {
          text-transform: uppercase;
          font-size: 15px;
        }
      }
    }
  }

  //  PUTTING LEGEND
  .putting-legend {
    @include mixins.breakpoint() {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      flex-wrap: wrap;

      padding: 5px 0;
    }

    li {
      @include mixins.breakpoint() {
        flex: none;

        color: variables.$black;

        font-size: 16px;
        line-height: 24px;

        padding: 0 0 0 10px;

        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .putting-legend__color {
        @include mixins.breakpoint() {
          display: block;

          width: 8px;
          height: 8px;

          margin: 0 2px 0 0;

          border-radius: 2px;
        }

        &.light-blue {
          @include mixins.breakpoint() {
            background-color: variables.$legend_light_blue;
          }
        }

        &.dark-blue {
          @include mixins.breakpoint() {
            background-color: variables.$legend_dark_blue;
          }
        }

        &.light-red {
          @include mixins.breakpoint() {
            background-color: variables.$legend_light_red;
          }
        }
      }
    }
  }

  //  PUTTING STATS
  .putting-stats {
    @include mixins.breakpoint() {
      display: flex;
      flex-direction: row;
    }

    .putting-stat__item {
      @include mixins.breakpoint() {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 0 20px;

        border-right: solid 1px variables.$border_color;
      }

      &:last-child {
        @include mixins.breakpoint() {
          border-right: none;
        }
      }

      .putting-stat__label {
        @include mixins.breakpoint() {
          color: variables.$black;

          font-size: 16px;
          line-height: 20px;
        }
      }

      .putting-stat {
        @include mixins.breakpoint() {
          color: variables.$legend_dark_blue;

          font-size: 32px;
          line-height: 1;
          font-weight: variables.$light;
        }
      }
    }
  }

  &.small {
    .recharts-wrapper {
      svg {
        .recharts-pie {
          .recharts-pie-labels {
            .putting__label {
              .label-value {
                @include mixins.breakpoint() {
                  font-size: 1.7em;
                }
              }
            }
          }
        }
      }
    }
    .putting-mid {
      @include mixins.breakpoint() {
        width: 100px;
        height: 100px;
        top: 60px;
        left: 60px;
      }
      .putting-mid__percentage {
        @include mixins.breakpoint() {
          font-size: 2.9em;
          line-height: 40px;
        }
        sup {
          @include mixins.breakpoint() {
            font-size: 15px;
            line-height: 10px;
            top: 0;
            right: -10px;
          }
        }
      }
      .putting-mid__label {
        @include mixins.breakpoint() {
          font-size: 15px;
        }
      }
    }
  }
}
