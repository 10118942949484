@use "../utils/mixins";
@use "../utils/variables";

.menu__links__wrapper {
    overflow: auto;
    height: 90vh;
    padding-bottom: 60px;
}

.menu__links {

    @include mixins.breakpoint() {
        padding: 0 0 20px 0;
    }
    @include mixins.breakpoint(variables.$small_tablet) {

        display: flex;
        flex-direction: column;

    }

    &:not(:first-child) {
        @include mixins.breakpoint() {
            padding: 20px 0 0 0;
            border-top: solid 1px variables.$bg_gray;
        }
    }

    li {

        @include mixins.breakpoint() {
            padding: 5px 20px 5px 60px;
        }
        @include mixins.breakpoint(variables.$small_desktop) {
            padding: 5px 20px 5px 65px;
        }
        @include mixins.breakpoint(variables.$desktop) {
            padding: 5px 20px 5px 60px;
        }

        .menu__link {

            @include mixins.breakpoint() {
    
                color: variables.$dark_midnight_blue;

                font-size: 16px;
                line-height: 40px;
                font-weight: variables.$medium;
                letter-spacing: 0.5px;

                display: block;

                text-decoration: none;

                -webkit-tap-highlight-color: transparent;
                transition: all .25s ease;

                &.is-active, &:hover {
                    color: variables.$cardinal;
                    span{
                        display: inline-block;
                        position: relative;
                        &::after{
                            content:'';
                            position:absolute;
                            width: 100%;
                            height: 0;    
                            left:0;
                            bottom: 6px;                   
                            border-bottom: 2px solid variables.$cardinal;
                        }
                    }
                }
            }
    
        }

        //  REORDER FOR TABLET
        @for $i from 1 through 16 {
            &.order-#{$i} {
                @include mixins.breakpoint(variables.$small_tablet) {
                    order: $i;
                }
            }
        }

    }

    //  MODS
    &.compact {

        li {

            @include mixins.breakpoint() {
                padding: 5px 40px;
            }

        }

    }

}