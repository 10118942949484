@use "../utils/mixins";
@use "../utils/variables";

select {

    @include mixins.breakpoint() {

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        display: block;

        width: 100%;
        height: 35px;

        border: solid 1px variables.$border_color;
        background-color: variables.$white;

        padding: 0 10px;

        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: calc(100% - 10px) center;
        
        background-image: url('../img/icons/ic_expand_more_24px.svg');
        background-size: 24px 24px;

    }

    option {

        @include mixins.breakpoint() {

            border: none;

        }

    }

}

.tees_select > .ds__menu > .ds__menu-list > .ds__option--is-disabled {
  background-color: #eff0f2 !important;
}

// select on right side
.right-side{
    position: relative;
    left: -10px;
    top: -10px;
    margin-bottom: -10px;
    flex: 0 1 200px;
    height: 30px;
    border-color: transparent;
    > .rs__control{
        background: transparent;
        border-width: 0;
        border-color: transparent;
        box-shadow: none;
        > .rs__indicators {
            > .rs__indicator-separator {
                display: none;
            }
            .rs__indicator{
                padding-left: 0;
                color: hsl(0,0%,50%);
            }
        }
        div[class$="-Input"] {
            margin: 0;
            padding-bottom: 0;
            padding-top: 0;
            color: transparent;
        }
        .rs__single-value{
            > div{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                small{
                    display: none;
                }               
            }
        }
    }
    > .rs__menu {
        position: fixed;
        height: 100vh;
        z-index: 1000;
        overflow-y: scroll;
        left: 0;
        top: 0;
        margin-top: 0;
        margin-bottom: 0;
        border-radius: 0;
        > .rs__menu-list {
            padding-bottom: 0;
            padding-top: 0;
        }
        .rs__option {
            border-bottom: 1px solid variables.$border_color;
            padding: 15px 12px;
            font-size: 17px;
            line-height: 20px;
            font-weight: variables.$medium;
            small{
                display: block;
                color: variables.$text-muted;
                font-weight: variables.$regular;
            }
        }
    }
    .rs__control--menu-is-open{
        svg {
            transform: rotate(-90deg);
        }
    }
}
@include mixins.breakpoint(variables.$small-tablet) {
    .right-side{
        > .rs__menu {
            position: absolute;
            width: 300px;
            height: auto;
            max-height: 280px;
            left: 100%;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

}