@use "../utils/mixins";

.my-courses {

  @include mixins.breakpoint() {
    padding: 35px;
  }
}

@media screen and (min-width: 768px) {
  .item-list.courses .item-list__action .course-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 55px;
    white-space: normal;
    text-align: right;
    text-transform: uppercase;
    color: #00365f;
  } 
}

@media screen {
  .tabs-my-courses {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    margin: 0 0 20px 0; 
  } 
}

@media screen {
  .tabs-my-courses.rounded li {
    flex: 1 1 0;
    border-right: solid 1px #eff0f2;
    overflow: hidden; 
  } 
}

@media screen and (min-width: 768px) {
  .tabs-my-courses.rounded li {
    flex: 0 0 180px; 
  } 
}

@media screen {
  .tabs-my-courses.rounded li:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px; 
  } 
}

@media screen {
  .tabs-my-courses.rounded li:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-right: none; 
  } 
}

@media screen {
  .tabs-my-courses.rounded li a {
    width: 100%;
    color: #00365f;
    background-color: #fff;
    font-size: 16px;
    line-height: 40px;
    display: block;
    text-align: center;
    cursor: pointer;
  } 
}

@media screen {
  .tabs-my-courses.rounded li a.is-active {
    color: #fff;
    background-color: #00365f; 
  } 
}

@media screen {
  .box-head.my-courses-head {
    margin-top: 25px;
    &h1{
      padding-left: 0px;
    }
  }
  .box-actions.my-courses-actions {
      margin-top: 20px;
    }
}

.accordion-container .accordion-item .accordion-item__head.my-courses-button {
  background-image: url("../img/icons/chevron_blue_right.png");
  background-size: 8px 11px;
}

.add-courses-button {
  display: flex;
  justify-content: center;
  padding: 5px 20px;
}

.my-courses-location {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}