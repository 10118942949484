@use "../utils/mixins";
@use "../utils/variables";

.your-trend {
    
    @include mixins.breakpoint()  {

        padding: 35px;

    }

    .your-trend__title {
        @include mixins.breakpoint() {
            color: variables.$black;
            font-size: 22px;
            line-height: 1;
            text-align: center;
        }

        .info-button {
            color: #00365f;
            font-size: 15px;
            width: 22px;
            height: 22px;
            font-weight: 700;
            border-radius: 50%;
            border: 2px solid #00365f;
            background-color: initial;
            display: inline-block;
            margin-left: 5px;
            cursor: pointer;
        }

        .info-message {
            color: variables.$cardinal;
            font-size: 14px;
            max-width: 750px;
            margin: 15px 0;
            display: inline-block;
      
            a {
              color: variables.$cardinal !important;
              text-decoration: underline;
            }
        }
    }

    .your-trend__container {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

        }

        .your-trend__extra {
            white-space: pre-wrap;
            @include mixins.breakpoint() {

                color: variables.$black;

                font-size: 16px;
                line-height: 24px;
                font-weight: variables.$medium;

            }
            @include mixins.breakpoint(variables.$small_tablet) {
                text-align: center;
            }
            @include mixins.breakpoint(variables.$small_desktop) {
                font-size: 20px;
            }

        }

        .your-trend__col {

            @include mixins.breakpoint() {

                flex: none;
                width: 50%;

                margin: 0 0 30px 0;

            }

            &:nth-child(1) {
                @include mixins.breakpoint() {
                    border-right: solid 1px variables.$border_color;
                }
                @include mixins.breakpoint(variables.$small_tablet) {
                    width: 100%;
                    border: none;
                }
            }
            &:nth-child(2) {
                @include mixins.breakpoint() {
                }
                @include mixins.breakpoint(variables.$small_tablet) {
                    width: 33.333333%;
                    border-right: solid 1px variables.$border_color;
                }
                label.regular.black.centered.large {
                    @include mixins.breakpoint(variables.$small_tablet) {
                        color: variables.$black;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: variables.$medium;
                        margin: 0;
                    }
                    @include mixins.breakpoint(variables.$small_desktop) {
                        font-size: 20px;
                    }
                }
                .your-trend__score {
                    @include mixins.breakpoint(variables.$small_tablet) {
                        color: variables.$black;
                        font-size: 18px;
                        line-height: 24px;
                        font-weight: variables.$bold;
                    }
                    @include mixins.breakpoint(variables.$small_desktop) {
                        font-size: 20px;
                    }
                }
            }
            &:nth-child(3) {
                @include mixins.breakpoint() {
                    width: 45%;
                    padding: 0 10px;
                    border-right: solid 1px variables.$border_color;
                }
                @include mixins.breakpoint(variables.$small_tablet) {
                    width: 33.333333%;
                    padding: 0;
                }
            }
            &:nth-child(4) {
                @include mixins.breakpoint() {
                    width: 45%;
                    padding: 0 10px;
                }
                @include mixins.breakpoint(variables.$small_tablet) {
                    width: 33.333333%;
                    padding: 0;
                }
            }

            .your-trend__score {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    font-size: 48px;

                    text-align: center;

                    display: block;

                }

            }

            .your-trend__score .your-trend__differential {
              display: flex !important;
              justify-content: center
            }

        }


    }
  
    .score-card__row {
      @include mixins.breakpoint()  {
        margin: 10px -8px;
      }
    }
    
    .score-card__button {
      @include mixins.breakpoint()  {
        width: 380px;
      }
    }

}