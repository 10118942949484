@use "../utils/mixins";
@use "../utils/variables";

.filter__list {

  @include mixins.breakpoint() {

    background-color: variables.$white;

    padding: 15px 25px;
    margin: 0 0 10px 0;

    @include mixins.box-shadow(0 0 5px 0 rgba(variables.$black, 0.15));

  }
  @include mixins.breakpoint(variables.$small_tablet) {

    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 0 0 20px 0;

  }

  label {
    @include mixins.breakpoint(variables.$small_tablet) {
      margin: 0 20px 0 0;
    }
  }

  .default_select {

    @include mixins.breakpoint() {
      margin: 0 0 10px 0;
    }
    @include mixins.breakpoint(variables.$small_tablet) {

      flex: none;
      width: 240px;

      margin: 0 20px 0 0;

      // display: flex;
      // flex-direction: row;
      // justify-content: center;
      // align-items: center;

    }

    &:last-of-type {
      @include mixins.breakpoint(variables.$small_tablet) {
        margin: 0;
      }
    }

  }

}