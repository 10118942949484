@use "../utils/mixins";
@use "../utils/variables";

.create-profile {
  @include mixins.breakpoint() {
    height: 100%;
    padding: 35px 25px;
    background-color: rgba(variables.$white, 0.8);
    display: flex;
    flex-direction: column;
  }
  @include mixins.breakpoint(variables.$small_tablet) {
    margin: 0;
  }

  .create-profile__title {
    @include mixins.breakpoint() {
      color: variables.$dark_cardinal;
      font-size: 20px;
      line-height: 1;
      text-align: center;
      margin: 0 0 30px 0;
      font-weight: bold;
    }
    @include mixins.breakpoint(variables.$tablet) {
      font-size: 26px;
      line-height: 1.2;
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      font-size: 26px;
    }
    &.sub-title {
      color: variables.$dark_midnight_blue;
      font-weight: normal;
    }
  }

  p {
    @include mixins.breakpoint() {
      font-size: 13px;
      line-height: 1.2em;
      color: variables.$legend_gray_dark;
      margin: 15px 0 0 0;
      text-align: center;
    }
    @include mixins.breakpoint(variables.$tablet) {
      font-size: 16px;
    }

    a {
      @include mixins.breakpoint() {
        color: variables.$black;
        text-decoration: underline;
        position: relative;
      }

      sup {
        @include mixins.breakpoint() {
          vertical-align: super;
          position: absolute;
          right: -10px;
          top: -6px;
        }
      }
    }
  }
  .button-container {
    align-self: center;
  }

  .separator {
    background-color: #e8e8e8;
    height: 0.5px;
    margin-bottom: 1.8em;
    &.transparent {
      height: 0px;
    }
    &.mt {
      margin-top: 1.8em;
    }
  }

  .field-place-holder {
    display: flex;
    margin-top: 6em;
  }

  .top-label {
    font-size: 16px;
    line-height: 1.2em;
    font-family: "National", sans-serif;
    padding-bottom: 15px;
  }

  .create_form {
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="number"] {
      font-family: "National", sans-serif;
      border-radius: 1.5px;
      font-size: 18px;
      height: 43px;
    }
    input[type="text-disabled"] {
      font-family: National, sans-serif;
      font-size: 18px;
      height: 43px;
      background-color: transparent;
      border-width: 0px;
      margin-left: -1px;
      margin-top: -15px;
    }
    label {
      margin-top: 10px;
      font-family: National, sans-serif;
      font-size: 16px;
      font-weight: variables.$regular;
      font-weight: variables.$medium;
      &.disable {
        margin-top: -10px;
        font-family: National, sans-serif;
        font-size: 18px;
      }
      &.terms {
        font-family: National, sans-serif;
        font-size: 16px;
      }
    }
    span.error {
      color: red;
      font-size: 15px;
      line-height: 15px;
      font-family: National, sans-serif;
      margin-top: 8px;
    }
  }

  .radio-icon {
    cursor: pointer;
    border: none;

    width: 28px;
    height: 28px;
    background: url("../img/icons/radio_unchecked.svg") no-repeat center;
    &.checked {
      background: url("../img/icons/radio_checked.svg") no-repeat center;
    }
    margin-right: 8px;
  }

  input[type="checkbox"] {
    &.radio {
      @include mixins.breakpoint() {
        display: none;
      }

      & + label {
        .link {
          text-decoration: underline;
          color: variables.$black;
        }

        @include mixins.breakpoint() {
          font-family: National, sans-serif;
          font-size: 16px;
          line-height: 23px;
          display: flex;
          flex-direction: row;
          margin: 0;
          cursor: pointer;
          position: relative;
        }
      }
    }
  }
}

.show_password_wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;

  position: relative;
}
.show_password {
  position: absolute;
  align-self: flex-end;

  height: 35px;

  button {
    height: 35px;

    padding-top: 0;
    padding-bottom: 0;
  }
}
.show_button {
  padding: 0.3em 1.4em;
  color: variables.$legend_gray_dark;
}
