@use "../utils/mixins";
@use "../utils/variables";

.golfer-head__container {
    position: relative;

    @include mixins.breakpoint() {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      height: calc(100% - 40px);
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      width: 1230px;
      margin: 0 auto;
    }

    @include mixins.breakpoint() {
      .details {
        padding: 0 0 0 20px;
        color: variables.$white;
        
        .name-row{
          .name{
            font-family: 'National Book',sans-serif;
            font-size: 40px;
            line-height: 1em;
            margin-top: 40px;
          }
          .following{
            position: absolute;
            top: 0;
            right: 10px;
            padding: 5px 5px 5px 27px;
            margin: 10px 0 0 15px;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position: left 2px;
            background-size: 18px 18px;
            font-weight: variables.$medium;
            background-image: url('../img/icons/ic_starw_43x43.svg');
            background-color: transparent;
            border: none;
            color: variables.$white;
            font-size: 14px;
            &.active{
              background-image: url('../img/icons/ic_starw_43x43_active.svg');
            }
          }
        }


        .club{
          display: block;
          font-size: 18px;
        }

        .info-rows{
          display: inline-flex;
          flex-direction: column;
          margin-top: 10px;
        }

        .cap-container {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .info{
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 10px 0 0 0;
          div{
            flex-shrink: 0;
            padding: 0 10px;
            font-size: 16px;
            line-height: 1em;
            font-weight: variables.$bold;
            small{
              font-size: 15px; 
              font-weight: variables.$regular;
            }
          }
          .score{
            flex-grow: 1;
            font-size: 42px;
            line-height: 1em;
            font-weight: variables.$medium;
            padding: 0;
          }
          .trend,.low{
            text-align: center;
            border-left: 1px solid variables.$white;
          }
        }
      }
    }
    @include mixins.breakpoint(variables.$small_tablet) {
      .details {
        padding: 0 0 0 30px;
        .name-row{
          display: flex;
          align-items: center;
          .name{
            font-size: 52px;
            margin-top: 0;
          }
          .following{
            position: static;
          }    
        }
        .club{
          font-size: 20px;
        }
        .info-rows{
          flex-direction: row;
        }
        .info{
          margin: 10px 20px 0 0;
          div{
            padding: 0 8px;
            font-size: 19px;
            small{
              font-size: 18px;
            }
          }
          .score{
            font-size: 48px;
          }
          .trend,.low{
            text-align: left;
          }
        }
      }
    }
    @include mixins.breakpoint(variables.$small_desktop) {
      .details {
        padding: 0 0 0 50px;
        .name-row{
          .name{
            font-size: 56px;
          }
          .following{
            position: static;
          }    
        }
        .club{
          font-size: 20px;
        }
        .info-rows{
          flex-direction: row;
        }
        .info{
          margin: 10px 20px 0 0;
          div{
            font-size: 19px;
            small{
              font-size: 18px;
            }
          }
          .score{
            font-size: 54px;
          }
          .trend,.low{
            text-align: left;
          }
        }
      }
    }

}

.toolTip {
  
  & {
    max-width: 80%;
    line-height: 1.4;
  }

  @include mixins.breakpoint(variables.$small_tablet) {
    max-width: 350px;
  }
}
