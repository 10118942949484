.bottom-border-focus {
  /* For browsers that don't support :focus-visible */
  &:focus {
    border-bottom: 3px solid red;
    box-sizing: border-box;
  }
  /* Remove :focus styling for browsers that do support :focus-visible */
  &:focus:not(:focus-visible) {
    border-bottom: none;
  }
}

.background-focus {
  &.btn.fill.cardinal:focus-visible {
    background-color: black;
  }
}

.background-focus2 {
  &.btn.outline.blue:focus-visible {
    background-color: yellow;
  }
}

.color-focus {
  &.btn.lnk.white:focus-visible {
    color: red;
  }
}

.border-focus {
  &:focus-visible {
    outline: 3px solid red;
  }
}

.rounded-border-focus {
  &:focus-visible,
  a:focus-visible,
  &:focus-visible img {
    outline: 3px solid red;
    border-radius: 5px;
  }
}
