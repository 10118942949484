@use "../utils/mixins";
@use "../utils/variables";

.content {

    &.has-aside {

        @include mixins.breakpoint(variables.$tablet) {

            display: flex;
            flex-direction: row;
            justify-content: space-between;

            padding: 0 20px;

        }
        @include mixins.breakpoint(variables.$small_desktop) {

            width: 1220px;
            margin: 0 auto 40px;

        }

        .main {
            @include mixins.breakpoint() {
                width: 100%;
            }
            @include mixins.breakpoint(variables.$tablet) {
                width: 724px;
            }
            @include mixins.breakpoint(variables.$small_desktop) {
                width: 890px;
            }
        }
    
        .aside {
            @include mixins.breakpoint() {
                width: 100%;
                margin-top: 40px;
                margin-bottom: 40px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            @include mixins.breakpoint(variables.$tablet) {
                width: 400px;
                margin-top: 92px;
            }
            @include mixins.breakpoint(variables.$small_desktop) {
                width: 240px;
            }
        }    

    }
  
}

.hidden {
  display: none !important;
}

.center {
  margin: 0 auto;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.right {
  float: right;
}

.no-results-found {
  text-align: center;
  margin: 0 30px;
  padding: 50px;
}

.pagination_row{
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

#back-top-button{
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-image: url('../img/icons/ic_back-to-top.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    animation: 2s all;
    &:hover{
        background-color: rgba(0,0,0,0.2);
    }
    @include mixins.breakpoint(variables.$small_tablet) {
        right: 50px;
    }
}