@use "mixins";
@use "variables";

//	FONT DECLARATION
body > *,
input {
  width: 100%;

  font-family: "National", sans-serif;
  font-feature-settings: "tnum";
}

//	FONT SMOOTHING
html {
  -webkit-font-smoothing: antialiased;
}

//	BORDERBOX SETUP
* {
  box-sizing: border-box;
  outline: none;
}

//	BODY BACKGROUND & HEIGHT
body {
  background-color: variables.$bg_gray;
}

//	BODY
body {
  @include mixins.breakpoint() {
    min-height: 100vh;
  }
}

#root {
  @include mixins.breakpoint() {
    min-height: 100vh;
    max-width: 100vw;

    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    position: relative;
  }
}

//  PREVENT SCROLL
body {
  &.prevent-scroll,
  &.ReactModal__Body--open {
    @include mixins.breakpoint() {
      overflow: hidden;
    }
  }
}

//	SET BASE FONT SIZE
html,
body {
  font-size: 16px;
}
