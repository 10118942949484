@use "../utils/mixins";
@use "../utils/variables";

.golfer_lookup_section {
  margin-bottom: 130px;
  .panel{
    box-shadow: none;
  }

  select{
    border-radius: 0;
    font-size: 13px;
    font-weight: variables.$medium;
    padding: 0 35px 0 10px;
    height: 40px;
  }
  label{
    font-family: 'National Book';
    font-size: 14px;
    font-weight: variables.$regular;
    color: variables.$black;
  }

  @include mixins.breakpoint() {
    .promo_banner{
      display: none;
    }
    .section_header{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 20px 10px;

      h3{
        color: variables.$dark_midnight_blue;
        font-size: 24px;
        font-weight: variables.$light;
        margin: 10px 0;
      }

      h1{
        color: variables.$dark_midnight_blue;
        font-size: 24px;
        font-weight: variables.$light;
        margin: 10px 0;
      }

      h4{
        font-size: 18px;
        font-weight: variables.$medium;
        line-height: 40px;
      }

      .tabs{
        margin: 0;
        padding: 0;
        li{
          a{
            font-size: 16px;
            text-transform: none;
            line-height: 1em;
            white-space: nowrap;
            padding: 0.8em 0;
          }
        }
      }

      .search-container{
        min-width: 280px;
        input[type="text"].search{
          background: variables.$bg_gray;
          padding: 0 40px 0 20px;
        }
      }

      .form-group{
        width: 100%;
        margin: 0 0 15px 0;
        &:last-child {
          margin: 0;
        }
        .search-container {
          margin: 0;
        }
      }

      &.filters{
        padding: 35px 25px;
        border-bottom: solid 1px variables.$border_color;   
      }
      &.expand{
        .form-group{
          flex: 1;
        }
      }

    }
  }

  @include mixins.breakpoint(variables.$small_tablet) {
    .promo_banner{
      display: block;
      margin-bottom: 30px;
      img{
        width: 100%;
      }
    }
    .section_header{
      flex-direction: row;
      align-items: flex-end;
      h3{
        font-size: 28px;
      }
      h1{
        font-size: 28px;
      }
      .tabs{
        li{
          flex: 0 0 auto;
          a{
            padding: 0.7em 2.3em;
          }
        }
      }
      .search-container{
        margin-bottom: 0;
      }
      .form-group{
        margin: 0 10px 0 10px;
        &:first-child{
          margin-left: 0;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }

  @include mixins.breakpoint(variables.$small_desktop) {
    .section_header{
      padding: 20px 0;
      h3{
        font-size: 35px;
      }
      h1{
        font-size: 35px;
      }
    }
  }

}
