@use "../utils/mixins";
@use "../utils/variables";

//  CARD TABLE
//  CARDS ON MOBILE -> TABLE ON DESKTOP

.card__table {
  @include mixins.breakpoint() {
    display: flex;
    flex-direction: column;

    background-color: variables.$white;
    margin: 0 0 40px 0;
  }

  @include mixins.breakpoint(variables.$small_tablet) {
    margin: 0 0 40px 0;
  }

  .table__head {
    @include mixins.breakpoint() {
      display: none;
    }

    @include mixins.breakpoint(variables.$small_tablet) {
      display: block;
    }

    .head__row {
      .head__cell {
        @include mixins.breakpoint(variables.$small_tablet) {
          color: variables.$text_muted;

          font-size: 13px;
          line-height: 20px;

          text-transform: uppercase;

          height: 50px;
          padding: 0 10px;

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }

        @include mixins.breakpoint(variables.$small_desktop) {
          font-size: 14px;

          height: 60px;
        }

        &.stats {
          @include mixins.breakpoint() {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  //  COMMON
  .table__head,
  .table__body {
    .head__row,
    .card {
      @include mixins.breakpoint(variables.$small_tablet) {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .head__cell,
      .card__cell {
        @include mixins.breakpoint(variables.$small_tablet) {
        }

        &.esc-score {
          @include mixins.breakpoint(variables.$small_tablet) {
            flex: none;
            width: 100px;
          }

          @include mixins.breakpoint(variables.$small_desktop) {
            width: 140px;
          }

          &.nineHole {
            background-color: variables.$highlight_red;
          }

          &.deletedScore {
            background-color: #fafafa;
          }
        }

        &.date {
          @include mixins.breakpoint(variables.$small_tablet) {
            flex: none;
            width: 80px;
          }

          @include mixins.breakpoint(variables.$small_desktop) {
            width: 100px;
          }
        }

        &.rating-slope {
          @include mixins.breakpoint(variables.$small_tablet) {
            flex: none;
            width: 80px;
          }

          @include mixins.breakpoint(variables.$small_desktop) {
            width: 100px;
          }
        }

        &.differential {
          @include mixins.breakpoint(variables.$small_tablet) {
            flex: none;
            width: 80px;
          }
        }

        &.pcc {
          @include mixins.breakpoint(variables.$small_tablet) {
            flex: none;
            width: 60px;
          }
        }

        &.course {
          @include mixins.breakpoint(variables.$small_tablet) {
            flex: none;
            width: 230px;

            justify-content: flex-start;
          }

          @include mixins.breakpoint(variables.$small_desktop) {
            flex: 1 1 0;
            width: auto;
            max-width: 390px;
          }
        }

        &.stats {
          white-space: nowrap;
          @include mixins.breakpoint(variables.$small_tablet) {
            flex: none;
            width: 100px;
            margin: 0 0 0 auto;
          }

          .btn.comp {
            @include mixins.breakpoint() {
              margin-top: 5px;
            }
          }
        }
      }

      &.active {
        background: variables.$legend_light_blue;
        color: variables.$white;

        .card__cell {
          &.esc-score {
            background: variables.$legend_light_blue;
          }

          strong {
            &.dark-blue {
              color: variables.$white;
            }
          }
        }
      }
    }
  }

  .h6_attributes {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: variables.$dark_midnight_blue;
    background: variables.$white;
    text-transform: uppercase;
    padding: 20px 0;
    &.border-bottom {
      border-bottom: 1px solid #e5e5e5;
    }
    &.border-top {
      border-top: 1px solid #e5e5e5;
    }
  }

  .table__body {
    @include mixins.breakpoint() {
      display: flex;
      flex-direction: column;
    }

    .card {
      @include mixins.breakpoint() {
        display: flex;
        flex-direction: column;

        //  border-bottom: solid 1px $border_color;
      }

      @include mixins.breakpoint(variables.$small_tablet) {
        flex-direction: row;

        height: 60px;

        border-bottom: solid 1px variables.$border_color;
      }

      &:first-of-type {
        @include mixins.breakpoint(variables.$small_tablet) {
          border-top: solid 1px variables.$border_color;
        }
      }

      .card__cell {
        @include mixins.breakpoint() {
          display: flex;
          flex-direction: row;
          align-items: center;

          font-size: 16px;
          line-height: 20px;

          padding: 0 15px 0 0;
        }

        @include mixins.breakpoint(variables.$small_tablet) {
          justify-content: center;
          padding: 0 10px;

          font-size: 14px;
          line-height: 16px;

          height: 45px;
        }

        @include mixins.breakpoint(variables.$small_desktop) {
          font-size: 18px;
          line-height: 20px;

          height: 60px;
        }

        &:last-child {
          @include mixins.breakpoint() {
            border-bottom: solid 1px variables.$border_color;
          }

          @include mixins.breakpoint(variables.$small_tablet) {
            border: none;
          }
        }

        strong {
          @include mixins.breakpoint() {
            font-weight: variables.$bold;
          }

          &.dark-blue {
            @include mixins.breakpoint() {
              color: variables.$dark_midnight_blue;
            }
          }

          &.medium_blue_2 {
            @include mixins.breakpoint() {
              color: variables.$medium_blue_2;
            }
          }
        }

        .subscript {
          font-size: 0.6em;
          vertical-align: sub;
        }

        &::before {
          @include mixins.breakpoint() {
            display: block;

            width: 150px;

            flex-shrink: 0;

            padding: 15px 20px;
            margin: 0 15px 0 0;

            box-sizing: border-box;

            color: variables.$black;
            background-color: variables.$super_light_blue;

            @include mixins.box-shadow(0 200px 0 0 variables.$super_light_blue);

            font-size: 14px;
            line-height: 20px;

            text-transform: uppercase;
          }

          @include mixins.breakpoint(variables.$small_tablet) {
            display: none;
          }
        }

        //  MOBILE LABELS
        //  SIMULATE THEAD FOR EACH CARD
        &.esc-score {
          @include mixins.breakpoint(variables.$small_tablet) {
            background-color: variables.$light_gray;
            height: 100%;
          }

          &::before {
            @include mixins.breakpoint() {
              content: "Score";
            }
          }
        }

        &.date {
          @include mixins.breakpoint(variables.$small_tablet) {
            letter-spacing: -0.5px;
          }

          @include mixins.breakpoint(variables.$small_desktop) {
            font-size: 17px;
          }

          &::before {
            @include mixins.breakpoint() {
              content: "Date";
            }
          }
        }

        &.rating-slope {
          &::before {
            @include mixins.breakpoint() {
              content: "Rating / Slope";
            }
          }
        }

        &.pcc {
          &::before {
            @include mixins.breakpoint() {
              content: "PCC";
            }
          }
        }

        &.differential {
          &::before {
            @include mixins.breakpoint() {
              content: "Differential";
            }
          }
        }

        &.course {
          &::before {
            @include mixins.breakpoint() {
              content: "Course";
            }
          }

          //  COND FOR TOO BIG
          strong {
            @include mixins.breakpoint(variables.$small_tablet) {
              max-width: 100%;
              max-height: 32px;

              overflow: hidden;
              text-overflow: ellipsis;

              display: block;
            }

            @include mixins.breakpoint(variables.$small_tablet) {
              max-height: 40px;

              font-size: 16px;
              line-height: 20px;
            }

            span {
              @include mixins.breakpoint(variables.$small_tablet) {
                max-height: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }

        &.stats {
          @include mixins.breakpoint(variables.$small_tablet) {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
          }

          @include mixins.breakpoint(variables.$small_desktop) {
            font-size: 17px;
          }

          &::before {
            @include mixins.breakpoint() {
              content: "Stats";
            }
          }
        }
      }
    }

    .card_more {
      display: flex;
      flex-grow: 1;
      background-color: #eff0f2;
      justify-content: space-around;
      padding: 0 5px;
      flex-direction: column;
      align-items: center !important;
      justify-content: center;

      .donut,
      .advanced-stats-panel {
        position: relative;
        max-width: 470px;
        margin: 0 auto;
      }

      .donut {
        border-bottom: 1px solid #ccc;

        &:after {
          border-right: none;
        }
      }

      .advanced-stats-panel {
        padding: 20px 0;
      }

      .donut:after {
        border-right: none;
      }

      &::after {
        content: "";
        display: block;
        border-right: solid 1px variables.$border_color;
        height: calc(100% - 40px);
        width: 0;
        position: absolute;
        top: 20px;
        bottom: 20px;
      }
    }
  .card_more.disable_divider::after {
    display: none;
  }

    @include mixins.breakpoint(variables.$small_tablet) {
      .card_more {
        flex-direction: row;
        align-items: flex-end;
        position: relative;

        .donut,
        .advanced-stats-panel {
          width: 50%;
        }

        .donut {
          border-bottom: none;
        }
      }
    }

    .post-card__stats {
      @include mixins.breakpoint() {
      }
    }

    .right-item {
      display: flex;
      justify-content: flex-end;
    }
  }

  .row-action-right {
    display: flex;
    justify-content: flex-end;
    background-color: #eff0f2;
    padding: 0 0 20px;

    .rar_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @include mixins.breakpoint(variables.$small_tablet) {
      .rar_container {
        width: 50%;
      }
    }
    .rar_container > *:last-child {
      margin-top: 10%;
    }
  }

  .row-action {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 30px 0 20px;

    .rar_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @include mixins.breakpoint(variables.$small_tablet) {
      .rar_container {
        width: 50%;
      }
    }

    .rar_container > *:last-child {
      margin-top: 10%; 
    }
  }
}

.pre-card__table {
  position: relative;

  .pre-table__head {
    @include mixins.breakpoint() {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      background-color: transparent;

      height: 60px;
      width: auto;
      padding: 0 25px;

      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .graph-view {
      @include mixins.breakpoint() {
        background-color: transparent;
        border: none;

        color: variables.$black;

        font-size: 14px;
        line-height: 20px;
        font-weight: variables.$bold;

        height: 40px;

        margin: 0;
        padding: 0;

        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .icon {
        @include mixins.breakpoint() {
          color: variables.$dark_midnight_blue;

          margin: 0 0 0 10px;
        }
      }

      .open,
      .close {
        @include mixins.breakpoint() {
          font-size: 16px;
        }
      }

      .open {
        @include mixins.breakpoint() {
          display: block;
        }
      }

      .close {
        @include mixins.breakpoint() {
          display: none;
        }
      }

      &.active {
        .open {
          @include mixins.breakpoint() {
            display: none;
          }
        }

        .close {
          @include mixins.breakpoint() {
            display: block;
          }
        }
      }
    }
  }

  .pre-table__body {
    .panel {
      box-shadow: none;
    }

    @include mixins.breakpoint() {
      padding-top: 60px;
      background-color: variables.$white;
    }
  }

  .pre-table__foot {
    @include mixins.breakpoint() {
      border-top: solid 1px variables.$border_color;
      border-bottom: solid 1px variables.$border_color;

      background-color: variables.$white;

      min-height: 60px;
      padding: 15px 25px;

      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .round-with-advanced-stats {
      @include mixins.breakpoint() {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin: 0 0 0 auto;
      }

      .tiny {
        @include mixins.breakpoint() {
          font-size: 14px;
        }
      }

      .btn.comp {
        @include mixins.breakpoint() {
          margin: 0 0 0 10px;
        }
      }

      .legend9Holes {
        width: 46px;
        height: 30px;
        border: 1px solid #fff;
        margin: 0 5px 0 0;
        margin-left: 5px;
        background-color: variables.$highlight_red;
      }
    }
  }
}

//  ADDITIONAL INFO
.add-info__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: variables.$white;

  height: 60px;

  border-bottom: solid 1px variables.$border_color;

  padding: 20px;

  @include mixins.breakpoint(variables.$small_tablet) {
    justify-content: flex-start;
  }

  .add-info__cell {
    color: variables.$text_muted;

    font-weight: variables.$bold;

    @include mixins.breakpoint(variables.$small_tablet) {
      margin: 0 20px 0 0;
    }

    &:last-child {
      @include mixins.breakpoint(variables.$small_tablet) {
        margin: 0;
      }
    }
  }
}

.deleted-score {
  padding: 0!important;
  margin-right: 14px!important;

  @include mixins.breakpoint(variables.$small_desktop) {
    width: 84px!important;
    height: 26px!important;
  }

  span {
    background-color: #f4e9e9;
    color: #ad1312;
    width: 70px!important;
    height: 22px!important;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px!important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;

    @include mixins.breakpoint(variables.$small_desktop) {
      width: 84px!important;
      height: 26px!important;
      font-size: 15px!important;
      justify-content: center!important;
      margin-right: 0!important
    }

    @include mixins.breakpoint(variables.$small_tablet) {
      justify-content: center!important;
    }
  }
}
